/**
 * 图表
 * luxinwen
 * Developed on 2020-12
 * Updated on 2023-04
 */
<template>
  <div>
    <div ref="sp-chart" :style="chartStyle"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  // 按需引入
  // import * as echarts from 'echarts/lib/echarts';

  export default {
    name: 'sp-chart',
    props: {
      // echarts配置参数，可以使用 v-model 双向绑定数据
      value: {
        type: Object,
        default() {
          return {};
        }
      },
      // 图表类型，line / bar / pie 等
      type: {
        type: [String, Array],
        required: true,
        default: ''
      },
      // 图表数据
      data: {
        type: Array,
        default() {
          return [];
        }
      },
      // 图层宽度
      width: {
        type: String,
        default: '100%'
      },
      // 图层高度
      height: {
        type: String,
        default: '400px'
      },
      // 是否加载中
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        chart: null,
        option: {}
      };
    },
    computed: {
      chartStyle() {
        return {
          width: this.width,
          height: this.height
        };
      }
    },
    // created() {
    //   // 引入图形组件
    //   if (this.type) {
    //     if (typeof this.type === 'string') {
    //       require(`echarts/lib/chart/${this.type}`);
    //     } else {
    //       this.type.forEach(item => {
    //         require(`echarts/lib/chart/${item}`);
    //       });
    //     }
    //   }
    // },
    mounted() {
      this.initChart();
    },
    beforeDestroy() {
      const chart = this.chart;
      if (chart === null) return;
      chart.dispose(); // 组件销毁时，及时销毁图表
    },
    methods: {
      /**
       * 初始化图表
       * @return {[type]} [description]
       */
      initChart() {
        this.chart = echarts.init(this.$refs['sp-chart']);
        this.setChart();
      },
      /**
       * 设置图表
       */
      setChart() {
        if (this.chart === null) return;
        let option = Object.assign({
          title: {
            text: 'echarts ' + this.type
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            left: 'center'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'Data',
              type: 'line',
              stack: 'Total',
              data: []
            }
          ]
        }, this.value);
        if (typeof this.type === 'string') {
          if (option.series.length === 0) option.series.push({});
          option.series[0].type = this.type;
          option.series[0].data = this.data;
        } else {
          this.type.forEach((item, index) => {
            if (option.series.length <= index) option.series.push({});
            option.series[index].type = item;
            option.series[index].data = this.data[index];
          });
        }
        if (this.type === 'pie') {
          option.tooltip.trigger = 'item';
          delete option.xAxis;
          delete option.yAxis;
        }
        this.chart.setOption(option);
      }
    },
    watch: {
      value: {
        deep: true,
        handler(newVal) {
          if (newVal) this.setChart();
        }
      },
      data: {
        immediate: true,
        handler(newVal) {
          this.setChart();
        }
      },
      loading: {
        immediate: true,
        handler(newVal) {
          if (this.chart === null) return;
          this.chart[newVal ? 'showLoading' : 'hideLoading']();
        }
      }
    }
  };
</script>