/**
 * 富文本编辑器
 * luxinwen
 * Developed on 2020-02
 * Updated on 2023-03
 */
<style scoped lang="less">
  @import "./sp-editor.less";
</style>

<template>
  <div class="sp-editor">
    <Toolbar class="sp-editor-toolbar" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor :style="editorStyle" v-model="myValue" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" @onChange="onChange" />
  </div>
</template>

<script>
  import '@wangeditor/editor/dist/css/style.css';
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

  export default {
    name: 'sp-editor',
    components: {
      Editor,
      Toolbar
    },
    props: {
      // 要编辑的内容数据，可以使用 v-model 双向绑定数据
      value: {
        type: String,
        default: ''
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
      // 编辑器高度
      height: {
        type: Number,
        default: 400
      },
      // 编辑器提示文字
      placeholder: {
        type: String,
        default: '请输入内容...'
      }
    },
    data() {
      let userInfo = this.getUserInfo();
      let serverUrl = process.env.VUE_APP_END_SERVER + this.$api.common.uploadImage;
      return {
        myValue: this.value,
        editor: null,
        toolbarConfig: {
          excludeKeys: [/* 'uploadImage', */'insertVideo', 'uploadVideo', 'group-video', 'fullScreen', 'emotion', 'blockquote', 'todo'] // 排除掉某些菜单
        },
        editorConfig: {
          autoFocus: false,                 // 默认是否 focus
          placeholder: this.placeholder,    // 提示文案
          readOnly: this.disabled,          // 是否只读
          MENU_CONF: {
            // 上传图片配置
            uploadImage: {
              server: serverUrl,            // 服务端地址
              fieldName: 'file',            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
              maxFileSize: 5 * 1024 * 1024, // 单个文件的最大体积限制
              headers: {                    // 自定义增加 http header
                sessionId: userInfo.token
              },
              customInsert(res, insertFn) { // 自定义插入图片
                let url = res.data;
                let alt = '';
                let href = '';
                insertFn(url, alt, href);
              }
            }
          }
        },
        mode: 'default' // 'default' or 'simple'
      };
    },
    computed: {
      editorStyle() {
        return {
          'height': `${this.height}px`,
          'overflow-y': 'hidden'
        };
      }
    },
    beforeDestroy() {
      const editor = this.editor;
      if (editor === null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
    methods: {
      /**
       * 编辑器创建完毕时的回调函数
       */
      onCreated(editor) {
        this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      },
      /**
       * 编辑器内容、选区变化时的回调函数
       */
      onChange(editor) {
        // let val = editor.getHtml();
        if (editor.getHtml() === '<p><br></p>') this.myValue = '';
        this.$emit('input', this.myValue);
      }
    },
    watch: {
      value(val) {
        if (this.editor === null || val === this.myValue || val === null) return;
        this.editor.setHtml(val);
      },
      disabled(val) {
        if (this.editor === null) return;
        this.editor[val ? 'disable' : 'enable']();
      }
    }
  };
</script>