/**
 * 通用布局容器，可设置显示列数，自动在最后一个FormItem插入展开按钮，并添加展开收起功能
 * luxinwen
 * Developed on 2020-02
 * Updated on 2023-04
 */
<style scoped lang="less">
  @import "./sp-row.less";
</style>

<script>
  export default {
    name: 'sp-row',
    props: {
      // 表单项列数，必须为24的整除倍数，如 1、2、3、4、6
      col: {
        type: Number,
        default: 3
      },
      // 是否使用展开收起
      expand: {
        type: Boolean,
        default: true
      },
      // 是否默认全部展开
      opened: {
        type: Boolean,
        default: true
      },
      // 收起后展示行数
      defaultRow: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        isOpened: this.opened
      };
    },
    computed: {
      buttonName() {
        return this.isOpened ? '收起' : '展开';
      },
      buttonIcon() {
        return this.isOpened ? 'ios-arrow-up' : 'ios-arrow-down';
      }
    },
    methods: {
      openForm() {
        this.isOpened = !this.isOpened;
      }
    },
    render: function (h) {
      let slots = (this.$slots.default || []).filter(item => {
        return item.tag;
      });
      let children = [];
      let col = Number(this.col);
      let span = Math.floor(24 / col);
      slots.forEach((item, index) => {
        let colSpan = null;
        let colClass = '';
        let colDisplay = '';
        let spBtn = '';
        let itemIndex = index + 1;
        if (col > 1) {
          if (itemIndex > (col * this.defaultRow) - 1 && itemIndex < slots.length && !this.isOpened && this.expand) {
            colDisplay = 'none';
          }
          if (itemIndex === slots.length) {
            if (this.expand) {
              colClass = 'txt-right';
              if (itemIndex > col) {
                colClass += ' btn-col';
                spBtn = h('a', {
                  class: 'sp-btn',
                  on: {
                    click: () => {
                      this.openForm();
                    }
                  }
                }, [
                  h('span', this.buttonName),
                  h('Icon', {
                    props: {
                      type: this.buttonIcon
                    }
                  })
                ]);
              }
            }
            if (this.isOpened) {
              let num = itemIndex % col;
              if (num > 0) {
                colSpan = span * (col - num + 1);
              }
            } else {
              if (itemIndex < col) {
                colSpan = span * (col - itemIndex + 1);
              }
            }
          }
        }
        let node = h('Col', {
          props: {
            span: colSpan || span
          },
          class: colClass,
          style: {
            display: colDisplay
          }
        }, [item, spBtn]);
        children.push(node);
      });
      return h('Row', {
        props: {
          gutter: 16
        }
      }, children);
    }
  };
</script>