/**
 * 上传
 * luxinwen
 * Developed on 2020-02
 * Updated on 2023-01
 */
<style scoped lang="less">
  @import "./sp-upload.less";
</style>

<template>
  <div class="sp-upload" :class="{'disabled': disabled}">
    <div class="sp-upload-list">
      <div class="sp-upload-list-item" v-for="(item, index) in fileList" :key="'sp-upload-item-' + index" :class="{'uploading': item._sp_status === 'uploading', 'video': video}" v-show="showUploadList">
        <p class="sp-upload-list-item-format">{{ item._sp_format }}</p>
        <p class="sp-upload-list-item-img" :style="getImgStyle(item)">
          <video height="100" v-if="video && !!item.url">
            <source :src="item.url" />
          </video>
        </p>
        <p class="sp-upload-list-item-btns">
          <Icon type="ios-eye-outline" class="sp-upload-list-item-btn" @click="handlePreview(item)" />
          <Icon type="ios-arrow-back" class="sp-upload-list-item-btn" v-if="moveBtnDisplay" @click="handleMove('left', index)" />
          <Icon type="ios-arrow-forward" class="sp-upload-list-item-btn" v-if="moveBtnDisplay" @click="handleMove('right', index)" />
          <Icon type="ios-trash-outline" class="sp-upload-list-item-btn" v-if="removeBtnDisplay" @click="handleRemove(index)" />
        </p>
        <p class="sp-upload-list-item-uploading">
          <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
          上传中…
        </p>
      </div>

      <div class="sp-upload-btn" @click="handleClick" @drop.prevent="handleDrop" @paste="handlePaste" @dragover.prevent="dragOver = true" @dragleave.prevent="dragOver = false">
        <input ref="input" type="file" @change="handleChange" :multiple="multiple" :accept="accept" />
        <slot>
          <div class="sp-upload-btn-default">
            <Icon type="md-cloud-upload" />
            <span class="sp-upload-btn-default-txt">上传</span>
          </div>
        </slot>
      </div>
    </div>

    <Modal v-model="videoModal.display" title="选择上传方式" width="650">
      <RadioGroup v-model="videoModal.type" vertical>
        <Radio label="link">
          <span>网络链接</span>
          <Input v-model.trim="videoModal.link" maxlength="100" clearable class="width-l margin-l-10" v-show="videoModal.type === 'link'" />
        </Radio>
        <Radio label="local">
          <span>本地视频</span>
        </Radio>
      </RadioGroup>
      <div slot="footer">
        <Button type="text" size="large" @click="videoModal.display = false">取消</Button>
        <Button type="primary" size="large" @click="submitVideo">确定</Button>
      </div>
    </Modal>

    <Modal v-model="previewModal.display" title="预览" width="70%" footer-hide>
      <p class="sp-upload-preview-img" v-if="video">
        <video controls autoplay height="400" v-if="previewModal.display">
          <source :src="previewModal.url" />
        </video>
      </p>
      <p class="sp-upload-preview-img" v-else-if="previewModal.isImage"><img :src="previewModal.url" /></p>
      <p class="sp-upload-preview-txt" v-else>
        <a :href="previewModal.url" target="_blank">{{ previewModal.url }}</a>
      </p>
    </Modal>
  </div>
</template>

<script>
  import ajax from './ajax';

  export default {
    name: 'sp-upload',
    props: {
      // 当前上传文件数据，可以使用 v-model 双向绑定数据
      value: {
        type: [String, Array],
        default: ''
      },
      // 上传的地址
      action: {
        type: String,
        default: ''
      },
      // 设置上传的请求头部
      headers: {
        type: Object,
        default() {
          return {};
        }
      },
      // 上传时附带的额外参数
      data: {
        type: Object
      },
      // 上传的文件字段名
      name: {
        type: String,
        default: 'file'
      },
      // 是否支持发送 cookie 凭证信息
      withCredentials: {
        type: Boolean,
        default: false
      },
      // 是否支持多选文件
      multiple: {
        type: Boolean,
        default: false
      },
      // 是否支持粘贴上传文件
      paste: {
        type: Boolean,
        default: false
      },
      // 是否支持删除文件
      remove: {
        type: Boolean,
        default: true
      },
      // 是否支持左右移动文件
      move: {
        type: Boolean,
        default: false
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
      // 是否视频
      video: {
        type: Boolean,
        default: false
      },
      // 是否显示已上传文件列表
      showUploadList: {
        type: Boolean,
        default: true
      },
      // 上传控件的类型，可选值为 select（点击选择），drag（支持拖拽）
      type: {
        type: String,
        default: 'select'
      },
      // 接受上传的文件类型
      accept: {
        type: String
      },
      // 支持的文件类型，与 accept 不同的是，format 是识别文件的后缀名，accept 为 input 标签原生的 accept 属性，会在选择文件时过滤，可以两者结合使用
      format: {
        type: Array,
        default() {
          return ['jpg', 'jpeg', 'png'];
        }
      },
      // 文件大小限制，单位 kb，默认不超过5M
      maxSize: {
        type: Number,
        default: 5120
      },
      // 图片宽度限制，值为数字时宽度要等于该值，值为数组时宽度要在该范围内
      imgWidth: {
        type: [Number, Array]
      },
      // 图片高度限制，值为数字时高度要等于该值，值为数组时高度要在该范围内
      imgHeight: {
        type: [Number, Array]
      },
      // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者 Promise 则停止上传
      beforeUpload: {
        type: Function
      },
      // 文件上传时的钩子，返回字段为 event, file, fileList
      onProgress: {
        type: Function,
        default() {
          return {};
        }
      },
      // 文件上传成功时的钩子，返回字段为 response, file, fileList
      onSuccess: {
        type: Function,
        default() {
          return {};
        }
      },
      // 文件上传失败时的钩子，返回字段为 error, response, file
      onError: {
        type: Function,
        default() {
          return {};
        }
      },
      // 文件移除时的钩子，返回字段为 file, fileList
      onRemove: {
        type: Function,
        default() {
          return {};
        }
      },
      // 文件左右移动时的钩子，返回字段为 file, fileList
      onMove: {
        type: Function,
        default() {
          return {};
        }
      },
      // 显示提示文案时的钩子，返回字段为 message
      onAlert: {
        type: Function
      }
    },
    data() {
      return {
        fileList: [],
        dragOver: false,
        tempIndex: 1,
        imageFormatList: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
        previewModal: {
          display: false,
          isImage: true,
          url: ''
        },
        videoModal: {
          display: false,
          type: 'link',
          link: ''
        },
        uploadList: [],
        uploadTips: []
      };
    },
    computed: {
      removeBtnDisplay() {
        return this.remove && !this.disabled;
      },
      moveBtnDisplay() {
        return this.move && !this.disabled && this.fileList.length > 1;
      }
    },
    methods: {
      /**
       * 获取图片样式
       */
      getImgStyle(file) {
        let val = {};
        if (file._sp_status === 'finished' && this.checkIsImage(file._sp_format)) {
          val['background-image'] = `url(${file.url})`;
        }
        return val;
      },
      /**
       * 获取上传地址
       */
      getAction() {
        let val = this.action;
        if (!val) {
          if (this.video) {
            val = this.$api.common.uploadVideo;
          } else {
            val = this.$api.common.uploadImage;
          }
          val = process.env.VUE_APP_END_SERVER + val;
        }
        return val;
      },
      /**
       * 点击上传
       */
      handleClick() {
        if (this.disabled || this.type !== 'select') return;
        if (this.video) {
          this.videoModal.link = '';
          this.videoModal.type = 'link';
          this.videoModal.display = true;
        } else {
          this.$refs.input.click();
        }
      },
      /**
       * 确定视频上传方式
       */
      submitVideo() {
        if (this.videoModal.type === 'link') {
          if (!this.videoModal.link) {
            this.handleAlert('请填写视频链接');
            return;
          }
          let file = {};
          let res = {
            code: 'S0A00000',
            data: this.videoModal.link
          };
          this.handleStart(file);
          this.handleSuccess(res, file);
        } else {
          this.$refs.input.click();
        }
        this.videoModal.display = false;
      },
      /**
       * 选择文件
       */
      handleChange(e) {
        const files = e.target.files;
        if (!files) return;
        this.uploadFiles(files);
        this.$refs.input.value = null;
      },
      /**
       * 拖拽上传
       */
      handleDrop(e) {
        this.dragOver = false;
        if (this.disabled || this.type !== 'drag') return;
        this.uploadFiles(e.dataTransfer.files);
      },
      /**
       * 粘贴上传
       */
      handlePaste(e) {
        if (this.disabled || !this.paste) return;
        this.uploadFiles(e.clipboardData.files);
      },
      /**
       * 准备上传
       */
      uploadFiles(files) {
        let postFiles = [].slice.call(files);
        if (postFiles.length === 0) return;
        if (!this.multiple) postFiles = postFiles.slice(0, 1);
        postFiles.forEach((file, index) => {
          file._sp_index = index;
        });
        this.uploadList = [...postFiles];
        this.uploadTips = [];
        // beforeUpload
        if (!this.beforeUpload) {
          return this.checkFiles(postFiles);
        }
        const before = this.beforeUpload(postFiles);
        if (before && before.then) {
          before.then(processedFile => {
            if (Object.prototype.toString.call(processedFile) === '[object File]') {
              this.checkFiles(processedFile);
            } else {
              this.checkFiles(postFiles);
            }
          }, () => {
            // this.$emit('cancel', file);
          });
        } else if (before !== false) {
          this.checkFiles(postFiles);
        } else {
          // this.$emit('cancel', file);
        }
      },
      /**
       * 上传前的检测
       */
      checkFiles(files) {
        files.forEach(file => {
          this.checkFile(file);
        });
      },
      /**
       * 文件格式和尺寸检测
       */
      checkFile(file) {
        let tips = '';
        const _file_format = file.name.split('.').pop().toLocaleLowerCase();
        // check format
        if (!tips && this.format.length > 0) {
          const checked = this.format.some(item => item.toLocaleLowerCase() === _file_format);
          if (!checked) {
            tips = `上传文件仅支持${this.format.join('、')}格式`;
          }
        }
        // check maxSize
        if (!tips && this.maxSize && file.size > this.maxSize * 1024) {
          tips = '上传文件大小不得超过';
          if (this.maxSize < 1024) {
            tips += `${this.maxSize}KB`;
          } else {
            tips += `${Math.floor(this.maxSize / 1024)}MB`;
          }
        }
        // check imageSize
        if (!tips && this.checkIsImage(_file_format) && (this.imgWidth || this.imgHeight)) {
          let widthTip = '';
          let heightTip = '';
          let reader = new FileReader();
          reader.onload = e => {
            let image = document.createElement('img');
            image.src = e.target.result;
            // 延时处理，确保能正确获取到图片尺寸 image.width image.height
            setTimeout(() => {
              if (this.imgWidth) {
                if (typeof this.imgWidth === 'number') {
                  widthTip = `宽度为${this.imgWidth}`;
                  if (image.width !== this.imgWidth) {
                    tips = 1;
                  }
                } else if (this.imgWidth.length === 2) {
                  widthTip = `宽度为${this.imgWidth.join('~')}`;
                  if (image.width > this.imgWidth[1] || image.width < this.imgWidth[0]) {
                    tips = 2;
                  }
                }
              }
              if (this.imgHeight) {
                if (typeof this.imgHeight === 'number') {
                  heightTip = `高度为${this.imgHeight}`;
                  if (image.height !== this.imgHeight) {
                    tips = 1;
                  }
                } else if (this.imgHeight.length === 2) {
                  heightTip = `高度为${this.imgHeight.join('~')}`;
                  if (image.height > this.imgHeight[1] || image.height < this.imgHeight[0]) {
                    tips = 2;
                  }
                }
              }
              if (tips) {
                tips = `上传图片仅支持${[widthTip, heightTip].filter(item => !!item).join('、')}的尺寸`;
              }
              this.upload(file, tips);
            }, 50);
          };
          reader.readAsDataURL(file);
          return false;
        }
        this.upload(file, tips);
      },
      /**
       * 进入上传队列
       */
      upload(file, tips) {
        if (tips) {
          this.uploadTips.push(tips);
        }
        this.uploadList[file._sp_index]._sp_status = 'ready';
        let allcheck = this.uploadList.every(file => file._sp_status === 'ready');
        if (allcheck) {
          if (this.uploadTips.length > 0) {
            this.handleAlert(this.uploadTips[0]);
          } else {
            this.uploadList.forEach(file => {
              this.post(file);
            });
          }
        }
      },
      /**
       * 开始上传
       */
      post(file) {
        this.handleStart(file);
        // setTimeout(() => {
        //   this.$axios({
        //     url: this.$api.test.uploadImage
        //   }).then(res => {
        //     console.log('upload post', res);
        //     this.handleSuccess(res, file);
        //   });
        // }, 1000);
        setTimeout(() => {
          let headers = {};
          if (typeof this.getUserInfo === 'function') {
            headers.sessionId = this.getUserInfo().token;
          }
          ajax({
            action: this.getAction(),
            withCredentials: this.withCredentials,
            headers: Object.assign({}, headers, this.headers),
            data: Object.assign({
              size: file.size
            }, this.data),
            file: file,
            filename: this.name,
            onProgress: e => {
              this.handleProgress(e, file);
            },
            onSuccess: res => {
              this.handleSuccess(res, file);
            },
            onError: (err, response) => {
              this.handleError(err, response, file);
            }
          });
        }, 0);
      },
      /**
       * 生成上传数据
       */
      handleStart(file) {
        const _file = this.getNewFile({
          _sp_status: 'uploading',
          _sp_name: file.name,
          _sp_size: file.size
        });
        file._sp_uid = _file._sp_uid;
        if (typeof this.value === 'string') {
          this.fileList = [_file];
        } else {
          this.fileList.push(_file);
        }
      },
      /**
       * 查找文件数据
       */
      getFile(file) {
        let target = this.fileList.find(item => item._sp_uid === file._sp_uid);
        return target;
      },
      /**
       * 检测是否图片
       */
      checkIsImage(format) {
        return this.imageFormatList.findIndex(item => item === format.toLocaleLowerCase()) !== -1;
      },
      /**
       * 弹出提示
       */
      handleAlert(message) {
        if (this.onAlert) {
          this.onAlert(message);
        } else if (this.alert) {
          this.alert(message);
        } else {
          console.log(message);
        }
      },
      /**
       * 上传过程
       */
      handleProgress(e, file) {
        const _file = this.getFile(file);
        if (_file) {
          this.onProgress(e, _file, this.fileList);
          _file._sp_percentage = e.percent || 0;
        }
      },
      /**
       * 上传成功
       */
      handleSuccess(res, file) {
        const _file = this.getFile(file);
        if (_file) {
          _file._sp_status = 'finished';
          if (!this.action) {
            if (res.code === 'S0A00000' && res.data) {
              _file.url = res.data;
              this.setValue();
            } else {
              this.handleAlert(res.msg);
              if (res.code === '401') {
                setTimeout(() => {
                  this.goToLogin();
                }, 1500);
              }
            }
          }
          this.onSuccess(res, _file, this.fileList);
        }
      },
      /**
       * 上传失败
       */
      handleError(err, response, file) {
        const _file = this.getFile(file);
        if (_file) {
          _file._sp_status = 'fail';
          let index = this.fileList.indexOf(_file);
          this.fileList.splice(index, 1);
          this.setValue();
          this.onError(err, response, _file);
        }
      },
      /**
       * 删除
       */
      handleRemove(index) {
        const fileList = this.fileList;
        const file = fileList[index];
        fileList.splice(index, 1);
        this.setValue();
        this.onRemove(file, fileList);
      },
      /**
       * 移动
       */
      handleMove(direction, index) {
        const fileList = this.fileList;
        if ((direction === 'left' && index <= 0) || (direction === 'right' && index >= fileList.length - 1)) return;
        const file = fileList[index];
        let target = direction === 'left' ? index - 1 : index + 1;
        let temp = fileList.splice(index, 1)[0];
        fileList.splice(target, 0, temp);
        this.setValue();
        this.onMove(file, fileList);
      },
      /**
       * 预览
       */
      handlePreview(file) {
        if (file._sp_status === 'finished') {
          this.previewModal.isImage = this.checkIsImage(file._sp_format);
          this.previewModal.url = file.url;
          this.previewModal.display = true;
        }
      },
      /**
       * 生成文件数据
       */
      getNewFile(file = {}) {
        let obj = Object.assign({
          url: '',
          _sp_status: '',
          _sp_format: '',
          _sp_percentage: 0,
          _sp_uid: Date.now() + this.tempIndex++
        }, file);
        if (obj.url) {
          let format = obj.url.split('.').pop().toLocaleUpperCase();
          if (format.length > 4) format = format.substr(-3);
          obj._sp_format = format;
        }
        return obj;
      },
      /**
       * 设置值
       */
      setValue() {
        let val = '';
        if (typeof this.value === 'string' || this.value === null || this.value === undefined) {
          if (this.fileList.length > 0) {
            val = this.fileList[0].url;
          }
        } else {
          val = [];
          this.fileList.forEach(file => {
            let item = {};
            if (file._sp_status === 'finished') {
              Object.keys(file).forEach(key => {
                if (key.indexOf('_sp_') !== 0) {
                  item[key] = file[key];
                }
              });
            } else {
              item = Object.assign({}, file);
            }
            val.push(item);
          });
        }
        console.log('upload setValue', val);
        this.$emit('input', val);
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          let fileList = [];
          if (typeof newVal === 'string' || newVal === null || newVal === undefined) {
            if (newVal) {
              let file = this.getNewFile({
                url: newVal,
                _sp_status: 'finished',
                _sp_percentage: 100
              });
              fileList.push(file);
            }
          } else {
            newVal.forEach(item => {
              let file = this.getNewFile(Object.assign({
                _sp_status: 'finished',
                _sp_percentage: 100
              }, item));
              fileList.push(file);
            });
          }
          this.fileList = fileList;
          console.log('upload watch', this.fileList);
        }
      }
    }
  };
</script>