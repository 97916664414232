/**
 * 测试状态
 */
export const TEST_STATUS = {
  0: '禁用',
  1: '启用',
  2: '其它'
};

/**
 * 系统名称
 */
export const MERCHANTS = {
  'OSP': '亚泰选房OSP管理系统'
};

/**
 * 会员范围
 */
export const EVENT_MEMBER_RANGE = {
  0: '等级筛选',
  // 1: '手工指定'
};

/**
 * 事件执行结果
 */
export const EVENT_EXECUTE_RESULT = {
  0: '失败',
  1: '成功',
  2: '部分成功'
};

/**
 * 事件触达方式
 */
export const EVENT_TRIGGER_MODE = {
  0: '自动事件',
  1: '手动事件'
};

/**
 * 性别
 */
export const GENDER = {
  0: '女',
  1: '男'
};

/**
 * 活动类型
 */
export const ACTIVITY_TYPE = {
  'QMYX': '全民营销',
  // 'LDXHD': '老带新活动'
};

/**
 * 活动状态
 */
export const ACTIVITY_STATUS = {
  1: '启用',
  0: '禁用'
};

/**
 * 活动进行状态
 */
export const ACTIVITY_PROGRESS_STATUS = {
  0: '未开始',
  1: '进行中',
  2: '已结束'
};

/**
 * 活动参与状态
 */
export const ACTIVITY_PART_STATUS = {
  0: '已失效',
  1: '已推荐',
  2: '已来访',
  3: '已认购',
  4: '已成交'
};

/**
 * 广告状态
 */
export const ADVERT_STATUS = {
  1: '未开始',
  2: '展示中',
  3: '已结束'
};

/**
 * 广告跳转类型
 */
export const ADVERT_SKIP_TYPE = {
  0: 'H5链接',
  1: '项目详情页'
};

/**
 * 佣金状态
 */
export const COMMISSION_STATUS = {
  0: '待发放',
  1: '发放中',
  2: '已发放',
  3: '发放异常'
};

/**
 * 会员状态
 */
export const MEMBER_STATUS = {
  0: '正常',
  1: '预激活',
  2: '未激活',
  3: '冻结',
  4: '重复',
  5: '注销'
};

/**
 * 项目户型状态
 */
export const PROJECT_HOUSE_STATUS = {
  0: '禁用',
  1: '启用'
};

/**
 * 项目销售状态
 */
export const PROJECT_SALE_STATUS = {
  0: '停售',
  1: '在售'
};
/**
 * 操作反馈方式
 */
export const MESSAGE_CHANNELLINK =
 [
   {
     value: 0,
     label: '图片'
   },
   {
     value: 1,
     label: '视频'
   },
   {
     value: 2,
     label: '微信小程序页面'
   },
   {
     value: 3,
     label: '微信公众号页面'
   },
   {
     value: 4,
     label: '其他小程序页面'
   },
   {
     value: 5,
     label: '网页'
   },
   {
     value: 6,
     label: '无'
   }
 ];

export const MESSAGE_CHANNEL_TYPE =
// 发送方式：短信SMS,微信WECHAT,站内信INNM,云推送APP,邮箱MAIL,小程序H5
[
  // {
  //   value: 'SMS',
  //   label: '短信'
  // },
  {
    value: 'INNM',
    label: '站内信'
  },
  // {
  //   value: 'WECHAT',
  //   label: '微信小程序页面'
  // },
  // {
  //   value: 'H5',
  //   label: '微信公众号页面'
  // }
];

export const MESSAGE_STATUS =
[
  {
    value: '1',
    label: '已发布'
  },
  {
    value: '2',
    label: '已终止'
  },
  {
    value: '3',
    label: '未推送'
  },
  {
    value: '4',
    label: '推送中'
  },
  {
    value: '5',
    label: '已结束'
  }
];

export const SHARE_ACT_STATUS =
[
  {
    value: '0',
    label: '待上架'
  },
  {
    value: '2',
    label: '待生效'
  },
  {
    value: '3',
    label: '已上架'
  },
  {
    value: '5',
    label: '已下架'
  },
];