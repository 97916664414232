/**
 * 业务弹窗-选择项目
 * luxinwen
 * 2023-03
 */
<style scoped lang="less">
  .selected {
    width: 500px;
  }
</style>

<template>
  <Modal v-model="myValue" title="选择项目" width="900" :mask-closable="false" :footer-hide="!multiple">
    <div class="page-main">
      <div class="page-main-content">
        <div class="selected">
          已选择：
          <span v-for="(item, index) in selectedList" :key="'selected' + index">{{ item.buildingInfo.name }}，</span>
        </div>
        <!-- <div class="right-form">
          <Form :model="formData" inline>
            <FormItem>
              <Input class="width-xs" v-model="formData.title" placeholder="请输入标题" clearable />
            </FormItem>
            <FormItem>
              <Select class="width-xs" v-model="formData.status" placeholder="请选择状态" clearable>
                <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryData">查询</Button>
            </FormItem>
          </Form>
        </div> -->
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage" @select="selectData">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.buildingInfo.masterImage" style="max-width: 200px; max-height: 100px;">
          </template>
          <template slot-scope="params" slot="buildingName">
            <span>{{ params.row.buildingInfo.name }}</span>
          </template>
          <template slot-scope="params" slot="cityName">
            <span>{{ (allCityList.find(item => item.regionId === params.row.buildingInfo.cityId) || {}).regionName }}</span>
          </template>
          <template slot-scope="params" slot="category">
            <span>{{ params.row.buildingInfo.categoryValue }}</span>
          </template>
          <template slot-scope="params" slot="saleType">
            <span>{{ params.row.buildingInfo.saleTypeValue }}</span>
          </template>
          <template slot-scope="params" slot="status">
            <Tag :color="params.row.buildingInfo.status === 2 ? 'error' : 'success'">{{ (statusList.find(item => item.code === params.row.buildingInfo.status) || {}).desc }}</Tag>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="submitData(params.row)" :disabled="disabled.findIndex(item => item.id === params.row.id) !== -1">选取</Button>
          </template>
        </sp-table>
      </div>
    </div>
    <div slot="footer">
      <Button type="text" size="large" @click="myValue = false">取消</Button>
      <Button type="primary" size="large" :loading="isLoading" @click="submitData">确定</Button>
    </div>
  </Modal>
</template>

<script>
  export default {
    name: 'modal-select-project',
    props: {
      // 是否显示弹窗，可以使用 v-model 双向绑定数据
      value: {
        type: Boolean,
        default: false
      },
      // 是否支持选择多条数据
      multiple: {
        type: Boolean,
        default: false
      },
      // 已选择的数据
      selected: {
        type: Array,
        default() {
          return [];
        }
      },
      // 禁止选择的数据
      disabled: {
        type: Array,
        default() {
          return [];
        }
      },
      dataId: {
        type: [String, Number]
      },
      cityId: {
        type: [String, Number]
      }
    },
    data() {
      return {
        myValue: this.value,
        // formData: {
        //   title: '',
        //   status: ''
        // },
        tableColumns: [
          {
            title: '项目ID',
            key: 'buildingId'
          },
          {
            title: '项目主图',
            width: 220,
            slot: 'pic'
          },
          {
            title: '项目名称',
            slot: 'buildingName'
          },
          {
            title: '项目城市',
            slot: 'cityName'
          },
          {
            title: '项目类型',
            slot: 'category'
          },
          {
            title: '项目分类',
            slot: 'saleType'
          },
          {
            title: '项目状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            width: 80,
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        isLoading: false,
        allCityList: [],
        statusList: [],
        selection: [],
        selectedList: []
      };
    },
    computed: {
      filterColumns() {
        let val = [...this.tableColumns];
        if (this.multiple) {
          val = val.filter(item => item.slot !== 'operation');
          val.unshift({
            type: 'selection',
            width: 60
          });
        }
        return val;
      }
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, {
          activityId: Number(this.dataId),
          buildingCityId: Number(this.cityId),
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.activity.queryUnselectedList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          let tableData = data.list || [];
          this.selectedList.forEach(item => {
            item.onnow = false;
          });
          tableData.forEach(item => {
            let index = this.selectedList.findIndex(user => user.id === item.id);
            if (index !== -1) {
              this.selectedList[index].onnow = true;
            }
            item._checked = index !== -1;
            index = this.disabled.findIndex(user => user.id === item.id);
            item._disabled = index !== -1 || item.buildingInfo.status === 2;
          });
          this.tableData = tableData;
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 获取所有城市列表
       */
      getCityRegionAllList() {
        if (this.allCityList.length > 0) return;
        this.$axios({
          url: this.$api.region.queryCityRegionAllList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          this.allCityList = data || [];
        });
      },
      /**
       * 获取项目状态
       */
      getStatus() {
        if (this.statusList.length > 0) return;
        this.$axios({
          url: this.$api.project.queryBuildingStatus,
          data: {}
        }).then(data => {
          this.statusList = data || [];
        });
      },
      /**
       * 查询
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 重置表单
       */
      resetData() {
        // this.formData.title = '';
        // this.formData.status = '';
      },
      /**
       * 选择数据
       */
      selectData(selection) {
        this.selection = selection;
        let selectedList = this.selectedList.filter(item => !item.onnow);
        this.selection.forEach(item => {
          selectedList.push(Object.assign({}, item, {
            onnow: true
          }));
        });
        this.selectedList = selectedList;
      },
      /**
       * 确定选择
       */
      submitData(row) {
        if (!this.multiple) {
          this.$emit('submit', [row]);
        } else {
          if (this.selectedList.length < 1) {
            this.notice('请先选择项目');
            return;
          }
          this.isLoading = true;
          this.$emit('submit', this.selectedList);
        }
        this.myValue = false;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          this.selectedList = [...this.selected];
          this.getCityRegionAllList();
          this.getStatus();
          this.resetData();
          this.queryData();
        } else {
          this.isLoading = false;
          this.tableData = [];
          this.selection = [];
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>