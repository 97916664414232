/**
 * 超链接
 * luxinwen
 * Developed on 2020-03
 * Updated on 2023-04
 */
<style scoped lang="less">
  @import "./sp-linkto.less";
</style>

<template>
  <div class="sp-linkto">
    <div class="sp-linkto-type">
      <Select v-model="linkType" @on-change="changeSelect" :disabled="disabled" :clearable="typeList.length > 1" :size="size">
        <Option v-for="(item, index) in typeList" :key="'linkto-type' + index" :value="item.type">{{ item.label }}</Option>
      </Select>
    </div>
    <div class="sp-linkto-value" v-for="(item, index) in linkto.data" :key="'linkto-value' + index">
      <div class="sp-linkto-value-input">
        <Input v-model.trim="linkValue[index]" @on-change="changeInput" :disabled="disabled" clearable :size="size">
          <span slot="prepend">{{ item.prefix }}</span>
        </Input>
      </div>
      <div class="sp-linkto-value-button" v-show="Boolean(item.button)">
        <Button type="info" @click="queryData(index)" :disabled="disabled" :size="size">{{ item.button }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'sp-linkto',
    props: {
      // 当前超链接数据，可以使用 v-model 双向绑定数据
      value: {
        type: String,
        default: ''
      },
      // 可跳转下拉列表项，数组元素可用 string 格式匹配预设项，也可用 array 格式新增下拉项
      options: {
        type: Array,
        default() {
          return [];
        }
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
      // 显示规格，可选值 default / small
      size: {
        type: String,
        default: 'default'
      }
    },
    data() {
      return {
        linkList: [
          {
            type: 'web',
            label: '网页',
            data: [
              {
                value: 'url',
                prefix: 'URL'
              }
            ]
          },
          {
            type: 'goods',
            label: '商品',
            data: [
              {
                value: 'goodsId',
                prefix: '商品ID',
                button: '查询'
              }
            ]
          },
          {
            type: 'shop',
            label: '店铺',
            data: [
              {
                value: 'shopId',
                prefix: '店铺ID'
              }
            ]
          },
          {
            type: 'page',
            label: '页面',
            data: [
              {
                value: 'code',
                prefix: '页面编码'
              }
            ]
          },
          {
            type: 'miniPage',
            label: '小程序页面',
            data: [
              {
                value: 'url',
                prefix: '页面地址'
              }
            ]
          },
          {
            type: 'otherMiniPage',
            label: '其它小程序',
            data: [
              {
                value: 'appid',
                prefix: '小程序ID'
              },
              {
                value: 'path',
                prefix: '小程序路径'
              }
            ]
          },
          {
            type: 'search',
            label: '搜索',
            data: [
              {
                value: 'keyword',
                prefix: '关键字'
              }
            ]
          },
          {
            type: 'popover',
            label: '弹窗',
            data: [
              {
                value: 'uuid',
                prefix: '组件ID'
              }
            ]
          },
          {
            type: 'anchor',
            label: '锚点',
            data: [
              {
                value: 'uuid',
                prefix: '组件ID'
              }
            ]
          },
          {
            type: 'tab',
            label: '标签内容',
            data: []
          }
        ],
        linkType: '',
        linkValue: []
      };
    },
    computed: {
      typeList() {
        let val = [];
        this.options.forEach(option => {
          if (typeof option === 'string') {
            let link = this.linkList.find(item => item.type === option);
            if (link) val.push(link);
          } else if (option && option.type && option.label) {
            val.push(option);
          }
        });
        return val;
      },
      linkto() {
        let val = {};
        if (this.linkType) {
          let link = this.typeList.find(item => item.type === this.linkType);
          if (link) val = link;
        }
        if (!val.data) val.data = [];
        return val;
      },
      cType() {
        let val = '';
        if (this.linkType) {
          val = `#type=${this.linkType}`;
        }
        return val;
      },
      cValue() {
        let val = '';
        let temp = [];
        this.linkto.data && this.linkto.data.forEach((item, index) => {
          if (item.value) temp.push(`${item.value}=${this.linkValue[index]}`);
        });
        if (temp.length > 0) {
          val = `&${temp.join('||')}`;
        }
        return val;
      }
    },
    methods: {
      changeSelect(type) {
        this.linkValue = [];
        this.linkto.data && this.linkto.data.forEach(item => {
          this.linkValue.push('');
        });
        this.$emit('input', `${this.cType}${this.cValue}`);
      },
      changeInput() {
        this.$emit('input', `${this.cType}${this.cValue}`);
      },
      queryData(index) {
        let res = {
          type: this.linkType,
          value: this.linkValue[index]
        };
        this.$emit('query', res);
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(val) {
          let linkType = '';
          let linkValue = [];
          if (val) {
            let content = val.split('&');
            linkType = content[0].slice(6);
            if (content.length > 1) {
              let args = content.slice(1).join('&');
              args.split('||').forEach(item => {
                let value = item.slice(item.indexOf('=') + 1) || '';
                linkValue.push(value);
              });
            }
          }
          this.linkType = linkType;
          this.linkValue = linkValue;
        }
      }
    }
  };
</script>