import modalTest from './modal-test.vue';
import modalPreview from './modal-preview.vue';
import modalSelectProject from './modal-select-project.vue';

const components = {
  modalTest,
  modalPreview,
  modalSelectProject
};

const install = function(Vue, opts = {}) {
  if (install.installed) return;
  Object.keys(components).forEach(key => {
    Vue.component(key, components[key]);
  });
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const API = {
  install,
  ...components,
};

export default API;