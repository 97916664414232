const cityData = [
  {
    'name': '北京',
    'suffix': '',
    'code': '110000'
  },
  {
    'name': '天津',
    'suffix': '',
    'code': '120000'
  },
  {
    'name': '河北',
    'code': '130000',
    'city': [
      {
        'name': '石家庄',
        'suffix': '市',
        'code': '130100'
      },
      {
        'name': '唐山',
        'suffix': '市',
        'code': '130200'
      },
      {
        'name': '秦皇岛',
        'suffix': '市',
        'code': '130300'
      },
      {
        'name': '邯郸',
        'suffix': '市',
        'code': '130400'
      },
      {
        'name': '邢台',
        'suffix': '市',
        'code': '130500'
      },
      {
        'name': '保定',
        'suffix': '市',
        'code': '130600'
      },
      {
        'name': '张家口',
        'suffix': '市',
        'code': '130700'
      },
      {
        'name': '承德',
        'suffix': '市',
        'code': '130800'
      },
      {
        'name': '沧州',
        'suffix': '市',
        'code': '130900'
      },
      {
        'name': '廊坊',
        'suffix': '市',
        'code': '131000'
      },
      {
        'name': '衡水',
        'suffix': '市',
        'code': '131100'
      },
      {
        'name': '定州',
        'suffix': '市',
        'code': '131200'
      },
      {
        'name': '辛集',
        'suffix': '市',
        'code': '131300'
      }
    ]
  },
  {
    'name': '山西',
    'code': '140000',
    'city': [
      {
        'name': '太原',
        'suffix': '市',
        'code': '140100'
      },
      {
        'name': '大同',
        'suffix': '市',
        'code': '140200'
      },
      {
        'name': '阳泉',
        'suffix': '市',
        'code': '140300'
      },
      {
        'name': '长治',
        'suffix': '市',
        'code': '140400'
      },
      {
        'name': '晋城',
        'suffix': '市',
        'code': '140500'
      },
      {
        'name': '朔州',
        'suffix': '市',
        'code': '140600'
      },
      {
        'name': '晋中',
        'suffix': '市',
        'code': '140700'
      },
      {
        'name': '运城',
        'suffix': '市',
        'code': '140800'
      },
      {
        'name': '忻州',
        'suffix': '市',
        'code': '140900'
      },
      {
        'name': '临汾',
        'suffix': '市',
        'code': '141000'
      },
      {
        'name': '吕梁',
        'suffix': '市',
        'code': '141100'
      }
    ]
  },
  {
    'name': '内蒙古',
    'code': '150000',
    'city': [
      {
        'name': '呼和浩特',
        'suffix': '市',
        'code': '150100'
      },
      {
        'name': '包头',
        'suffix': '市',
        'code': '150200'
      },
      {
        'name': '乌海',
        'suffix': '市',
        'code': '150300'
      },
      {
        'name': '赤峰',
        'suffix': '市',
        'code': '150400'
      },
      {
        'name': '通辽',
        'suffix': '市',
        'code': '150500'
      },
      {
        'name': '鄂尔多斯',
        'suffix': '市',
        'code': '150600'
      },
      {
        'name': '呼伦贝尔',
        'suffix': '市',
        'code': '150700'
      },
      {
        'name': '巴彦淖尔',
        'suffix': '市',
        'code': '150800'
      },
      {
        'name': '乌兰察布',
        'suffix': '市',
        'code': '150900'
      },
      {
        'name': '兴安盟',
        'suffix': '',
        'code': '152200'
      },
      {
        'name': '锡林郭勒盟',
        'suffix': '',
        'code': '152500'
      },
      {
        'name': '阿拉善盟',
        'suffix': '',
        'code': '152900'
      }
    ]
  },
  {
    'name': '辽宁',
    'code': '210000',
    'city': [
      {
        'name': '沈阳',
        'suffix': '市',
        'code': '210100'
      },
      {
        'name': '大连',
        'suffix': '市',
        'code': '210200'
      },
      {
        'name': '鞍山',
        'suffix': '市',
        'code': '210300'
      },
      {
        'name': '抚顺',
        'suffix': '市',
        'code': '210400'
      },
      {
        'name': '本溪',
        'suffix': '市',
        'code': '210500'
      },
      {
        'name': '丹东',
        'suffix': '市',
        'code': '210600'
      },
      {
        'name': '锦州',
        'suffix': '市',
        'code': '210700'
      },
      {
        'name': '营口',
        'suffix': '市',
        'code': '210800'
      },
      {
        'name': '阜新',
        'suffix': '市',
        'code': '210900'
      },
      {
        'name': '辽阳',
        'suffix': '市',
        'code': '211000'
      },
      {
        'name': '盘锦',
        'suffix': '市',
        'code': '211100'
      },
      {
        'name': '铁岭',
        'suffix': '市',
        'code': '211200'
      },
      {
        'name': '朝阳',
        'suffix': '市',
        'code': '211300'
      },
      {
        'name': '葫芦岛',
        'suffix': '市',
        'code': '211400'
      }
    ]
  },
  {
    'name': '吉林',
    'code': '220000',
    'city': [
      {
        'name': '长春',
        'suffix': '市',
        'code': '220100'
      },
      {
        'name': '吉林',
        'suffix': '市',
        'code': '220200'
      },
      {
        'name': '四平',
        'suffix': '市',
        'code': '220300'
      },
      {
        'name': '辽源',
        'suffix': '市',
        'code': '220400'
      },
      {
        'name': '通化',
        'suffix': '市',
        'code': '220500'
      },
      {
        'name': '白山',
        'suffix': '市',
        'code': '220600'
      },
      {
        'name': '松原',
        'suffix': '市',
        'code': '220700'
      },
      {
        'name': '白城',
        'suffix': '市',
        'code': '220800'
      },
      {
        'name': '延边朝鲜族自治州',
        'suffix': '',
        'code': '222400'
      }
    ]
  },
  {
    'name': '黑龙江',
    'code': '230000',
    'city': [
      {
        'name': '哈尔滨',
        'suffix': '市',
        'code': '230100'
      },
      {
        'name': '齐齐哈尔',
        'suffix': '市',
        'code': '230200'
      },
      {
        'name': '鸡西',
        'suffix': '市',
        'code': '230300'
      },
      {
        'name': '鹤岗',
        'suffix': '市',
        'code': '230400'
      },
      {
        'name': '双鸭山',
        'suffix': '市',
        'code': '230500'
      },
      {
        'name': '大庆',
        'suffix': '市',
        'code': '230600'
      },
      {
        'name': '伊春',
        'suffix': '市',
        'code': '230700'
      },
      {
        'name': '佳木斯',
        'suffix': '市',
        'code': '230800'
      },
      {
        'name': '七台河',
        'suffix': '市',
        'code': '230900'
      },
      {
        'name': '牡丹江',
        'suffix': '市',
        'code': '231000'
      },
      {
        'name': '黑河',
        'suffix': '市',
        'code': '231100'
      },
      {
        'name': '绥化',
        'suffix': '市',
        'code': '231200'
      },
      {
        'name': '大兴安岭',
        'suffix': '地区',
        'code': '232700'
      }
    ]
  },
  {
    'name': '上海',
    'suffix': '',
    'code': '310000'
  },
  {
    'name': '江苏',
    'code': '320000',
    'city': [
      {
        'name': '南京',
        'suffix': '市',
        'code': '320100'
      },
      {
        'name': '无锡',
        'suffix': '市',
        'code': '320200'
      },
      {
        'name': '徐州',
        'suffix': '市',
        'code': '320300'
      },
      {
        'name': '常州',
        'suffix': '市',
        'code': '320400'
      },
      {
        'name': '苏州',
        'suffix': '市',
        'code': '320500'
      },
      {
        'name': '南通',
        'suffix': '市',
        'code': '320600'
      },
      {
        'name': '连云港',
        'suffix': '市',
        'code': '320700'
      },
      {
        'name': '淮安',
        'suffix': '市',
        'code': '320800'
      },
      {
        'name': '盐城',
        'suffix': '市',
        'code': '320900'
      },
      {
        'name': '扬州',
        'suffix': '市',
        'code': '321000'
      },
      {
        'name': '镇江',
        'suffix': '市',
        'code': '321100'
      },
      {
        'name': '泰州',
        'suffix': '市',
        'code': '321200'
      },
      {
        'name': '宿迁',
        'suffix': '市',
        'code': '321300'
      }
    ]
  },
  {
    'name': '浙江',
    'code': '330000',
    'city': [
      {
        'name': '杭州',
        'suffix': '市',
        'code': '330100'
      },
      {
        'name': '宁波',
        'suffix': '市',
        'code': '330200'
      },
      {
        'name': '温州',
        'suffix': '市',
        'code': '330300'
      },
      {
        'name': '嘉兴',
        'suffix': '市',
        'code': '330400'
      },
      {
        'name': '湖州',
        'suffix': '市',
        'code': '330500'
      },
      {
        'name': '绍兴',
        'suffix': '市',
        'code': '330600'
      },
      {
        'name': '金华',
        'suffix': '市',
        'code': '330700'
      },
      {
        'name': '衢州',
        'suffix': '市',
        'code': '330800'
      },
      {
        'name': '舟山',
        'suffix': '市',
        'code': '330900'
      },
      {
        'name': '台州',
        'suffix': '市',
        'code': '331000'
      },
      {
        'name': '丽水',
        'suffix': '市',
        'code': '331100'
      }
    ]
  },
  {
    'name': '安徽',
    'code': '340000',
    'city': [
      {
        'name': '合肥',
        'suffix': '市',
        'code': '340100'
      },
      {
        'name': '芜湖',
        'suffix': '市',
        'code': '340200'
      },
      {
        'name': '蚌埠',
        'suffix': '市',
        'code': '340300'
      },
      {
        'name': '淮南',
        'suffix': '市',
        'code': '340400'
      },
      {
        'name': '马鞍山',
        'suffix': '市',
        'code': '340500'
      },
      {
        'name': '淮北',
        'suffix': '市',
        'code': '340600'
      },
      {
        'name': '铜陵',
        'suffix': '市',
        'code': '340700'
      },
      {
        'name': '安庆',
        'suffix': '市',
        'code': '340800'
      },
      {
        'name': '黄山',
        'suffix': '市',
        'code': '341000'
      },
      {
        'name': '滁州',
        'suffix': '市',
        'code': '341100'
      },
      {
        'name': '阜阳',
        'suffix': '市',
        'code': '341200'
      },
      {
        'name': '宿州',
        'suffix': '市',
        'code': '341300'
      },
      {
        'name': '六安',
        'suffix': '市',
        'code': '341500'
      },
      {
        'name': '亳州',
        'suffix': '市',
        'code': '341600'
      },
      {
        'name': '池州',
        'suffix': '市',
        'code': '341700'
      },
      {
        'name': '宣城',
        'suffix': '市',
        'code': '341800'
      }
    ]
  },
  {
    'name': '福建',
    'code': '350000',
    'city': [
      {
        'name': '福州',
        'suffix': '市',
        'code': '350100'
      },
      {
        'name': '厦门',
        'suffix': '市',
        'code': '350200'
      },
      {
        'name': '莆田',
        'suffix': '市',
        'code': '350300'
      },
      {
        'name': '三明',
        'suffix': '市',
        'code': '350400'
      },
      {
        'name': '泉州',
        'suffix': '市',
        'code': '350500'
      },
      {
        'name': '漳州',
        'suffix': '市',
        'code': '350600'
      },
      {
        'name': '南平',
        'suffix': '市',
        'code': '350700'
      },
      {
        'name': '龙岩',
        'suffix': '市',
        'code': '350800'
      },
      {
        'name': '宁德',
        'suffix': '市',
        'code': '350900'
      }
    ]
  },
  {
    'name': '江西',
    'code': '360000',
    'city': [
      {
        'name': '南昌',
        'suffix': '市',
        'code': '360100'
      },
      {
        'name': '景德镇',
        'suffix': '市',
        'code': '360200'
      },
      {
        'name': '萍乡',
        'suffix': '市',
        'code': '360300'
      },
      {
        'name': '九江',
        'suffix': '市',
        'code': '360400'
      },
      {
        'name': '新余',
        'suffix': '市',
        'code': '360500'
      },
      {
        'name': '鹰潭',
        'suffix': '市',
        'code': '360600'
      },
      {
        'name': '赣州',
        'suffix': '市',
        'code': '360700'
      },
      {
        'name': '吉安',
        'suffix': '市',
        'code': '360800'
      },
      {
        'name': '宜春',
        'suffix': '市',
        'code': '360900'
      },
      {
        'name': '抚州',
        'suffix': '市',
        'code': '361000'
      },
      {
        'name': '上饶',
        'suffix': '市',
        'code': '361100'
      }
    ]
  },
  {
    'name': '山东',
    'code': '370000',
    'city': [
      {
        'name': '济南',
        'suffix': '市',
        'code': '370100'
      },
      {
        'name': '青岛',
        'suffix': '市',
        'code': '370200'
      },
      {
        'name': '淄博',
        'suffix': '市',
        'code': '370300'
      },
      {
        'name': '枣庄',
        'suffix': '市',
        'code': '370400'
      },
      {
        'name': '东营',
        'suffix': '市',
        'code': '370500'
      },
      {
        'name': '烟台',
        'suffix': '市',
        'code': '370600'
      },
      {
        'name': '潍坊',
        'suffix': '市',
        'code': '370700'
      },
      {
        'name': '济宁',
        'suffix': '市',
        'code': '370800'
      },
      {
        'name': '泰安',
        'suffix': '市',
        'code': '370900'
      },
      {
        'name': '威海',
        'suffix': '市',
        'code': '371000'
      },
      {
        'name': '日照',
        'suffix': '市',
        'code': '371100'
      },
      {
        'name': '莱芜',
        'suffix': '市',
        'code': '371200'
      },
      {
        'name': '临沂',
        'suffix': '市',
        'code': '371300'
      },
      {
        'name': '德州',
        'suffix': '市',
        'code': '371400'
      },
      {
        'name': '聊城',
        'suffix': '市',
        'code': '371500'
      },
      {
        'name': '滨州',
        'suffix': '市',
        'code': '371600'
      },
      {
        'name': '菏泽',
        'suffix': '市',
        'code': '371700'
      }
    ]
  },
  {
    'name': '河南',
    'code': '410000',
    'city': [
      {
        'name': '郑州',
        'suffix': '市',
        'code': '410100'
      },
      {
        'name': '开封',
        'suffix': '市',
        'code': '410200'
      },
      {
        'name': '洛阳',
        'suffix': '市',
        'code': '410300'
      },
      {
        'name': '平顶山',
        'suffix': '市',
        'code': '410400'
      },
      {
        'name': '安阳',
        'suffix': '市',
        'code': '410500'
      },
      {
        'name': '鹤壁',
        'suffix': '市',
        'code': '410600'
      },
      {
        'name': '新乡',
        'suffix': '市',
        'code': '410700'
      },
      {
        'name': '焦作',
        'suffix': '市',
        'code': '410800'
      },
      {
        'name': '濮阳',
        'suffix': '市',
        'code': '410900'
      },
      {
        'name': '许昌',
        'suffix': '市',
        'code': '411000'
      },
      {
        'name': '漯河',
        'suffix': '市',
        'code': '411100'
      },
      {
        'name': '三门峡',
        'suffix': '市',
        'code': '411200'
      },
      {
        'name': '南阳',
        'suffix': '市',
        'code': '411300'
      },
      {
        'name': '商丘',
        'suffix': '市',
        'code': '411400'
      },
      {
        'name': '信阳',
        'suffix': '市',
        'code': '411500'
      },
      {
        'name': '周口',
        'suffix': '市',
        'code': '411600'
      },
      {
        'name': '驻马店',
        'suffix': '市',
        'code': '411700'
      },
      {
        'name': '济源',
        'suffix': '市',
        'code': '411800'
      }
    ]
  },
  {
    'name': '湖北',
    'code': '420000',
    'city': [
      {
        'name': '武汉',
        'suffix': '市',
        'code': '420100'
      },
      {
        'name': '黄石',
        'suffix': '市',
        'code': '420200'
      },
      {
        'name': '十堰',
        'suffix': '市',
        'code': '420300'
      },
      {
        'name': '宜昌',
        'suffix': '市',
        'code': '420500'
      },
      {
        'name': '襄阳',
        'suffix': '市',
        'code': '420600'
      },
      {
        'name': '鄂州',
        'suffix': '市',
        'code': '420700'
      },
      {
        'name': '荆门',
        'suffix': '市',
        'code': '420800'
      },
      {
        'name': '孝感',
        'suffix': '市',
        'code': '420900'
      },
      {
        'name': '荆州',
        'suffix': '市',
        'code': '421000'
      },
      {
        'name': '黄冈',
        'suffix': '市',
        'code': '421100'
      },
      {
        'name': '咸宁',
        'suffix': '市',
        'code': '421200'
      },
      {
        'name': '随州',
        'suffix': '市',
        'code': '421300'
      },
      {
        'name': '恩施土家族苗族自治州',
        'suffix': '',
        'code': '422800'
      },
      {
        'name': '仙桃',
        'suffix': '市',
        'code': '422900'
      },
      {
        'name': '潜江',
        'suffix': '市',
        'code': '423000'
      },
      {
        'name': '天门',
        'suffix': '市',
        'code': '423100'
      },
      {
        'name': '神农架林区',
        'suffix': '',
        'code': '423200'
      }
    ]
  },
  {
    'name': '湖南',
    'code': '430000',
    'city': [
      {
        'name': '长沙',
        'suffix': '市',
        'code': '430100'
      },
      {
        'name': '株洲',
        'suffix': '市',
        'code': '430200'
      },
      {
        'name': '湘潭',
        'suffix': '市',
        'code': '430300'
      },
      {
        'name': '衡阳',
        'suffix': '市',
        'code': '430400'
      },
      {
        'name': '邵阳',
        'suffix': '市',
        'code': '430500'
      },
      {
        'name': '岳阳',
        'suffix': '市',
        'code': '430600'
      },
      {
        'name': '常德',
        'suffix': '市',
        'code': '430700'
      },
      {
        'name': '张家界',
        'suffix': '市',
        'code': '430800'
      },
      {
        'name': '益阳',
        'suffix': '市',
        'code': '430900'
      },
      {
        'name': '郴州',
        'suffix': '市',
        'code': '431000'
      },
      {
        'name': '永州',
        'suffix': '市',
        'code': '431100'
      },
      {
        'name': '怀化',
        'suffix': '市',
        'code': '431200'
      },
      {
        'name': '娄底',
        'suffix': '市',
        'code': '431300'
      },
      {
        'name': '湘西土家族苗族自治州',
        'suffix': '',
        'code': '433100'
      }
    ]
  },
  {
    'name': '广东',
    'code': '440000',
    'city': [
      {
        'name': '广州',
        'suffix': '市',
        'code': '440100'
      },
      {
        'name': '韶关',
        'suffix': '市',
        'code': '440200'
      },
      {
        'name': '深圳',
        'suffix': '市',
        'code': '440300'
      },
      {
        'name': '珠海',
        'suffix': '市',
        'code': '440400'
      },
      {
        'name': '汕头',
        'suffix': '市',
        'code': '440500'
      },
      {
        'name': '佛山',
        'suffix': '市',
        'code': '440600'
      },
      {
        'name': '江门',
        'suffix': '市',
        'code': '440700'
      },
      {
        'name': '湛江',
        'suffix': '市',
        'code': '440800'
      },
      {
        'name': '茂名',
        'suffix': '市',
        'code': '440900'
      },
      {
        'name': '肇庆',
        'suffix': '市',
        'code': '441200'
      },
      {
        'name': '惠州',
        'suffix': '市',
        'code': '441300'
      },
      {
        'name': '梅州',
        'suffix': '市',
        'code': '441400'
      },
      {
        'name': '汕尾',
        'suffix': '市',
        'code': '441500'
      },
      {
        'name': '河源',
        'suffix': '市',
        'code': '441600'
      },
      {
        'name': '阳江',
        'suffix': '市',
        'code': '441700'
      },
      {
        'name': '清远',
        'suffix': '市',
        'code': '441800'
      },
      {
        'name': '东莞',
        'suffix': '市',
        'code': '441900'
      },
      {
        'name': '中山',
        'suffix': '市',
        'code': '442000'
      },
      {
        'name': '潮州',
        'suffix': '市',
        'code': '445100'
      },
      {
        'name': '揭阳',
        'suffix': '市',
        'code': '445200'
      },
      {
        'name': '云浮',
        'suffix': '市',
        'code': '445300'
      }
    ]
  },
  {
    'name': '广西',
    'code': '450000',
    'city': [
      {
        'name': '南宁',
        'suffix': '市',
        'code': '450100'
      },
      {
        'name': '柳州',
        'suffix': '市',
        'code': '450200'
      },
      {
        'name': '桂林',
        'suffix': '市',
        'code': '450300'
      },
      {
        'name': '梧州',
        'suffix': '市',
        'code': '450400'
      },
      {
        'name': '北海',
        'suffix': '市',
        'code': '450500'
      },
      {
        'name': '防城港',
        'suffix': '市',
        'code': '450600'
      },
      {
        'name': '钦州',
        'suffix': '市',
        'code': '450700'
      },
      {
        'name': '贵港',
        'suffix': '市',
        'code': '450800'
      },
      {
        'name': '玉林',
        'suffix': '市',
        'code': '450900'
      },
      {
        'name': '百色',
        'suffix': '市',
        'code': '451000'
      },
      {
        'name': '贺州',
        'suffix': '市',
        'code': '451100'
      },
      {
        'name': '河池',
        'suffix': '市',
        'code': '451200'
      },
      {
        'name': '来宾',
        'suffix': '市',
        'code': '451300'
      },
      {
        'name': '崇左',
        'suffix': '市',
        'code': '451400'
      }
    ]
  },
  {
    'name': '海南',
    'code': '460000',
    'city': [
      {
        'name': '海口',
        'suffix': '市',
        'code': '460100'
      },
      {
        'name': '三亚',
        'suffix': '市',
        'code': '460200'
      },
      {
        'name': '三沙',
        'suffix': '市',
        'code': '460300'
      },
      {
        'name': '儋州',
        'suffix': '市',
        'code': '460400'
      },
      {
        'name': '五指山',
        'suffix': '市',
        'code': '469001'
      },
      {
        'name': '琼海',
        'suffix': '市',
        'code': '469002'
      },
      {
        'name': '文昌',
        'suffix': '市',
        'code': '469005'
      },
      {
        'name': '万宁',
        'suffix': '市',
        'code': '469006'
      },
      {
        'name': '东方',
        'suffix': '市',
        'code': '469007'
      },
      {
        'name': '定安县',
        'suffix': '',
        'code': '469021'
      },
      {
        'name': '屯昌县',
        'suffix': '',
        'code': '469022'
      },
      {
        'name': '澄迈县',
        'suffix': '',
        'code': '469023'
      },
      {
        'name': '临高县',
        'suffix': '',
        'code': '469024'
      },
      {
        'name': '白沙黎族自治县',
        'suffix': '',
        'code': '469025'
      },
      {
        'name': '昌江黎族自治县',
        'suffix': '',
        'code': '469026'
      },
      {
        'name': '乐东黎族自治县',
        'suffix': '',
        'code': '469027'
      },
      {
        'name': '陵水黎族自治县',
        'suffix': '',
        'code': '469028'
      },
      {
        'name': '保亭黎族苗族自治县',
        'suffix': '',
        'code': '469029'
      },
      {
        'name': '琼中黎族苗族自治县',
        'suffix': '',
        'code': '469030'
      }
    ]
  },
  {
    'name': '重庆',
    'suffix': '',
    'code': '500000'
  },
  {
    'name': '四川',
    'code': '510000',
    'city': [
      {
        'name': '成都',
        'suffix': '市',
        'code': '510100'
      },
      {
        'name': '自贡',
        'suffix': '市',
        'code': '510300'
      },
      {
        'name': '攀枝花',
        'suffix': '市',
        'code': '510400'
      },
      {
        'name': '泸州',
        'suffix': '市',
        'code': '510500'
      },
      {
        'name': '德阳',
        'suffix': '市',
        'code': '510600'
      },
      {
        'name': '绵阳',
        'suffix': '市',
        'code': '510700'
      },
      {
        'name': '广元',
        'suffix': '市',
        'code': '510800'
      },
      {
        'name': '遂宁',
        'suffix': '市',
        'code': '510900'
      },
      {
        'name': '内江',
        'suffix': '市',
        'code': '511000'
      },
      {
        'name': '乐山',
        'suffix': '市',
        'code': '511100'
      },
      {
        'name': '南充',
        'suffix': '市',
        'code': '511300'
      },
      {
        'name': '眉山',
        'suffix': '市',
        'code': '511400'
      },
      {
        'name': '宜宾',
        'suffix': '市',
        'code': '511500'
      },
      {
        'name': '广安',
        'suffix': '市',
        'code': '511600'
      },
      {
        'name': '达州',
        'suffix': '市',
        'code': '511700'
      },
      {
        'name': '雅安',
        'suffix': '市',
        'code': '511800'
      },
      {
        'name': '巴中',
        'suffix': '市',
        'code': '511900'
      },
      {
        'name': '资阳',
        'suffix': '市',
        'code': '512000'
      },
      {
        'name': '阿坝藏族羌族自治州',
        'suffix': '',
        'code': '513200'
      },
      {
        'name': '甘孜藏族自治州',
        'suffix': '',
        'code': '513300'
      },
      {
        'name': '凉山彝族自治州',
        'suffix': '',
        'code': '513400'
      }
    ]
  },
  {
    'name': '贵州',
    'code': '520000',
    'city': [
      {
        'name': '贵阳',
        'suffix': '市',
        'code': '520100'
      },
      {
        'name': '六盘水',
        'suffix': '市',
        'code': '520200'
      },
      {
        'name': '遵义',
        'suffix': '市',
        'code': '520300'
      },
      {
        'name': '安顺',
        'suffix': '市',
        'code': '520400'
      },
      {
        'name': '毕节',
        'suffix': '市',
        'code': '520500'
      },
      {
        'name': '铜仁',
        'suffix': '市',
        'code': '520600'
      },
      {
        'name': '黔西南布依族苗族自治州',
        'suffix': '',
        'code': '522300'
      },
      {
        'name': '黔东南苗族侗族自治州',
        'suffix': '',
        'code': '522600'
      },
      {
        'name': '黔南布依族苗族自治州',
        'suffix': '',
        'code': '522700'
      }
    ]
  },
  {
    'name': '云南',
    'code': '530000',
    'city': [
      {
        'name': '昆明',
        'suffix': '市',
        'code': '530100'
      },
      {
        'name': '曲靖',
        'suffix': '市',
        'code': '530300'
      },
      {
        'name': '玉溪',
        'suffix': '市',
        'code': '530400'
      },
      {
        'name': '保山',
        'suffix': '市',
        'code': '530500'
      },
      {
        'name': '昭通',
        'suffix': '市',
        'code': '530600'
      },
      {
        'name': '丽江',
        'suffix': '市',
        'code': '530700'
      },
      {
        'name': '普洱',
        'suffix': '市',
        'code': '530800'
      },
      {
        'name': '临沧',
        'suffix': '市',
        'code': '530900'
      },
      {
        'name': '楚雄彝族自治州',
        'suffix': '',
        'code': '532300'
      },
      {
        'name': '红河哈尼族彝族自治州',
        'suffix': '',
        'code': '532500'
      },
      {
        'name': '文山壮族苗族自治州',
        'suffix': '',
        'code': '532600'
      },
      {
        'name': '西双版纳傣族自治州',
        'suffix': '',
        'code': '532800'
      },
      {
        'name': '大理白族自治州',
        'suffix': '',
        'code': '532900'
      },
      {
        'name': '德宏傣族景颇族自治州',
        'suffix': '',
        'code': '533100'
      },
      {
        'name': '怒江傈僳族自治州',
        'suffix': '',
        'code': '533300'
      },
      {
        'name': '迪庆藏族自治州',
        'suffix': '',
        'code': '533400'
      }
    ]
  },
  {
    'name': '西藏',
    'code': '540000',
    'city': [
      {
        'name': '拉萨',
        'suffix': '市',
        'code': '540100'
      },
      {
        'name': '日喀则',
        'suffix': '市',
        'code': '540200'
      },
      {
        'name': '昌都',
        'suffix': '市',
        'code': '540300'
      },
      {
        'name': '林芝',
        'suffix': '市',
        'code': '540400'
      },
      {
        'name': '山南',
        'suffix': '市',
        'code': '540500'
      },
      {
        'name': '那曲',
        'suffix': '地区',
        'code': '542400'
      },
      {
        'name': '阿里',
        'suffix': '地区',
        'code': '542500'
      }
    ]
  },
  {
    'name': '陕西',
    'code': '610000',
    'city': [
      {
        'name': '西安',
        'suffix': '市',
        'code': '610100'
      },
      {
        'name': '铜川',
        'suffix': '市',
        'code': '610200'
      },
      {
        'name': '宝鸡',
        'suffix': '市',
        'code': '610300'
      },
      {
        'name': '咸阳',
        'suffix': '市',
        'code': '610400'
      },
      {
        'name': '渭南',
        'suffix': '市',
        'code': '610500'
      },
      {
        'name': '延安',
        'suffix': '市',
        'code': '610600'
      },
      {
        'name': '汉中',
        'suffix': '市',
        'code': '610700'
      },
      {
        'name': '榆林',
        'suffix': '市',
        'code': '610800'
      },
      {
        'name': '安康',
        'suffix': '市',
        'code': '610900'
      },
      {
        'name': '商洛',
        'suffix': '市',
        'code': '611000'
      }
    ]
  },
  {
    'name': '甘肃',
    'code': '620000',
    'city': [
      {
        'name': '兰州',
        'suffix': '市',
        'code': '620100'
      },
      {
        'name': '嘉峪关',
        'suffix': '市',
        'code': '620200'
      },
      {
        'name': '金昌',
        'suffix': '市',
        'code': '620300'
      },
      {
        'name': '白银',
        'suffix': '市',
        'code': '620400'
      },
      {
        'name': '天水',
        'suffix': '市',
        'code': '620500'
      },
      {
        'name': '武威',
        'suffix': '市',
        'code': '620600'
      },
      {
        'name': '张掖',
        'suffix': '市',
        'code': '620700'
      },
      {
        'name': '平凉',
        'suffix': '市',
        'code': '620800'
      },
      {
        'name': '酒泉',
        'suffix': '市',
        'code': '620900'
      },
      {
        'name': '庆阳',
        'suffix': '市',
        'code': '621000'
      },
      {
        'name': '定西',
        'suffix': '市',
        'code': '621100'
      },
      {
        'name': '陇南',
        'suffix': '市',
        'code': '621200'
      },
      {
        'name': '临夏回族自治州',
        'suffix': '',
        'code': '622900'
      },
      {
        'name': '甘南藏族自治州',
        'suffix': '',
        'code': '623000'
      }
    ]
  },
  {
    'name': '青海',
    'code': '630000',
    'city': [
      {
        'name': '西宁',
        'suffix': '市',
        'code': '630100'
      },
      {
        'name': '海东',
        'suffix': '市',
        'code': '630200'
      },
      {
        'name': '海北藏族自治州',
        'suffix': '',
        'code': '632200'
      },
      {
        'name': '黄南藏族自治州',
        'suffix': '',
        'code': '632300'
      },
      {
        'name': '海南藏族自治州',
        'suffix': '',
        'code': '632500'
      },
      {
        'name': '果洛藏族自治州',
        'suffix': '',
        'code': '632600'
      },
      {
        'name': '玉树藏族自治州',
        'suffix': '',
        'code': '632700'
      },
      {
        'name': '海西蒙古族藏族自治州',
        'suffix': '',
        'code': '632800'
      }
    ]
  },
  {
    'name': '宁夏',
    'code': '640000',
    'city': [
      {
        'name': '银川',
        'suffix': '市',
        'code': '640100'
      },
      {
        'name': '石嘴山',
        'suffix': '市',
        'code': '640200'
      },
      {
        'name': '吴忠',
        'suffix': '市',
        'code': '640300'
      },
      {
        'name': '固原',
        'suffix': '市',
        'code': '640400'
      },
      {
        'name': '中卫',
        'suffix': '市',
        'code': '640500'
      }
    ]
  },
  {
    'name': '新疆',
    'code': '650000',
    'city': [
      {
        'name': '乌鲁木齐',
        'suffix': '市',
        'code': '650100'
      },
      {
        'name': '克拉玛依',
        'suffix': '市',
        'code': '650200'
      },
      {
        'name': '吐鲁番',
        'suffix': '市',
        'code': '650400'
      },
      {
        'name': '哈密',
        'suffix': '市',
        'code': '650500'
      },
      {
        'name': '昌吉回族自治州',
        'suffix': '',
        'code': '652300'
      },
      {
        'name': '博尔塔拉蒙古自治州',
        'suffix': '',
        'code': '652700'
      },
      {
        'name': '巴音郭楞蒙古自治州',
        'suffix': '',
        'code': '652800'
      },
      {
        'name': '阿克苏',
        'suffix': '地区',
        'code': '652900'
      },
      {
        'name': '克孜勒苏柯尔克孜自治州',
        'suffix': '',
        'code': '653000'
      },
      {
        'name': '喀什',
        'suffix': '地区',
        'code': '653100'
      },
      {
        'name': '和田',
        'suffix': '地区',
        'code': '653200'
      },
      {
        'name': '伊犁哈萨克自治州',
        'suffix': '',
        'code': '654000'
      },
      {
        'name': '塔城',
        'suffix': '地区',
        'code': '654200'
      },
      {
        'name': '阿勒泰',
        'suffix': '地区',
        'code': '654300'
      },
      {
        'name': '石河子',
        'suffix': '市',
        'code': '659001'
      },
      {
        'name': '阿拉尔',
        'suffix': '市',
        'code': '659002'
      },
      {
        'name': '图木舒克',
        'suffix': '市',
        'code': '659003'
      },
      {
        'name': '五家渠',
        'suffix': '市',
        'code': '659004'
      },
      {
        'name': '铁门关',
        'suffix': '市',
        'code': '659006'
      }
    ]
  },
  {
    'name': '台湾',
    'code': '710000',
    'city': [
      {
        'name': '金门',
        'suffix': '',
        'code': '710100'
      },
      {
        'name': '连江',
        'suffix': '',
        'code': '710200'
      },
      {
        'name': '苗栗',
        'suffix': '',
        'code': '710300'
      },
      {
        'name': '南投',
        'suffix': '',
        'code': '710400'
      },
      {
        'name': '澎湖',
        'suffix': '',
        'code': '710500'
      },
      {
        'name': '屏东',
        'suffix': '',
        'code': '710600'
      },
      {
        'name': '台东',
        'suffix': '',
        'code': '710700'
      },
      {
        'name': '台中',
        'suffix': '',
        'code': '710800'
      },
      {
        'name': '台南',
        'suffix': '',
        'code': '710900'
      },
      {
        'name': '台北',
        'suffix': '',
        'code': '711000'
      },
      {
        'name': '桃园',
        'suffix': '',
        'code': '711100'
      },
      {
        'name': '云林',
        'suffix': '',
        'code': '711200'
      },
      {
        'name': '新北',
        'suffix': '',
        'code': '711300'
      },
      {
        'name': '彰化',
        'suffix': '',
        'code': '711400'
      },
      {
        'name': '嘉义',
        'suffix': '',
        'code': '711500'
      },
      {
        'name': '新竹',
        'suffix': '',
        'code': '711600'
      },
      {
        'name': '花莲',
        'suffix': '',
        'code': '711700'
      },
      {
        'name': '宜兰',
        'suffix': '',
        'code': '711800'
      },
      {
        'name': '高雄',
        'suffix': '',
        'code': '711900'
      },
      {
        'name': '基隆',
        'suffix': '',
        'code': '712000'
      }
    ]
  },
  {
    'name': '香港',
    'suffix': '特别行政区',
    'code': '810000',
    'type': 'ga'
  },
  {
    'name': '澳门',
    'suffix': '特别行政区',
    'code': '820000',
    'type': 'ga'
  }
];
export default cityData;