/**
 * 业务弹窗-图片预览
 * luxinwen
 * 2023-02
 */
<template>
  <Modal v-model="myValue" :title="titleName" width="800" footer-hide>
    <div class="text-center">
      <img :src="imageUrl" />
    </div>
    <div class="flex_box" v-if="titleName=='查看二维码'">
      <Button type="primary" @click="save">保存</Button>
    </div>
  </Modal>
</template>

<script>
  export default {
    name: 'modal-preview',
    props: {
      // 预览图片地址，可以使用 v-model 双向绑定数据
      value: {
        type: String,
        default: ''
      },
      titleName: {
        type: String,
        default: '预览'
      }
    },
    data() {
      return {
        myValue: false,
        imageUrl: this.value
      };
    },
    methods: {
      save() {
        let alink = document.createElement('a');
        alink.href = this.imageUrl;
        alink.download = '';
        alink.click();
      }
    },
    watch: {
      value(val) {
        if (val) this.imageUrl = val;
        this.myValue = Boolean(val);
      },
      myValue(val) {
        if (!val) this.$emit('input', '');
      }
    }
  };
</script>
<style scoped>
  .flex_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>