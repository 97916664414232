import Vue from 'vue';
import VueRouter from 'vue-router';
import ViewUI from 'view-design';
import Routers from './router/router';
import { VueJsonp } from 'vue-jsonp';
import { setTitle } from './util/util';
import App from './app.vue';
import Base from './plugins/base';
import Api from './util/api';
import Grant from './util/grant';
import Components from './components';
import Modals from './modals';
import './styles/index.less';

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);
Vue.use(ViewUI);
Vue.use(VueJsonp);
Vue.use(Base, {
  instance: {
    '$api': Api,
    '$grant': Grant
  }
});
Vue.use(Components);
Vue.use(Modals);

Vue.config.productionTip = false;

// 路由配置
const router = new VueRouter({
  // mode: 'history',
  routes: Routers
});

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  setTitle(to.meta.title);
  next();
});

router.afterEach(() => {
  ViewUI.LoadingBar.finish();
  window.scrollTo(0, 0);
});

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');