/**
 * 城市选择器
 * luxinwen
 * Developed on 2020-02
 * Updated on 2023-04
 */
<style scoped lang="less">
  @import "./sp-city.less";
</style>

<template>
  <div class="sp-city" :class="selectionClass">
    <div class="sp-city-selection" @click="clickSelection">
      <span class="sp-city-selection-placeholder" v-if="!currentValue">{{ placeholder }}</span>
      <span class="sp-city-selection-selected" v-else>{{ currentValue }}</span>
      <i class="sp-city-selection-arrow"></i>
      <Icon type="ios-close-circle" class="sp-city-selection-close" v-if="!!currentValue && clearable" @click.stop="clickClose" />
    </div>
    <transition name="drop">
      <div ref="spCityMain" class="sp-city-drop" v-show="isOpened" :style="mainStyle">
        <div class="sp-city-drop-menu">
          <div class="sp-city-drop-type">
            <div class="sp-city-drop-type-group">
              <label class="sp-city-drop-type-group-button" :class="{'checked': this.type === 'prov'}" @click="changeType('prov')">按省份</label><label class="sp-city-drop-type-group-button" :class="{'checked': this.type === 'city'}" @click="changeType('city')">按城市</label>
            </div>
          </div>
          <div class="sp-city-drop-search"></div>
        </div>
        <div class="sp-city-drop-list">
          <div class="sp-city-drop-list-letter">
            <span class="sp-city-drop-list-letter-tag" v-for="(item, index) in letterList" :key="index" @click="tabLetter(item)">{{ item }}</span>
          </div>
          <div ref="spCityList" class="sp-city-drop-list-main" :class="listClass">
            <dl>
              <template v-for="(item, index) in infoList">
                <dt :key="index + '-0'" :ref="'title' + item.letter">{{ item.name }}：</dt>
                <dd :key="index + '-1'">
                  <li v-for="(city, i) in item.city" :key="i" @click="setValue(city)">{{ city.name }}</li>
                </dd>
              </template>
            </dl>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import cityData from './data.js';

  export default {
    name: 'sp-city',
    props: {
      // 指定选中的城市 value 值，可以使用 v-model 双向绑定数据
      value: {
        type: String,
        default: ''
      },
      // 是否返回城市后缀，如“广州市”、“香港特别行政区”
      suffix: {
        type: Boolean,
        default: false
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
      // 是否显示边框
      border: {
        type: Boolean,
        default: false
      },
      // 是否可以清空选项
      clearable: {
        type: Boolean,
        default: true
      },
      // 默认显示文字
      placeholder: {
        type: String,
        default: '请选择'
      }
    },
    // Vue默认的v-model绑定参数和处理事件名称，如无必要就不更改
    /*
    model: {
      prop: 'value',
      event: 'input'
    },
    */
    data() {
      return {
        currentValue: this.value,
        cityData,
        isOpened: false,
        type: 'prov', // 展示类型，可选值为 prov（省份），city（城市）
        mainHeight: 0,
        mainStyle: {},
        letter: 'abcdefghjklmnopqrstwxyz'.split(''),
        zhCn: '阿八嚓哒妸发旮哈讥咔垃痳拏噢妑七呥扨它穵夕丫帀'.split(''),
        provLetter: [],
        cityLetter: [],
        provList: [],
        cityList: [],
        allCity: []
      };
    },
    computed: {
      selectionClass() {
        return {
          'disabled': this.disabled,
          'border': this.border,
          'opened': this.isOpened
        };
      },
      listClass() {
        return `${this.type}-list`;
      },
      letterList() {
        return this.type === 'prov' ? this.provLetter : this.cityLetter;
      },
      infoList() {
        return this.type === 'prov' ? this.provList : this.cityList;
      }
    },
    created() {
      let documentHandler = (e) => {
        let el = this.$el;
        if (el.contains(e.target)) {
          return false;
        }
        this.isOpened = false;
      };
      this.__vueClickOutside__ = documentHandler;
      document.addEventListener('click', documentHandler);
    },
    mounted() {
      this.initData();
      // 获取城市内容区域高度
      this.mainStyle = {
        'top': '-9999px'
      };
      this.isOpened = true;
      this.$nextTick(() => {
        this.mainHeight = this.$refs.spCityMain.clientHeight;
        this.isOpened = false;
      });
    },
    beforeDestroy() {
      document.removeEventListener('click', this.__vueClickOutside__);
      delete this.__vueClickOutside__;
    },
    methods: {
      /**
       * 点击选择
       */
      clickSelection() {
        if (this.disabled) return;
        this.isOpened = !this.isOpened;
      },
      /**
       * 点击清除
       */
      clickClose() {
        this.currentValue = '';
        this.setValue();
      },
      /**
       * 切换省份、城市
       */
      changeType(type) {
        this.type = type;
        this.tabLetter(this.letterList[0]);
      },
      /**
       * 初始化数据
       */
      initData() {
        let provList = [];
        let cityList = [];
        let zxsList = [];
        let gaList = [];
        this.cityData.forEach(item => {
          // 没有下属城市，说明该数据要么是直辖市，要么是港澳地区
          if (!item.city) {
            let cityTemp = {
              name: item.name,
              suffix: item.suffix,
              letter: ''
            };
            this.setLetter(cityTemp);
            if (item.type === 'ga') {
              gaList.push(cityTemp);
            } else {
              zxsList.push(cityTemp);
            }
            cityList.push(cityTemp);
          } else {
            let provTemp = {
              name: item.name,
              letter: '',
              city: []
            };
            this.setLetter(provTemp);
            item.city.forEach(city => {
              let cityTemp = {
                name: city.name,
                suffix: city.suffix,
                letter: ''
              };
              this.setLetter(cityTemp);
              cityList.push(cityTemp);
              provTemp.city.push(cityTemp);
            });
            provList.push(provTemp);
          }
        });
        this.setProvList(provList, zxsList, gaList);
        this.setCityList(cityList);
        this.allCity = cityList;
      },
      /**
       * 设置省份列表
       */
      setProvList(provList, zxsList, gaList) {
        let zxs = '直辖市';
        let ga = '港澳';
        this.sortLetter(provList);
        this.provList = [
          ...provList,
          {
            name: zxs,
            letter: zxs,
            city: zxsList
          },
          {
            name: ga,
            letter: ga,
            city: gaList
          }
        ];
        let provLetter = [];
        this.provList.forEach(item => {
          if (provLetter.indexOf(item.letter) === -1) {
            provLetter.push(item.letter);
          }
        });
        this.provLetter = provLetter;
      },
      /**
       * 设置城市列表
       */
      setCityList(cityList) {
        let cityJson = {};
        let resultList = [];
        let cityLetter = [];
        this.sortLetter(cityList);
        cityList.forEach(item => {
          if (cityJson[item.letter]) {
            cityJson[item.letter].push(item);
          } else {
            cityJson[item.letter] = [item];
          }
          if (cityLetter.indexOf(item.letter) === -1) {
            cityLetter.push(item.letter);
          }
        });
        Object.keys(cityJson).forEach(key => {
          let list = cityJson[key];
          this.sortLetter(list, 'name', 1);
          resultList.push({
            name: key,
            letter: key,
            city: list
          });
        });
        this.cityList = resultList;
        this.cityLetter = cityLetter;
      },
      /**
       * 按名称首拼音排序
       */
      sortLetter(arr, key = 'name', index) {
        return arr.sort((a, b) => {
          return this.getValue(a, key, index).localeCompare(this.getValue(b, key, index), 'zh');
        });
      },
      /**
       * 获取数值
       */
      getValue(obj, key, index) {
        let result;
        if (!key) {
          result = obj;
        } else {
          result = obj[key];
        }
        if (index) {
          let txts = result.split('');
          if (txts.length > index) {
            result = txts[index];
          }
        }
        return result;
      },
      /**
       * 设置拼音归属
       */
      setLetter(item, key = 'name', index = 0) {
        let letter = this.letter;
        let zh = this.zhCn;
        if (item[key].indexOf('日') === 0) { // 日照 和 日喀则 比较特殊，会被识别为S开头，所以手动处理
          item.letter = 'R';
        } else if (item[key].indexOf('长') === 0) {  // 长春、长沙 会被识别为Z开头，手动处理
          item.letter = 'C';
        } else if (zh[index] && item[key].localeCompare(zh[index], 'zh') >= 0 && (index === letter.length - 1 || item[key].localeCompare(zh[index + 1], 'zh') < 0)) {
          item.letter = letter[index].toUpperCase();
        } else {
          let i = index;
          i++;
          if (i < letter.length) {
            this.setLetter(item, key, i);
          }
        }
      },
      /**
       * 切换拼音
       */
      tabLetter(letter) {
        let elList = this.$refs.spCityList;
        let elTitle = this.$refs[`title${letter}`];
        let elFirst = this.$refs[`title${this.letterList[0]}`];
        if (elList && elTitle) {
          elList.scrollTop = elTitle[0].offsetTop - elFirst[0].offsetTop;
        }
      },
      /**
       * 设置数值
       */
      setValue(city) {
        let val = '';
        if (city) {
          val = city.name;
          if (this.suffix) val += city.suffix;
        }
        this.isOpened = false;
        this.$emit('input', val);
      }
    },
    watch: {
      value(val) {
        this.currentValue = val;
      },
      isOpened(val) {
        if (val && this.mainHeight > 0) {
          let mainStyle = {};
          let el = this.$el;
          if (document.body.clientHeight - el.getBoundingClientRect().bottom < this.mainHeight) {
            mainStyle['top'] = -this.mainHeight + 'px';
            mainStyle['transform-origin'] = 'center bottom';
          }
          this.mainStyle = mainStyle;
        }
      }
    }
  };
</script>