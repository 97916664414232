/**
 * 省市区联动选择
 * luxinwen
 * Developed on 2022-09
 * Updated on 2023-02
 */
<style scoped lang="less">
  @import "./sp-region.less";
</style>

<template>
  <div class="sp-region" :class="selectionClass">
    <div class="sp-region-selection" @click="clickSelection">
      <span class="sp-region-selection-placeholder" v-if="!showValue">{{ placeholder }}</span>
      <span class="sp-region-selection-selected" v-else>{{ showValue }}</span>
      <Icon type="ios-arrow-down" class="sp-region-selection-arrow" />
      <Icon type="ios-close-circle" class="sp-region-selection-arrow" v-if="showValue && clearable" @click.stop="clickClose" />
    </div>
    <div ref="sp-region-dropdown" class="sp-region-dropdown" :class="dropdownClass" :style="dropdownStyle">
      <div class="sp-region-dropdown-head">
        <p :class="{'active': regionLevel === 0}" @click="changeHead(0)">{{ provName }}</p>
        <p :class="{'active': regionLevel === 1}" @click="changeHead(1)">{{ cityName }}</p>
        <p :class="{'active': regionLevel === 2}" @click="changeHead(2)">{{ countyName }}</p>
      </div>
      <div class="sp-region-dropdown-body" v-show="regionLevel === 0">
        <p v-for="item in provData" :key="'prov' + item.code" @click="selectArea(item, 0)">{{ item.name }}</p>
      </div>
      <div class="sp-region-dropdown-body" v-show="regionLevel === 1">
        <p v-for="item in cityData" :key="'city' + item.code" @click="selectArea(item, 1)">{{ item.name }}</p>
      </div>
      <div class="sp-region-dropdown-body" v-show="regionLevel === 2">
        <p v-for="item in countyData" :key="'county' + item.code" @click="selectArea(item, 2)">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import regionList from './data.js';

  export default {
    name: 'sp-region',
    props: {
      // 指定选中的省市区 value 值，可以使用 v-model 双向绑定数据。Array 子项值接受 String 或 Number 或 Object 类型
      value: {
        type: Array,
        default() {
          return [];
        }
      },
      // 返回数据格式，可选值为 object / code / name
      format: {
        type: String,
        default: 'name'
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
      // 是否可以清空选项
      clearable: {
        type: Boolean,
        default: true
      },
      // 选择框默认文字
      placeholder: {
        type: String,
        default: '请选择'
      }
    },
    data() {
      return {
        currentValue: this.value,
        showValue: '',
        regionList,
        regionName: ['province_list', 'city_list', 'county_list'],
        regionLevel: 0,
        isOpened: false,
        dropdownClass: [],
        dropdownStyle: {},
        cssHeight: 280, // 内容区域高度
        cssWidth: 592,  // 内容区域宽度
        provData: [],
        cityData: [],
        countyData: []
      };
    },
    computed: {
      selectionClass() {
        return {
          'disabled': this.disabled,
          'opened': this.isOpened
        };
      },
      provName() {
        let val = '省/直辖市';
        if (this.currentValue.length > 0) {
          val = this.currentValue[0].name;
        }
        return val;
      },
      cityName() {
        let val = '市';
        if (this.currentValue.length > 1) {
          val = this.currentValue[1].name;
        }
        return val;
      },
      countyName() {
        let val = '区';
        if (this.currentValue.length > 2) {
          val = this.currentValue[2].name;
        }
        return val;
      }
    },
    created() {
      this.changeHead(0);
      let documentHandler = (e) => {
        let el = this.$el;
        if (el.contains(e.target)) {
          return false;
        }
        this.isOpened = false;
      };
      this.__vueClickOutside__ = documentHandler;
      document.addEventListener('click', documentHandler);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.__vueClickOutside__);
      delete this.__vueClickOutside__;
    },
    methods: {
      /**
       * 点击下拉框
       */
      clickSelection() {
        if (this.disabled) return;
        this.isOpened = !this.isOpened;
      },
      /**
       * 点击清除
       */
      clickClose() {
        this.isOpened = false;
        this.currentValue = [];
        this.setValue();
      },
      /**
       * 切换省市区
       */
      changeHead(level) {
        let name = this.regionName[level];
        let list = [];
        let object = this.regionList[name];
        if (level === 0) {
          Object.keys(object).forEach(key => {
            list.push({
              code: key,
              name: object[key]
            });
          });
          this.provData = list;
        } else if (level === 1) {
          if (this.currentValue.length < 1) return;
          Object.keys(object).forEach(key => {
            let code = String(key);
            let prefix = (String(this.currentValue[0].code)).substring(0, 2);
            if (code.indexOf(prefix) === 0) {
              list.push({
                code: key,
                name: object[key]
              });
            }
          });
          this.cityData = list;
        } else if (level === 2) {
          if (this.currentValue.length < 2) return;
          Object.keys(object).forEach(key => {
            let code = String(key);
            let prefix = (String(this.currentValue[1].code)).substring(0, 4);
            if (code.indexOf(prefix) === 0) {
              list.push({
                code: key,
                name: object[key]
              });
            }
          });
          this.countyData = list;
        }
        this.regionLevel = level;
      },
      /**
       * 选择省市区
       */
      selectArea(item, level) {
        let currentValue = this.currentValue.slice(0, level);
        currentValue.push(item);
        this.currentValue = currentValue;
        if (level < 2) {
          this.regionLevel++;
          this.changeHead(this.regionLevel);
        } else {
          this.setValue();
          this.isOpened = false;
        }
      },
      /**
       * 选定传值
       */
      setValue() {
        let currentValue = [];
        if (this.format === 'name') {
          currentValue = this.currentValue.map(item => item.name);
        } else if (this.format === 'code') {
          currentValue = this.currentValue.map(item => Number(item.code));
        } else {
          currentValue = this.currentValue.map(item => {
            return {
              code: Number(item.code),
              name: item.name
            };
          });
        }
        this.$emit('input', currentValue);
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          let currentValue = [];
          newVal && newVal.forEach((item, index) => {
            let regionData = this.regionList[this.regionName[index]];
            if (typeof item === 'string') {
              let region = Object.keys(regionData).find(key => regionData[key] === item);
              if (region) {
                currentValue.push({
                  code: Number(region),
                  name: item
                });
              }
            } else if (typeof item === 'number') {
              let region = Object.keys(regionData).find(key => Number(key) === item);
              if (region) {
                currentValue.push({
                  code: item,
                  name: regionData[item]
                });
              }
            } else {
              let region = Object.keys(regionData).find(key => regionData[key] === item.name || Number(key) === Number(item.code));
              if (region) {
                currentValue.push({
                  code: Number(region),
                  name: regionData[region]
                });
              }
              // currentValue.push(item);
            }
          });
          this.currentValue = currentValue;
          this.showValue = currentValue.map(item => item.name).join(' / ');
        }
      },
      isOpened(newVal) {
        if (newVal) {
          this.regionLevel = 0;
          this.dropdownClass.push('drop-enter');
          let el = this.$el;
          let dropdownStyle = {};
          let rect = el.getBoundingClientRect();
          if (this.cssWidth > 0 && document.body.clientWidth - rect.left < this.cssWidth) {
            dropdownStyle['right'] = '0';
          }
          if (this.cssHeight > 0 && document.body.clientHeight - rect.bottom < this.cssHeight) {
            dropdownStyle['top'] = -this.cssHeight - 10 + 'px';
            dropdownStyle['transform-origin'] = 'center bottom';
          }
          this.dropdownStyle = dropdownStyle;
        } else {
          this.dropdownClass.push('drop-leave');
          setTimeout(() => {
            this.dropdownClass = [];
          }, 300);
        }
      }
    }
  };
</script>