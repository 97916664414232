/**
 * 业务弹窗-示例
 * luxinwen
 * 2023-02
 */
<style scoped lang="less">
  .selected {
    width: 500px;
  }
</style>

<template>
  <Modal v-model="myValue" title="业务弹窗-示例" width="900" :mask-closable="false" :footer-hide="!multiple">
    <div class="page-main">
      <div class="page-main-content">
        <div class="selected">
          已选择：{{ selectedList.map(item => item.name).join('，') }}
        </div>
        <div class="right-form">
          <Form :model="formData" inline>
            <FormItem>
              <Input class="width-xs" v-model="formData.title" placeholder="请输入标题" clearable />
            </FormItem>
            <FormItem>
              <Select class="width-xs" v-model="formData.status" placeholder="请选择状态" clearable>
                <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryData">查询</Button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage" @select="selectData">
          <template slot-scope="params" slot="operation">
            <Button @click="submitData(params.row)" :disabled="params.row._disabled">选取</Button>
          </template>
        </sp-table>
      </div>
    </div>
    <div slot="footer">
      <Button type="text" size="large" @click="myValue = false">取消</Button>
      <Button type="primary" size="large" :loading="isLoading" @click="submitData">确定</Button>
    </div>
  </Modal>
</template>

<script>
  import { TEST_STATUS } from '@/util/enum';

  export default {
    name: 'modal-test',
    props: {
      // 是否显示弹窗，可以使用 v-model 双向绑定数据
      value: {
        type: Boolean,
        default: false
      },
      // 是否支持选择多条数据
      multiple: {
        type: Boolean,
        default: false
      },
      // 已选择的数据
      selected: {
        type: Array,
        default() {
          return [];
        }
      },
      // 禁止选择的数据
      disabled: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        myValue: this.value,
        formData: {
          title: '',
          status: ''
        },
        tableColumns: [
          {
            title: '标题',
            key: 'name'
          },
          {
            title: '编码',
            key: 'code'
          },
          {
            title: '状态',
            render: (h, params) => {
              return h('span', this.statusList[params.row.status]);
            }
          },
          {
            title: '创建时间',
            key: 'time'
          },
          {
            title: '操作',
            slot: 'operation',
            width: 80,
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        isLoading: false,
        statusList: TEST_STATUS,
        selection: [],
        selectedList: []
      };
    },
    computed: {
      filterColumns() {
        let val = [...this.tableColumns];
        if (this.multiple) {
          val = val.filter(item => item.slot !== 'operation');
          val.unshift({
            type: 'selection',
            width: 60
          });
        }
        return val;
      }
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.test.queryTestList,
          mock: true,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          let tableData = data.list || [];
          this.selectedList.forEach(item => {
            item.onnow = false;
          });
          tableData.forEach(item => {
            let index = this.selectedList.findIndex(user => user.id === item.id);
            if (index !== -1) {
              this.selectedList[index].onnow = true;
            }
            item._checked = index !== -1;
            index = this.disabled.findIndex(user => user.id === item.id);
            item._disabled = index !== -1 || item.status === 0;
          });
          this.tableData = tableData;
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 重置表单
       */
      resetData() {
        this.formData.title = '';
        this.formData.status = '';
      },
      /**
       * 选择数据
       */
      selectData(selection) {
        this.selection = selection;
        let selectedList = this.selectedList.filter(item => !item.onnow);
        this.selection.forEach(item => {
          selectedList.push(Object.assign({}, item, {
            onnow: true
          }));
        });
        this.selectedList = selectedList;
      },
      /**
       * 确定选择
       */
      submitData(row) {
        if (!this.multiple) {
          this.$emit('submit', [row]);
        } else {
          if (this.selectedList.length < 1) {
            this.notice('请先选择数据');
            return;
          }
          this.isLoading = true;
          this.$emit('submit', this.selectedList);
        }
        this.myValue = false;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          this.selectedList = [...this.selected];
          this.resetData();
          this.queryData();
        } else {
          this.isLoading = false;
          this.tableData = [];
          this.selection = [];
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>