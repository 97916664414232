export default {
  '/osp/sysMenu/querySysMenus': {
    'code': 'S0A00000',
    'msg': '操作成功',
    'data': [
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT_CHARTS',
        'menuId': 23105,
        'menuName': '大屏幕展示',
        'menuOrder': 23105,
        'menuStatus': 0,
        'menuUrl': '/osp/cms/report/statisCms,/osp/cms/inquiry/statisInquiry,/osp/ospGoods/statisMMBGSNum,/osp/order/dealRank4Top10Merchant,/osp/order/monthlyGrowthRate4TranVolume,/osp/order/monthlySalesVolume',
        'merchantCode': 'OSP',
        'parentMenuId': 1500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT_ORDER_DETAIL',
        'menuId': 23104,
        'menuName': '订单明细报表',
        'menuOrder': 23104,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 1500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT_ORDER_FINISH',
        'menuId': 23103,
        'menuName': '订单完成明细报表',
        'menuOrder': 23103,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 1500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT_INQUIRY',
        'menuId': 23102,
        'menuName': '咨询服务报表',
        'menuOrder': 23102,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 1500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT_CMS',
        'menuId': 23101,
        'menuName': '信息服务报表',
        'menuOrder': 23068,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 1500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_PLATFORM_TELEPHONE',
        'menuId': 23099,
        'menuName': '服务电话维护',
        'menuOrder': 23099,
        'menuStatus': 0,
        'menuUrl': '/osp/platformInfo/queryServicePhoneList,/osp/platformInfo/queryServicePhoneDetail,/osp/platformInfo/saveOrUpdateServicePhone,/osp/platformInfo/disableServicePhone,/osp/platformInfo/deleteServicePhoneById',
        'merchantCode': 'OSP',
        'parentMenuId': 23097,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_PLATFORM_ACCOUNT',
        'menuId': 23098,
        'menuName': '对公帐户维护',
        'menuOrder': 23098,
        'menuStatus': 0,
        'menuUrl': '/osp/platformInfo/queryCorporateAccountList,/osp/platformInfo/queryCorporateAccountDetail,/osp/platformInfo/saveOrUpdateCorporateAccount,/osp/platformInfo/disableCorporateAccount,/osp/platformInfo/deleteCorporateAccountById',
        'merchantCode': 'OSP',
        'parentMenuId': 23097,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_PLATFORM',
        'menuId': 23097,
        'menuName': '平台信息维护',
        'menuOrder': 23184,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_STORE_DELIVERY',
        'menuId': 23096,
        'menuName': '提货点管理',
        'menuOrder': 22303,
        'menuStatus': 0,
        'menuUrl': '/mall/order/shop/selfDelivery/querySelfDeliveryList,/mall/order/shop/selfDelivery/updateSelfDelivery,/mall/order/shop/selfDelivery/addSelfDelivery,/mall/order/shop/selfDelivery/deleteSelfDelivery',
        'merchantCode': 'shop',
        'parentMenuId': 22301,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_GOODS_MODEL',
        'menuId': 23093,
        'menuName': '型号管理',
        'menuOrder': 23088,
        'menuStatus': 0,
        'menuUrl': '/shop/goods/shop/goodsShop/queryGoodsModelList,/shop/goods/shop/goodsShop/queryGoodsModelDetail,/shop/goods/shop/goodsShop/saveOrUpdateGoodsModel,/shop/goods/shop/goodsShop/onOrOffGoodsModel',
        'merchantCode': 'shop',
        'parentMenuId': 22001,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_CMS_RENT',
        'menuId': 23092,
        'menuName': '租赁信息',
        'menuOrder': 23092,
        'menuStatus': 0,
        'menuUrl': '/cms/biz/cms/tags/list,/cms/biz/cms/rent/list,/cms/biz/cms/rent/info,/cms/biz/cms/rent/save,/cms/biz/cms/rent/update,/cms/biz/cms/rent/status/update,/cms/biz/cms/rent/delete',
        'merchantCode': 'shop',
        'parentMenuId': 23088,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_CMS_PROJECT',
        'menuId': 23091,
        'menuName': '工程项目',
        'menuOrder': 23091,
        'menuStatus': 0,
        'menuUrl': '/cms/biz/cms/tags/list,/cms/biz/cms/project/list,/cms/biz/cms/project/status/update,/cms/biz/cms/project/info,/cms/biz/cms/project/save,/cms/biz/cms/project/update,/cms/biz/cms/project/delete',
        'merchantCode': 'shop',
        'parentMenuId': 23088,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_CMS_RESUME',
        'menuId': 23090,
        'menuName': '求职信息',
        'menuOrder': 23090,
        'menuStatus': 0,
        'menuUrl': '/cms/biz/cms/tags/list,/cms/biz/cms/resume/list,/cms/biz/cms/resume/info',
        'merchantCode': 'shop',
        'parentMenuId': 23088,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_CMS_POSITION',
        'menuId': 23089,
        'menuName': '招聘信息',
        'menuOrder': 23089,
        'menuStatus': 0,
        'menuUrl': '/cms/biz/cms/tags/list,/cms/biz/cms/position/list,/cms/biz/cms/position/info,/cms/biz/cms/position/save,/cms/biz/cms/position/update,/cms/biz/cms/position/status/update,/cms/biz/cms/position/delete',
        'merchantCode': 'shop',
        'parentMenuId': 23088,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_CMS',
        'menuId': 23088,
        'menuName': '信息服务',
        'menuOrder': 23138,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_GOODS_SERIES',
        'menuId': 23087,
        'menuName': '系列管理',
        'menuOrder': 23087,
        'menuStatus': 0,
        'menuUrl': '/shop/goods/shop/goodsShop/queryGoodsSeriesList,/shop/goods/shop/goodsShop/queryGoodsSeriesDetail,/shop/goods/shop/goodsShop/saveOrUpdateGoodsSeries,/shop/goods/shop/goodsShop/onOrOffGoodsSeries',
        'merchantCode': 'shop',
        'parentMenuId': 22001,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_GOODS_ESLIST',
        'menuId': 23086,
        'menuName': '二手挖掘机',
        'menuOrder': 22004,
        'menuStatus': 0,
        'menuUrl': '/secondHand/shop/secondHandGoods/saveOrUpdate,/secondHand/shop/secondHandGoods/queryGoodsCategoryList,/secondHand/shop/secondHandGoods/get,/secondHand/shop/secondHandGoods/getList,/secondHand/shop/secondHandGoods/getUpList,/secondHand/shop/secondHandGoods/submitForReview,/secondHand/shop/secondHandGoods/savePictures',
        'merchantCode': 'shop',
        'parentMenuId': 22001,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_INQUIRY_RECORD',
        'menuId': 23085,
        'menuName': '咨询记录',
        'menuOrder': 23085,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23084,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_INQUIRY',
        'menuId': 23084,
        'menuName': '咨询服务',
        'menuOrder': 23182,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_FAQ_SETTING',
        'menuId': 23083,
        'menuName': 'FAQ设置',
        'menuOrder': 23083,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23082,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_FAQ',
        'menuId': 23082,
        'menuName': 'FAQ',
        'menuOrder': 23174,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_RENT',
        'menuId': 23081,
        'menuName': '租赁信息',
        'menuOrder': 23081,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_PROJECT',
        'menuId': 23080,
        'menuName': '工程项目',
        'menuOrder': 23080,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_NEWS',
        'menuId': 23079,
        'menuName': '行业资讯',
        'menuOrder': 23079,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_POLICY',
        'menuId': 23078,
        'menuName': '政策信息',
        'menuOrder': 23078,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_RESUME',
        'menuId': 23077,
        'menuName': '求职信息',
        'menuOrder': 23077,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_POSITION',
        'menuId': 23076,
        'menuName': '招聘信息',
        'menuOrder': 23076,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS_TAG',
        'menuId': 23075,
        'menuName': '标签管理',
        'menuOrder': 23075,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23074,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CMS',
        'menuId': 23074,
        'menuName': '信息服务',
        'menuOrder': 23163,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_GOODS_ESLIST',
        'menuId': 23068,
        'menuName': '二手挖掘机',
        'menuOrder': 506,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT_TRANSACTION_SUMMARY',
        'menuId': 23067,
        'menuName': '交易汇总报表',
        'menuOrder': 23037,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 1500,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'xxcj',
        'menuId': 23065,
        'menuName': '信息采集',
        'menuOrder': 23064,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 23063,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_CONTRACT_MGR',
        'menuId': 23046,
        'menuName': '合同管理',
        'menuOrder': 304,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'OSP',
        'parentMenuId': 300,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'GXT_PRIVILEGE_RECORD_SYS',
        'menuId': 23042,
        'menuName': '权益核销明细',
        'menuOrder': 23042,
        'menuStatus': 0,
        'menuUrl': '/member-privilege/member-privilege/queryPrivilegeRecordPage,/member-privilege/member-privilege/privilegeRecordDownload,/member-privilege/member-privilege/queryAppSceneList,/member-privilege/member-privilege/queryAppPrivilegeRecordList,/member-privilege/member-privilege/queryAppMemberPrivilegeSceneList',
        'merchantCode': 'shop',
        'parentMenuId': 23038,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'GXT_PRIVILEGE_ITEM_SYS',
        'menuId': 23041,
        'menuName': '权益发放',
        'menuOrder': 23041,
        'menuStatus': 0,
        'menuUrl': '/member-privilege/privilegeItem/downloadUserPhoneTemplate,/member-privilege/privilegeItem/uploadUserPhoneFile,/member-privilege/privilegeItem/queryMemberLevelList,/member-privilege/privilegeItem/queryPrivilegeSceneRelationList,/member-privilege/privilegeItem/editPrivilegeItem,/member-privilege/privilegeItem/queryPrivilegeItemPage,/member-privilege/privilegeItem/queryPrivilegeItemInfo,/member-privilege/privilegeItem/queryPrivilegeMemberPage,/member-privilege/privilegeItem/editItemStatus',
        'merchantCode': 'shop',
        'parentMenuId': 23038,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'GXT_PRIVILEGE_PRIVILEGE_SYS',
        'menuId': 23040,
        'menuName': '权益合作',
        'menuOrder': 23040,
        'menuStatus': 0,
        'menuUrl': '/member-privilege/privilegeInfo/queryPrivilegeInfoPage,/member-privilege/privilegeInfo/queryPrivilegeInfo,/member-privilege/privilegeInfo/queryMerchantList,/member-privilege/privilegeInfo/editPrivilegeInfo,/member-privilege/privilegeInfo/querySceneList',
        'merchantCode': 'shop',
        'parentMenuId': 23038,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'GXT_PRIVILEGE_SCENE_SYS',
        'menuId': 23039,
        'menuName': '权益配置',
        'menuOrder': 23039,
        'menuStatus': 0,
        'menuUrl': '/member-privilege/privilegeScene/queryScenePage,/member-privilege/privilegeScene/querySceneList,/member-privilege/privilegeScene/querySceneInfoById,/member-privilege/privilegeScene/editSceneInfo,/member-privilege/privilegeScene/queryMerchantMemberList,/member-privilege/privilegeScene/editSceneStatus',
        'merchantCode': 'shop',
        'parentMenuId': 23038,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_DAYORDER_LIST',
        'menuId': 23035,
        'menuName': '当日订单查询',
        'menuOrder': 22208,
        'menuStatus': 0,
        'menuUrl': '/ecmall/shop/order2B/getOrderPageForStoreWorker,/ecmall/shop/order2B/notifyUserToGetFood,/integration/common/queryLogisticCorpList',
        'merchantCode': 'shop',
        'parentMenuId': 22201,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'GXT_MERCHANT_USER_SYS',
        'menuId': 23034,
        'menuName': '用户管理',
        'menuOrder': 23034,
        'menuStatus': 0,
        'menuUrl': '/merchant/shop/sysUser/add,/merchant/shop/sysUser/update,/merchant/shop/sysUser/queryUsers,/merchant/shop/sysUser/delete,/merchant/shop/sysUser/shopResetPasscode,/merchant/shop/sysUser/ospResetPasscode,/merchant/shop/store/queryAll,/merchant/shop/store/queryStoreListByMerchantInfo',
        'merchantCode': 'shop',
        'parentMenuId': 23033,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'GXT_MERCHANT_SYS',
        'menuId': 23033,
        'menuName': '商户端系统管理',
        'menuOrder': 23130,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_DATA_REPORT_STORE_REVENUE',
        'menuId': 23032,
        'menuName': '商户订单销售情况统计',
        'menuOrder': 23032,
        'menuStatus': 0,
        'menuUrl': '/statistics/shop/ecmall/report/storeOrderSaleListExport,/ecmall/shop/order2B/queryStoreOrderSaleList',
        'merchantCode': 'shop',
        'parentMenuId': 23030,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_DATA_REPORT_STORE_GOODS_REVENUE',
        'menuId': 23031,
        'menuName': '商户商品销售情况统计',
        'menuOrder': 23031,
        'menuStatus': 0,
        'menuUrl': '/ecmall/shop/goodsShop/queryStoreGoodsSaleList,/statistics/shop/ecmall/report/storeGoodsSaleListExport',
        'merchantCode': 'shop',
        'parentMenuId': 23030,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_DATA_REPORT',
        'menuId': 23030,
        'menuName': '数据报表',
        'menuOrder': 23123,
        'menuStatus': 0,
        'menuUrl': '/shop/dataReport',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_MULTI_COUPON',
        'menuId': 23029,
        'menuName': '套票管理',
        'menuOrder': 1005,
        'menuStatus': 0,
        'menuUrl': '/osp/coupon/shortQuery,/osp/coupon/pageMultiCouponBatch,/osp/coupon/addMultiCouponBatch,/osp/coupon/editMultiCouponBatch,/osp/coupon/optMultiCouponBatch',
        'merchantCode': 'OSP',
        'parentMenuId': 1000,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_MEMBER_INFO',
        'menuId': 23024,
        'menuName': '会员信息查询',
        'menuOrder': 23024,
        'menuStatus': 0,
        'menuUrl': '/member/osp/info/queryOspMemberInfo,/osp/memberGrowth/queryMemberGrowthByMemberId',
        'merchantCode': 'shop',
        'parentMenuId': 23023,
        'parentMenuName': ''
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_MEMBER',
        'menuId': 23023,
        'menuName': '会员信息管理',
        'menuOrder': 23101,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_SETTLEMENT_QUERY',
        'menuId': 22902,
        'menuName': '财务账单查询',
        'menuOrder': 22902,
        'menuStatus': 0,
        'menuUrl': '/settlement/report/merchant/queryFiles,/settlement/report/merchant/download',
        'merchantCode': 'shop',
        'parentMenuId': 22901,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_SETTLEMENT',
        'menuId': 22901,
        'menuName': '清结算管理',
        'menuOrder': 22901,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_STORE_INFO',
        'menuId': 22302,
        'menuName': '店铺信息维护',
        'menuOrder': 22302,
        'menuStatus': 0,
        'menuUrl': '/merchant/shop/store/queryByCurrentUser,/merchant/shop/store/update,/integration/common/uploadImage,/point/shop/pointHistory/uploadImageOrWord,/merchant/shop/store/getFoodCourtStoreMiniCode',
        'merchantCode': 'shop',
        'parentMenuId': 22301,
        'parentMenuName': null
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_STORE',
        'menuId': 22301,
        'menuName': '店铺管理',
        'menuOrder': 22301,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': null
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_SELF_DELIVERY_VERIFY_EXPORT',
        'menuId': 22207,
        'menuName': '自提码核销导出',
        'menuOrder': 22207,
        'menuStatus': 0,
        'menuUrl': '/ecmall/shop/exportLog/queryByPage',
        'merchantCode': 'shop',
        'parentMenuId': 22201,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_SELF_DELIVERY_VERIFY_QUERY',
        'menuId': 22206,
        'menuName': '自提码核销查询',
        'menuOrder': 22206,
        'menuStatus': 0,
        'menuUrl': '/ecmall/shop/selfDeliveryValidation/queryExportPage,/ecmall/shop/selfDeliveryValidation/exportSelfDeliveryValidation',
        'merchantCode': 'shop',
        'parentMenuId': 22201,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_ORDER_EXPORT',
        'menuId': 22205,
        'menuName': '订单导出',
        'menuOrder': 22205,
        'menuStatus': 0,
        'menuUrl': '/ecmall/shop/exportLog/queryByPage',
        'merchantCode': 'shop',
        'parentMenuId': 22201,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_REFUND_LIST',
        'menuId': 22204,
        'menuName': '售后列表',
        'menuOrder': 22204,
        'menuStatus': 0,
        'menuUrl': '/mall/order/shop/refund/queryRefundByPage,/merchant/shop/store/queryByCurrentUser,/mall/order/shop/refund/doAgreeReturn,/mall/order/shop/refund/doAgreeReturnFee,/mall/order/shop/refund/doAgreeRefund,/mall/order/shop/refund/doAgreeRefundFee,/mall/order/shop/refund/doRejectRefund,/mall/order/shop/refund/doRejectReturn,/mall/order/shop/refund/queryLogisticsTraceByLogisticCode,/mall/order/shop/refund/getOrderById,/ecmall/shop/selfDeliveryValidation/queryValidationListByOrderId,/mall/common/shop/operationLog/queryOrderGoodsOperationLog',
        'merchantCode': 'shop',
        'parentMenuId': 22201,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_ORDER_IMPORT_DELIVERY',
        'menuId': 22203,
        'menuName': '批量导入物流单号',
        'menuOrder': 22203,
        'menuStatus': 0,
        'menuUrl': '/ecmall/shop/order2B/importDelivery,/ecmall/shop/order2B/queryLogisticImportResultPage',
        'merchantCode': 'shop',
        'parentMenuId': 22201,
        'parentMenuName': null
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_ORDER_LIST',
        'menuId': 22202,
        'menuName': '订单查询',
        'menuOrder': 22202,
        'menuStatus': 0,
        'menuUrl': '/mall/order/shop/order/getOrderPage,/mall/order/shop/order/getOrderById,/integration/common/queryLogisticCorpList,/integration/common/queryCityRegionByRegionId,/integration/common/queryCityRegionList,/mall/order/shop/order/orderDelivery,/mall/order/shop/order/getNonDeliveryOrderItems,/mall/order/shop/order/queryLogisticsTraceByLogisticCode,/ecmall/shop/order2B/exportOrderItemExportList,/ecmall/shop/selfDeliveryValidation/queryValidationListByOrderId,/ecmall/shop/order2B/queryOrderGoodsOperationLog,/ecmall/shop/order2B/notifyUserToGetFood,/mall/order/shop/order/updateGoodsPrice',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': null
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_ORDER',
        'menuId': 22201,
        'menuName': '订单管理',
        'menuOrder': 22201,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': null
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_GOODS_LIST',
        'menuId': 22002,
        'menuName': '商品列表',
        'menuOrder': 22002,
        'menuStatus': 0,
        'menuUrl': '/shop/goods/shop/goodsShop/queryGoodsList,/shop/goods/shop/goodsShop/saveGoods,/shop/goods/shop/goodsShop/updateGoods,/shop/goods/shop/goodsShop/queryGoodsDetailById,/shop/goods/shop/goodsShop/onOrOffGoods,/shop/goods/shop/goodsShop/queryAllGoodsBrand,/shop/goods/shop/goodsShop/queryAllGoodsCategory,/shop/goods/shop/goodsShop/queryAllGoodsSubcategory,/shop/goods/shop/goodsShop/queryAllGoodsSeries,/shop/goods/shop/goodsShop/queryAllGoodsModel,/integration/common/uploadImage',
        'merchantCode': 'shop',
        'parentMenuId': 22001,
        'parentMenuName': null
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'SHOP_MENU_GOODS',
        'menuId': 22001,
        'menuName': '商品管理',
        'menuOrder': 22001,
        'menuStatus': 0,
        'menuUrl': '/',
        'merchantCode': 'shop',
        'parentMenuId': 0,
        'parentMenuName': null
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_DATA_REPORT',
        'menuId': 1500,
        'menuName': '数据报表',
        'menuOrder': 1500,
        'menuStatus': 0,
        'menuUrl': '/osp/dataReport',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT_MERCHANT_CREATE',
        'menuId': 1306,
        'menuName': '商户账单生成',
        'menuOrder': 1306,
        'menuStatus': 0,
        'menuUrl': '/osp/st/merchant/coupon,/osp/st/merchant/ebusiness,/osp/st/merchant/points,/osp/merchant/queryAll',
        'merchantCode': 'OSP',
        'parentMenuId': 1300,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT_FINANCE_CREATE',
        'menuId': 1305,
        'menuName': '财务账单生成',
        'menuOrder': 1305,
        'menuStatus': 0,
        'menuUrl': '/osp/st/finance/coupon,/osp/st/finance/ebusiness,/osp/st/finance/points',
        'merchantCode': 'OSP',
        'parentMenuId': 1300,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT_DATA_PULL',
        'menuId': 1304,
        'menuName': '数据同步',
        'menuOrder': 1304,
        'menuStatus': 0,
        'menuUrl': '/osp/st/pull/merchantDataPull,/osp/st/pull/pointsDetailPull,/osp/st/pull/importBillFile',
        'merchantCode': 'OSP',
        'parentMenuId': 1300,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT_THIRD',
        'menuId': 1303,
        'menuName': '第三方支付对账查询',
        'menuOrder': 1303,
        'menuStatus': 0,
        'menuUrl': '/osp/st/download',
        'merchantCode': 'OSP',
        'parentMenuId': 1300,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT_MERCHANT',
        'menuId': 1302,
        'menuName': '商户积分对账单',
        'menuOrder': 1302,
        'menuStatus': 0,
        'menuUrl': '/osp/st/download',
        'merchantCode': 'OSP',
        'parentMenuId': 1300,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT_MANAGE',
        'menuId': 1301,
        'menuName': '账单管理',
        'menuOrder': 1301,
        'menuStatus': 0,
        'menuUrl': '/osp/st/merchant/query,/osp/st/finance/query,/osp/st/download,/osp/merchant/queryAll,/osp/settlement/reimbursement/mergeSubmitReimbursement',
        'merchantCode': 'OSP',
        'parentMenuId': 1300,
        'parentMenuName': '清结算管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SETTLEMENT',
        'menuId': 1300,
        'menuName': '清结算管理',
        'menuOrder': 1300,
        'menuStatus': 0,
        'menuUrl': '/osp/settlement',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SYSTEM_CONFIG_TIMING_TASK',
        'menuId': 1101,
        'menuName': '定时任务',
        'menuOrder': 1101,
        'menuStatus': 0,
        'menuUrl': '/osp/task/addTask,/osp/task/updateTask,/osp/task/pauseTask,/osp/task/resumeTask,/osp/task/deleteTask,/osp/task/queryJobInfoByPage',
        'merchantCode': 'OSP',
        'parentMenuId': 900,
        'parentMenuName': '系统管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SYS_MENU',
        'menuId': 903,
        'menuName': '菜单管理',
        'menuOrder': 903,
        'menuStatus': 0,
        'menuUrl': '/osp/sysMenu/querySysMenus,/osp/sysMenu/add,/osp/sysMenu/delete,/osp/sysMenu/update',
        'merchantCode': 'OSP',
        'parentMenuId': 900,
        'parentMenuName': '系统管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SYS_USER',
        'menuId': 902,
        'menuName': '用户管理',
        'menuOrder': 902,
        'menuStatus': 0,
        'menuUrl': '/osp/sysUser/add,/osp/sysUser/update,/osp/sysUser/queryUsers,/osp/sysRole/queryRoles,/osp/merchant/queryAll,/osp/store/queryStoreList,/osp/sysAuth/bindingUserRole,/osp/sysUser/ospResetPasscode',
        'merchantCode': 'OSP',
        'parentMenuId': 900,
        'parentMenuName': '系统管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SYS_ROLE',
        'menuId': 901,
        'menuName': '角色管理',
        'menuOrder': 901,
        'menuStatus': 0,
        'menuUrl': '/osp/sysRole/queryRoles,/osp/sysMenu/querySysMenus,/osp/sysRole/update,/osp/sysRole/add,/osp/sysRole/delete,/osp/sysAuth/roleId2MenuId,/osp/sysAuth/bindingRoleMenu',
        'merchantCode': 'OSP',
        'parentMenuId': 900,
        'parentMenuName': '系统管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SYS',
        'menuId': 900,
        'menuName': '系统管理',
        'menuOrder': 900,
        'menuStatus': 0,
        'menuUrl': '/osp/sysUser',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_PMT_COMPONENT',
        'menuId': 601,
        'menuName': '组件页面管理',
        'menuOrder': 601,
        'menuStatus': 0,
        'menuUrl': '/osp/component/saveOrUpdateComponent,/osp/component/getById,/osp/component/getByCode,/osp/component/listComponent,/osp/component/updateStatus,/integration/common/uploadImage,/osp/ecmall/goods/batchQueryGoodsInfo,/osp/actGoods/getOspActivityInprogressGoodsList,/osp/ecmall/goods/getGoodsByCategoryCode',
        'merchantCode': 'OSP',
        'parentMenuId': 600,
        'parentMenuName': '运营管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_PMT',
        'menuId': 600,
        'menuName': '运营管理',
        'menuOrder': 600,
        'menuStatus': 0,
        'menuUrl': '/osp/component',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_GOODS_BRAND',
        'menuId': 505,
        'menuName': '品牌管理',
        'menuOrder': 505,
        'menuStatus': 0,
        'menuUrl': '/osp/goods/osp/ospGoods/queryGoodsBrandList,/osp/goods/osp/ospGoods/queryGoodsBrandDetail,/osp/goods/osp/ospGoods/saveOrUpdateGoodsBrand',
        'merchantCode': 'OSP',
        'parentMenuId': 500,
        'parentMenuName': '商品管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_GOODS_LIST',
        'menuId': 503,
        'menuName': '商品列表',
        'menuOrder': 503,
        'menuStatus': 0,
        'menuUrl': '/osp/store/queryStoreList,/osp/merchant/queryAll,/osp/goods/osp/ospGoods/queryGoodsList,/osp/goods/osp/ospGoods/queryGoodsDetailById,/osp/goods/osp/ospGoods/auditGoodsDetail,/osp/goods/osp/ospGoods/deleteGoodsById,/osp/goods/osp/ospGoods/queryAllGoodsBrand,/osp/goods/osp/ospGoods/queryAllGoodsCategory',
        'merchantCode': 'OSP',
        'parentMenuId': 500,
        'parentMenuName': '商品管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_GOODS_CATEGORY',
        'menuId': 502,
        'menuName': '商品类别',
        'menuOrder': 502,
        'menuStatus': 0,
        'menuUrl': '/osp/promotion/goodsCategory/ospSaveOrUpdateGoodsCategory,/osp/promotion/goodsCategory/ospQueryGoodsCategoryListWithPage,/osp/goods/osp/ospGoods/queryGoodsCategoryList,/osp/goods/osp/ospGoods/queryGoodsCategoryDetail,/osp/goods/osp/ospGoods/saveOrUpdateGoodsCategory,/osp/goods/osp/ospGoods/queryGoodsSubcategoryList,/osp/goods/osp/ospGoods/queryGoodsSubcategoryDetail,/osp/goods/osp/ospGoods/saveOrUpdateGoodsSubcategory',
        'merchantCode': 'OSP',
        'parentMenuId': 500,
        'parentMenuName': '商品管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_GOODS_MGM',
        'menuId': 500,
        'menuName': '商品管理',
        'menuOrder': 500,
        'menuStatus': 0,
        'menuUrl': '/osp/goods',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_REFUND_LIST',
        'menuId': 405,
        'menuName': '售后列表',
        'menuOrder': 405,
        'menuStatus': 0,
        'menuUrl': '/osp/mall/refund/queryRefundByPage,/osp/mall/order/getOrderById,/osp/mall/selfDeliveryValidation/queryValidationListByOrderId,/osp/mall/order/queryLogisticsTraceByLogisticCode,/osp/mall/order/queryOrderGoodsOperationLog',
        'merchantCode': 'OSP',
        'parentMenuId': 400,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_SELF_DELIVERY_VERIFY_QUERY',
        'menuId': 403,
        'menuName': '自提码核销查询',
        'menuOrder': 403,
        'menuStatus': 0,
        'menuUrl': '/osp/mall/selfDeliveryValidation/queryExportPage,/osp/selfDeliveryValidation/exportSelfDeliveryValidation,/osp/merchant/queryAll,/osp/store/queryStoreList',
        'merchantCode': 'OSP',
        'parentMenuId': 400,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_ORDER_EXPORT',
        'menuId': 402,
        'menuName': '订单导出',
        'menuOrder': 402,
        'menuStatus': 0,
        'menuUrl': '/osp/mall/exportLog/queryByPage',
        'merchantCode': 'OSP',
        'parentMenuId': 400,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_ORDER_LIST',
        'menuId': 401,
        'menuName': '订单列表',
        'menuOrder': 401,
        'menuStatus': 0,
        'menuUrl': '/osp/mall/order/getOrderById,/osp/mall/order/getOrderPage,/osp/merchant/queryMerchantList,/osp/merchant/queryAll,/osp/store/queryStoreList,/osp/mall/order/queryLogisticsTraceByLogisticCode,/osp/mall/order/exportOrderItemExportList,/osp/mall/selfDeliveryValidation/queryValidationListByOrderId,/osp/mall/common/queryOrderGoodsOperationLog',
        'merchantCode': 'OSP',
        'parentMenuId': 400,
        'parentMenuName': '订单管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_ORDER',
        'menuId': 400,
        'menuName': '订单管理',
        'menuOrder': 400,
        'menuStatus': 0,
        'menuUrl': '/osp/order',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_ORGANIZATION_MGR',
        'menuId': 303,
        'menuName': '组织管理',
        'menuOrder': 303,
        'menuStatus': 0,
        'menuUrl': '/osp/organization/queryAll,/osp/organization/add,/osp/organization/deleteById,/osp/organization/queryOrganizationList,/osp/organization/update',
        'merchantCode': 'OSP',
        'parentMenuId': 300,
        'parentMenuName': '商户管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_STORE_MGR',
        'menuId': 302,
        'menuName': '店铺管理',
        'menuOrder': 302,
        'menuStatus': 0,
        'menuUrl': '/osp/store/add,/osp/store/update,/osp/store/queryStoreList,/integration/common/uploadImage,/merchant/osp/merchant/queryMerchantList,/merchant/osp/merchant/queryAll,/osp/store/storeInfoReview',
        'merchantCode': 'OSP',
        'parentMenuId': 300,
        'parentMenuName': '商户管理'
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_MERCHANT_MGR',
        'menuId': 301,
        'menuName': '商户管理',
        'menuOrder': 301,
        'menuStatus': 0,
        'menuUrl': '/osp/merchant/queryMerchantList,/osp/merchant/queryAll,/osp/merchant/add,/osp/merchant/update,/merchant/osp/organization/queryAll,/integration/common/uploadContractFile,/osp/merchantContract/queryContract,/osp/merchantContract/queryContract/operateLog,/osp/merchantContract/queryContractList,/osp/merchantContract/updateContractStatus',
        'merchantCode': 'OSP',
        'parentMenuId': 300,
        'parentMenuName': '商户管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_MERCHANT',
        'menuId': 300,
        'menuName': '商户管理',
        'menuOrder': 300,
        'menuStatus': 0,
        'menuUrl': '/merchant/osp/merchant',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      },
      {
        'isLeaf': 1,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_MEMBER_INFO',
        'menuId': 101,
        'menuName': '会员信息管理',
        'menuOrder': 101,
        'menuStatus': 0,
        'menuUrl': '/member/osp/info/queryOspMemberInfo,/member/osp/info/batchChangeStatus,/member/osp/info/queryOspMemberProfileInfo,/osp/point/query/queryAccountBalance,/osp/merchant/queryMerchantList,/osp/store/queryStoreList,/osp/memberGrowth/queryMemberGrowthByMemberId,/osp/memberGrowth/queryGrowthTransactionDetailList,/member/osp/info/sendSmsCode,/member/osp/info/changeMobile,/member/osp/info/queryMemberModifyInfoList,/osp/point/orderItem/queryByMemberId,/osp/point/query/queryTxnList,/member/osp/info/pageQueryOspMemberInfo',
        'merchantCode': 'OSP',
        'parentMenuId': 100,
        'parentMenuName': '会员管理'
      },
      {
        'isLeaf': 0,
        'isButtonControl': 1,
        'menuCode': 'OSP_MENU_MEMBER',
        'menuId': 100,
        'menuName': '会员管理',
        'menuOrder': 100,
        'menuStatus': 0,
        'menuUrl': '/osp/member',
        'merchantCode': 'OSP',
        'parentMenuId': 0,
        'parentMenuName': ''
      }
    ],
    'time': '2023-03-15T10:30:53.660+08:00',
    'success': true
  }
};