/**
 * 腾讯地图
 * luxinwen
 * Developed on 2023-05
 */
<template>
  <div>
    <div ref="sp-map-t" :style="mapStyle"></div>
  </div>
</template>

<script>
  import md5 from 'md5';

  export default {
    name: 'sp-map-t',
    props: {
      // 中心点地址
      center: {
        type: String
      },
      // 中心点坐标
      point: {
        type: Object
      },
      // 图层宽度
      width: {
        type: String,
        default: '100%'
      },
      // 图层高度
      height: {
        type: String,
        default: '400px'
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        apiKey: 'JQXBZ-SSU6G-IAOQR-QPEQF-X4PGO-4NB3D',
        apiSig: 'u3zIVfKvsUfpgOoSzW2rtcxKdFiMpdC1',
        address: this.center,
        lat: 39.915,  // 纬度
        lng: 116.604, // 经度
        TMap: null,
        map: null,
        marker: null,
        mapHandler: null
      };
    },
    computed: {
      mapStyle() {
        return {
          width: this.width,
          height: this.height
        };
      }
    },
    mounted() {
      if (!window.TMap) {
        this.loadScript('https://map.qq.com/api/gljs?v=1.exp&key=' + this.apiKey).then(res => {
          this.initMap();
        });
      } else {
        this.initMap();
      }
    },
    beforeDestroy() {
      if (this.map === null || this.mapHandler === null) return;
      // 组件销毁时，及时解绑点击事件
      this.map.off('click', this.mapHandler);
    },
    methods: {
      /**
       * 加载JS
       */
      loadScript(src) {
        console.log('loadScript');
        return new Promise((resolve, reject) => {
          let script = document.createElement('script');
          script.onload = () => {
            resolve();
          };
          script.onerror = err => {
            reject(err);
          };
          script.src = src;
          (document.head || document.body).appendChild(script);
        });
      },
      /**
       * 初始化地图
       */
      initMap() {
        console.log('initMap');
        let TMap = window.TMap;
        if (!TMap) return;
        // 设置中心点坐标
        let center = new TMap.LatLng(this.lat, this.lng);
        // 创建地图
        let map = new TMap.Map(this.$refs['sp-map-t'], {
          center: center, // 设置地图中心点坐标
          zoom: 15, // 设置地图缩放级别
          // pitch: 43.5,  // 设置俯仰角
          // rotation: 45  // 设置地图旋转角度
        });
        // 创建标注
        let marker = new TMap.MultiMarker({
          map: map,
          geometries: [
            {
              id: '1',
              position: center
            }
          ]
        });
        this.marker = marker;
        if (!this.disabled) {
          this.mapHandler = e => {
            this.lat = Number(e.latLng.getLat().toFixed(6));
            this.lng = Number(e.latLng.getLng().toFixed(6));
            this.getAddress(this.lat, this.lng, result => {
              this.marker.updateGeometries([
                {
                  id: '1',
                  position: new TMap.LatLng(this.lat, this.lng),
                  properties: {
                    title: this.address
                  }
                }
              ]);
            });
          };
          // 监听点击事件
          map.on('click', this.mapHandler);
        }
        this.TMap = TMap;
        this.map = map;
        if (this.address && !this.point.lat && !this.point.lng) this.searchByAddress(this.address);
      },
      /**
       * 根据坐标得到地址描述
       */
      getAddress(lat, lng, callback) {
        if (!this.map || !lat || !lng) return;
        console.log('getAddress', lat, lng);
        let host = 'https://apis.map.qq.com';
        let path = '/ws/geocoder/v1/';
        let params = `?callback=jsonpCallback&key=${this.apiKey}&location=${lat},${lng}&output=jsonp`;
        let sig = md5(path + params + this.apiSig);
        this.$jsonp(host + path, {
          callbackQuery: 'callback',
          callbackName: 'jsonpCallback',
          key: this.apiKey,
          location: lat + ',' + lng,
          output: 'jsonp',
          sig: sig
        }).then(res => {
          if (res.status === 0) {
            let result = res.result;
            this.address = result.address;
            console.log('getAddress callback', this.address);
            if (callback && typeof callback === 'function') callback(result);
            this.setValue();
          }
        });
      },
      /**
       * 根据地址获得坐标信息
       */
      getPoint(address, callback) {
        if (!this.map || !address) return;
        console.log('getPoint', address);
        let host = 'https://apis.map.qq.com';
        let path = '/ws/geocoder/v1/';
        let params = `?address=${address}&callback=jsonpCallback&key=${this.apiKey}&output=jsonp`;
        let sig = md5(path + params + this.apiSig);
        this.$jsonp(host + path, {
          address: address,
          callbackQuery: 'callback',
          callbackName: 'jsonpCallback',
          key: this.apiKey,
          output: 'jsonp',
          sig: sig
        }).then(res => {
          if (res.status === 0) {
            let result = res.result;
            this.lat = result.location.lat;
            this.lng = result.location.lng;
            console.log('getPoint callback', this.lat, this.lng);
            if (callback && typeof callback === 'function') callback(result);
            this.setValue();
          }
        });
      },
      /**
       * 根据地址搜索地图
       */
      searchByAddress(address) {
        console.log('searchByAddress', address);
        this.getPoint(address, result => {
          let TMap = this.TMap;
          if (TMap) {
            let center = new TMap.LatLng(this.lat, this.lng);
            this.map.setCenter(center);
            this.marker.updateGeometries([
              {
                id: '1',
                position: center,
                properties: {
                  title: address
                }
              }
            ]);
          } else {
            this.initMap();
          }
        });
      },
      /**
       * 根据坐标搜索地图
       */
      searchByPoint(lat, lng) {
        console.log('searchByPoint', lat, lng);
        this.getAddress(lat, lng, result => {
          let TMap = this.TMap;
          if (TMap) {
            let center = new TMap.LatLng(lat, lng);
            this.map.setCenter(center);
            this.marker.updateGeometries([
              {
                id: '1',
                position: center,
                properties: {
                  title: this.address
                }
              }
            ]);
          } else {
            this.initMap();
          }
        });
      },
      /**
       * 设置传值
       */
      setValue() {
        this.$emit('on-change', {
          address: this.address,
          lat: this.lat,
          lng: this.lng
        });
      }
    },
    watch: {
      center: {
        immediate: true,
        handler(newVal) {
          console.log('map watch center', newVal);
          if (newVal && newVal !== this.address) {
            this.address = newVal;
            this.searchByAddress(newVal);
          }
        }
      },
      point: {
        immediate: true,
        handler(newVal) {
          console.log('map watch point', newVal);
          if (newVal && newVal.lng && newVal.lat && newVal.lng !== this.lng && newVal.lat !== this.lat) {
            this.lat = newVal.lat;
            this.lng = newVal.lng;
            this.searchByPoint(this.lat, this.lng);
          }
        }
      }
    }
  };
</script>