export default {
  '/test/queryList': {
    'code': 'S0A00000',
    'msg': '操作成功',
    'data': {
      'list': [
        {
          'id': 1,
          'name': 'MOCK-lxw',
          'code': 'AC001',
          'pic': 'https://file03.16sucai.com/2016/10/1100/16sucai_p20161016147_5be.JPG',
          'price': 3,
          'status': 0,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 2,
          'name': 'MOCK-hauengs',
          'code': 'AC002',
          'pic': 'https://file03.16sucai.com/2017/1100/16sucai_P59201F258.JPG',
          'price': 4.5,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 3,
          'name': 'MOCK-ziekths',
          'code': 'AC003',
          'pic': 'https://file03.16sucai.com/2017/1100/16sucai_p567c138.JPG',
          'price': 3999,
          'status': 2,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 4,
          'name': 'MOCK-weidong',
          'code': 'AC004',
          'pic': 'https://file03.16sucai.com/2016/10/1100/16sucai_p20161006152_4ba.JPG',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 5,
          'name': 'MOCK-weidonswrg',
          'code': 'AC005',
          'pic': 'http://img.faakee.com/uploadfiles/image/201811/1542094457.jpg',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 6,
          'name': 'MOCK-大东保的',
          'code': 'AC006',
          'pic': 'https://file03.16sucai.com/2016/10/1100/16sucai_p20161006152_4ba.JPG',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 7,
          'name': 'MOCK-陈佳佳',
          'code': 'AC007',
          'pic': 'http://img.faakee.com/uploadfiles/image/201811/1542094457.jpg',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 8,
          'name': 'MOCK-欧阳楚楚',
          'code': 'AC008',
          'pic': 'http://img.faakee.com/uploadfiles/image/201811/1542094457.jpg',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 9,
          'name': 'MOCK-你是一个大笨蛋',
          'code': 'AC009',
          'pic': 'http://img.faakee.com/uploadfiles/image/201811/1542094457.jpg',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        },
        {
          'id': 10,
          'name': 'MOCK-来啊来啊你来啊',
          'code': 'AC010',
          'pic': 'https://alifei02.cfp.cn/creative/vcg/veer/800/new/VCG41N179346685.abcpdf',
          'price': 27856,
          'status': 1,
          'time': '2023-02-01 10:45:22'
        }
      ],
      'total': 20
    }
  },
  '/test/queryDetail': {
    'code': 'S0A00000',
    'msg': '操作成功',
    'data': {
      'id': 1,
      'name': 'MOCK-lxw',
      'code': 'AC001',
      'status': 1,
      'price': 59,
      'phone': '13532112323',
      'time': ['2023-02-01 10:45:22', '2023-02-05 10:45:22']
    }
  },
  '/test/delete': {
    'code': 'S0A00000',
    'msg': '操作成功'
  },
  '/test/add': {
    'code': 'S0A00000',
    'msg': '操作成功'
  },
  '/test/update': {
    'code': 'S0A00000',
    'msg': '操作成功'
  },
  '/test/upload': {
    'code': 'S0A00000',
    'msg': '操作成功',
    'data': {
      'url': 'https://file03.16sucai.com/2016/10/1100/16sucai_p20161016147_5be.JPG'
    }
  }
};