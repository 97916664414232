/**
 * 通用组件 table + page 组合
 * luxinwen
 * Developed on 2020-02
 * Updated on 2023-01
 */
<style scoped lang="less">
  @import "./sp-table.less";
</style>

<template>
  <div class="sp-table">
    <Table :data="data" :columns="columns" :stripe="stripe" :border="border" :show-header="showHeader" :height="height" :max-height="maxHeight" :loading="loading" :disabled-hover="disabledHover" :size="size" :no-data-text="noDataText" :tooltip-theme="tooltipTheme" :tooltip-max-width="tooltipMaxWidth" :fixed-shadow="fixedShadow" @on-selection-change="selectData" @on-sort-change="sortChange">
      <template v-for="column in columns" :slot="column.slot ? column.slot : ''" slot-scope="params">
        <slot :name="column.slot ? column.slot : ''" v-bind="params"></slot>
      </template>
    </Table>
    <div class="sp-table-pager" v-if="total || page || pageSize">
      <Page :total="total" :current="currentPage" :page-size="currentPageSize" :page-size-opts="pageSizeOpts" :size="size" :simple="simple" :show-total="showTotal" :show-sizer="showSizer" :show-elevator="showElevator" @on-change="changePage" @on-page-size-change="changePageSize" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'sp-table',
    props: {
      // 显示的结构化数据
      data: {
        type: Array,
        default() {
          return [];
        }
      },
      // 表格列的配置描述
      columns: {
        type: Array,
        default() {
          return [];
        }
      },
      // 是否显示间隔斑马纹
      stripe: {
        type: Boolean,
        default: false
      },
      // 是否显示纵向边框
      border: {
        type: Boolean,
        default: false
      },
      // 是否显示表头
      showHeader: {
        type: Boolean,
        default: true
      },
      // 表格高度，单位 px，设置后，如果表格内容大于此值，会固定表头
      height: {
        type: [Number, String]
      },
      // 表格最大高度，单位 px，设置后，如果表格内容大于此值，会固定表头
      maxHeight: {
        type: [Number, String]
      },
      // 表格是否加载中
      loading: {
        type: Boolean,
        default: false
      },
      // 禁用鼠标悬停时的高亮
      disabledHover: {
        type: Boolean,
        default: false
      },
      // 表格尺寸，可选值为 large、small、default 或者不填
      size: {
        type: String
      },
      // 数据为空时显示的提示内容
      noDataText: {
        type: String,
        default: '暂无数据'
      },
      // 列使用 tooltip 时，配置它的主题，可选值为 dark 或 light
      tooltipTheme: {
        type: String,
        default: 'dark'
      },
      // 列使用 tooltip 时，配置 Tooltip 的最大宽，默认是 300
      tooltipMaxWidth: {
        type: Number,
        default: 300
      },
      // 列固定时，阴影显示规则，可选值为 auto、show、hide
      fixedShadow: {
        type: String,
        default: 'auto'
      },
      // 数据总数
      total: {
        type: Number,
        default: 0
      },
      // 当前页码
      page: {
        type: Number,
        default: 0
      },
      // 每页条数
      pageSize: {
        type: Number,
        default: 0
      },
      // 每页条数切换的配置
      pageSizeOpts: {
        type: Array,
        default() {
          return [10, 20, 50, 100];
        }
      },
      // 简洁版
      simple: {
        type: Boolean,
        default: false
      },
      // 显示总数
      showTotal: {
        type: Boolean,
        default: true
      },
      // 显示分页，用来改变page-size
      showSizer: {
        type: Boolean,
        default: true
      },
      // 显示电梯，可以快速切换到某一页
      showElevator: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        currentPage: this.page || 1,
        currentPageSize: this.pageSize || 10
      };
    },
    methods: {
      /**
       * 选中项发生变化
       * @param  {[type]} selection 已选项数据
       */
      selectData(selection) {
        this.$emit('select', selection);
      },
      /**
       * 点击排序时
       * @param  {[type]} column 当前列数据
       * @param  {[type]} key    排序依据的指标
       * @param  {[type]} order  排序的顺序，值为 asc 或 desc
       */
      sortChange(column, key, order) {
        this.$emit('sortChange', {
          column,
          key,
          order
        });
      },
      /**
       * 改变页码
       * @param  {[type]} val 改变后的页码
       */
      changePage(val) {
        if (val) {
          this.currentPage = val;
          this.$emit('change', {
            page: this.currentPage,
            pageSize: this.currentPageSize
          });
        }
      },
      /**
       * 切换每页条数
       * @param  {[type]} val 切换后的每页条数
       */
      changePageSize(val) {
        if (val) {
          this.currentPageSize = val;
          // 切换每页条数时，如果当前页码不为1，会自动切换页码为1执行changePage，所以此处只在当前页码为1时进行操作
          if (this.currentPage === 1) {
            this.changePage(this.currentPage);
          }
        }
      }
    },
    watch: {
      page(val) {
        this.currentPage = val;
      }
    }
  };
</script>