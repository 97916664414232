/**
 * 数字格式化
 * luxinwen
 * Developed on 2020-02
 * Updated on 2023-02
 */
<template>
  <span><template v-if="showPrefix">{{ prefix }}</template>{{ numeral }}</span>
</template>

<script>
  export default {
    name: 'sp-number',
    props: {
      // 要格式化的数值，可以使用 v-model 双向绑定数据
      value: {
        type: [String, Number],
        default: ''
      },
      // 小数点后面位数
      decimals: {
        type: [String, Number],
        default: 0
      },
      // 前缀符号
      prefix: {
        type: String
      },
      // 千分位符号
      separator: {
        type: String,
        default: ','
      },
      // 是否显示数值变化的过渡效果
      effect: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        current: Number(this.value),
        target: Number(this.value)
      };
    },
    computed: {
      showPrefix() {
        return !!this.prefix;
      },
      numeral() {
        let val = this.current;
        if (val && isFinite(val)) {
          let prec = Math.abs(this.decimals);
          let sep = this.separator;
          let s = [];
          let toFixedFix = function(n, prec) {
            let k = Math.pow(10, prec);
            return Math.ceil(n * k) / k;
          };
          s = String(prec ? toFixedFix(val, prec) : Math.round(val)).split('.');
          if (sep) {
            let re = /(-?\d+)(\d{3})/;
            while (re.test(s[0])) {
              s[0] = s[0].replace(re, '$1' + sep + '$2');
            }
          }
          if (prec === 0) {
            val = s[0];
          } else {
            if (s.length === 1) s[1] = '';
            if (s[1].length < prec) {
              s[1] += new Array(prec - s[1].length).fill('0').join('');
            }
            val = s.join('.');
          }
        }
        return val;
      }
    },
    methods: {
      start() {
        this.current = 0;
        let time = 1000 / 60;
        let step = Math.max(this.target / time, 1);
        let timer = setInterval(() => {
          if (this.current <= this.target - step) {
            this.current = this.current + step;
          } else {
            this.current = this.target;
            clearInterval(timer);
            timer = null;
          }
        }, time);
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          if (this.effect) {
            this.target = Number(newVal);
            this.start();
          } else {
            this.current = Number(newVal);
          }
        }
      }
    }
  };
</script>