import axios from 'axios';
import Clipboard from 'clipboard';
import Grant from '@/util/grant';
import mockData from '../../mock/index.js';

/**
 * 让函数只执行一次
 * @param {*} fn 要执行的函数
 * @param {*} context 函数执行的上下文
 */
function once(fn, context) {
  let newFn = fn;
  return function() {
    let result;
    if (newFn) {
      result = newFn.apply(context || this, arguments);
      newFn = null;
    }
    return result;
  };
}

/**
 * 获取用户数据
 */
function getUserInfo() {
  let val;
  if (window.sessionStorage) {
    val = window.sessionStorage.getItem(process.env.VUE_APP_SESSION);
  }
  if (!val) {
    val = window.localStorage.getItem(process.env.VUE_APP_SESSION);
  }
  if (val) {
    val = JSON.parse(val);
  } else {
    val = {};
  }
  return val;
}

/**
 * 权限检测
 * 当权限编码为*或者mock模式已打开，值为true
 * 当权限编码为单一的字符串，则判断用户的权限列表是否存在该编码，存在为true，否则为false
 * 当权限编码为多个数组格式，则判断用户的权限列表是否包含该编码数组中的至少一个，包含为true，否则为false
 */
function checkGrant(code) {
  let val = false;
  if (code === '*') {
    val = true;
  } else {
    let menus = (getUserInfo().menus || []).map(item => item.menuCode);
    if (typeof (code) === 'string' && code) {
      val = menus.includes(code);
    } else if (Array.isArray(code) && code.length > 0) {
      if (code.indexOf('*') !== -1) {
        val = true;
      } else {
        val = code.some(item => menus.includes(item));
      }
    }
  }
  return val;
}

export default {
  install: function(Vue, opt = {}) {
    let options = Object.assign({}, opt);

    /**
     * 添加全局属性
     */
    if (options.global) {
      Object.keys(options.global).forEach(key => {
        if (options.global.hasOwnProperty(key)) Vue[key] = options.global[key];
      });
    }

    /**
     * 添加实例属性
     */
    if (options.instance) {
      Object.keys(options.instance).forEach(key => {
        if (options.instance.hasOwnProperty(key)) Vue.prototype[key] = options.instance[key];
      });
    }

    /**
     * 用于设置全组件公用的属性。要谨慎设置，以免管理混乱。
     */
    let globalData = {};

    Vue.mixin({
      data() {
        return {
          globalData: globalData,
        };
      },
      methods: {
        /**
         * ajax
         */
        $axios(options = {}) {
          /**
           * 初始化axios实例并设置axios拦截器，并且只设置一次；
           */
          const axiosService = once(function() {
            const vm = this;
            let axiosOptions = {
              // baseURL: process.env.VUE_APP_END_SERVER,  // 设置接口地址前缀域名
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },
              method: options.method || 'post',
              responseType: options.responseType || 'json',
              timeout: options.timeout || 30000, // request timeout  设置请求超时时间
              validateStatus: (status) => {  // 只接受200返回信息为正常响应
                return status === 200;
              },
            };

            /**
             * 如果设置了mock为true，则自定义适配器adapter使用mock数据
             */
            if (process.env.NODE_ENV === 'development' && options.mock) {
              let data = mockData[options.url];
              if (typeof data === 'function') {
                data = data(options.data);
              }
              if (data) {
                axiosOptions.adapter = (config) => {
                  return new Promise((resolve, reject) => {
                    const response = {
                      status: 200,
                      statusText: 'ok',
                      config,
                      data,
                    };
                    resolve(response);
                  });
                };
              } else {
                console.log(`找不到 ${options.url} 对应的mock数据`);
              }
            }
            delete options.mock;

            const axiosService = axios.create(axiosOptions);

            /**
             * 添加请求拦截器
             */
            axiosService.interceptors.request.use(
              (config) => {
                if (process.env.VUE_APP_REQUEST_LOG === 'true') {
                  console.log(`${config.url} 请求配置为：`, config);
                }
                if (config.loading && vm.$showLoading) {
                  vm.$showLoading();
                }
                if (config.beforeSend && typeof config.beforeSend === 'function') {
                  config.beforeSend();
                }
                return config;
              },
              (error) => {
                if (vm.$hideLoading) {
                  vm.$hideLoading();
                }
                return Promise.reject(error);
              }
            );

            /**
             * 添加响应拦截器
             */
            axiosService.interceptors.response.use(
              (response) => {
                if (process.env.VUE_APP_REQUEST_LOG === 'true') {
                  console.log(`${response.config.url} 响应数据为：`, response);
                }
                if (response.config.loading && vm.$hideLoading) {
                  vm.$hideLoading();
                }
                if (response.config.complete && typeof response.config.complete === 'function') {
                  response.config.complete();
                }
                if (response.status === 200) {
                  if (response.hasOwnProperty('status') && response.hasOwnProperty('data')) {
                    if (response.config.responseType === 'blob') {
                      return Promise.resolve(response);
                    } else if (response.data.code === 'S0A00000') {
                      return Promise.resolve(response.data.data);
                    } else {
                      if (response.data.code === '401') {
                        vm.notice(response.data.msg);
                        setTimeout(() => {
                          vm.goToLogin();
                        }, 1500);
                        return;
                      }
                      if (response.config.err !== true) {
                        vm.notice(response.data.msg);
                      }
                      return Promise.reject({
                        code: response.data.code,
                        message: response.data.msg,
                      });
                    }
                  } else {
                    return Promise.reject({
                      code: 'E7001',
                      message: '返回数据格式不正确',
                    });
                  }
                } else {
                  return Promise.reject({
                    code: response.status,
                  });
                }
              },
              (error) => {
                if (error.config.loading && vm.$hideLoading) {
                  vm.$hideLoading();
                }
                if (error.config.complete && typeof error.config.complete === 'function') {
                  error.config.complete();
                }
                let errMsg = {};
                if (error.message === 'Network Error') {  // 网络错误
                  errMsg.code = 'E8001';
                  errMsg.message = '网络错误';
                } else if (error.message.match(/(?<=timeout of )(\d)+(?=000ms exceeded)/g)) { // 网络错误
                  errMsg.code = 'E8002';
                  errMsg.message = '网络超时：' + error.message.match(/(?<=timeout of )(\d)+(?=000ms exceeded)/g)[0];
                } else {
                  console.log('响应错误：', JSON.stringify(error));
                  if (error.response) {
                    if (error.response.data) {
                      errMsg.code = error.response.data.status;
                      errMsg.message = error.response.data.msg;
                    } else {
                      errMsg.code = error.response.status;
                      errMsg.message = error.response.statusText;
                    }
                  } else {
                    errMsg.code = 'E9999';
                    errMsg.message = '未知错误';
                  }
                }
                if (error.config.err !== true) {
                  vm.notice(errMsg.message);
                }
                return Promise.reject(errMsg);
              }
            );

            return axiosService;
          }, this)();

          /**
           * 添加通用自定义请求头
           */
          options.headers = Object.assign({}, options.headers, {
            sessionId: this.getUserInfo().token,
          });

          if (options.data && !options.posttype) {
            options.data = {
              data: options.data || options.data === '' ? options.data : {},
            };
            if (options.data.data && options.data.data.pageNum) {
              options.data.pageNum = options.data.data.pageNum;
              delete options.data.data.pageNum;
            }
            if (options.data.data && options.data.data.pageSize) {
              options.data.pageSize = options.data.data.pageSize;
              delete options.data.data.pageSize;
            }
          }

          if (options.url) {
            if (!options.url.match(/^http/)) {
              options.url = process.env.VUE_APP_END_SERVER + options.url;
            }
            return axiosService.request(options);
          } else {
            return new Promise((resolve, reject) => {
              this.notice('接口路径不能为空');
            });
          }
        },

        /**
         * 提示框
         * @param  {String | Object} options 组件参数，值为字符串时直接为提示内容，值为对象时包含以下字段
         *                   type     {String}    类型，可选值为 warning, success, info, error
         *                   title    {String}    标题
         *                   content  {String}    内容
         */
        alert(options) {
          return new Promise((resolve, reject) => {
            let config = {};
            let type = 'warning';
            if (typeof options === 'string') {
              config.content = options;
            } else {
              config = { ...options };
              if (config.type) {
                type = config.type;
                delete config.type;
              }
            }
            if (!config.title) config.title = '提示';
            config.onOk = () => {
              resolve();
            };
            if (this.$Modal[type]) {
              this.$Modal[type](config);
            } else {
              reject(new Error(type + '对象不存在'));
            }
          });
        },

        /**
         * 通知框
         * @param  {String | Object} options 组件参数，值为字符串时直接为通知内容，值为对象时包含以下字段
         *                   type     {String}    类型，可选值为 warning, success, info, error
         *                   title    {String}    标题
         *                   desc     {String}    内容
         *                   duration {Number}    自动关闭的延时，单位秒，默认 4
         */
        notice(options) {
          let config = {
            duration: 4,
          };
          let type = 'warning';
          if (typeof options === 'string') {
            config.desc = options;
          } else {
            config = Object.assign(config, options);
            if (config.type) {
              type = config.type;
              delete config.type;
            }
          }
          if (!config.title) config.title = '提示';
          if (this.$Notice[type]) this.$Notice[type](config);
        },

        /**
         * 确认框
         * @param  {String | Object} options 组件参数，值为字符串时直接为提示内容，值为对象时包含以下字段
         *                   title      {String}    标题
         *                   content    {String}    内容
         *                   okText     {String}    确定按钮的文字
         *                   cancelText {String}    取消按钮的文字
         *                   onOk       {Function}  确定按钮事件
         *                   onCancel   {Function}  取消按钮事件
         * confirm名称被iView组件占用
         */
        $confirm(options) {
          return new Promise((resolve, reject) => {
            let config = {};
            if (typeof options === 'string') {
              config.content = options;
            } else {
              config = { ...options };
            }
            if (!config.title) config.title = '请确认';
            config.onOk = () => {
              resolve();
            };
            // 如果添加取消按钮事件，但在调用confirm方法时只写then不写catch，就会报 Uncaught (in promise) 错误
            if (config.onCancel) {
              config.onCancel = () => {
                reject();
              };
            }
            this.$Modal.confirm(config);
          });
        },

        /**
         * 显示加载框
         * @param  {String} text 加载中的提示文案
         * 2021-02-05 update：因与iview tree组件自带变量重名导致使用tree组件时报错，故重命名为$showLoading
         */
        $showLoading(text = '加载中…') {
          this.$Spin.show({
            render: (h) => {
              return h('div', [
                h('Icon', {
                  class: 'spin-icon-load',
                  props: {
                    type: 'ios-loading',
                    size: 32,
                  },
                }),
                h('div', text),
              ]);
            }
          });
        },

        /**
         * 隐藏加载框
         */
        $hideLoading() {
          this.$Spin.hide();
        },

        /**
         * 跳转到登录页
         */
        goToLogin() {
          this.$router.push({
            name: 'login'
          });
        },

        /**
         * 设置用户数据
         */
        setUserInfo(json) {
          let val = json ? JSON.stringify(json) : '';
          if (window.sessionStorage) {
            window.sessionStorage.setItem(process.env.VUE_APP_SESSION, val);
          }
          if (window.localStorage) {
            window.localStorage.setItem(process.env.VUE_APP_SESSION, val);
          }
        },

        /**
         * 清除用户数据
         */
        clearUserInfo() {
          this.setUserInfo('');
        },

        /**
         * 获取用户数据
         */
        getUserInfo: getUserInfo,

        /**
         * 权限检测
         */
        checkGrant: checkGrant,

        /**
        * 报表导出
        */
        exportDataToExcel(url, data, filename) {
          this.$axios({
            url,
            data,
            responseType: 'blob',
            loading: true
          }).then(res => {
            if (res.data.type === 'application/json') {
              const reader = new FileReader();
              reader.readAsText(res.data, 'utf-8');
              reader.onload = function() {
                const { msg } = JSON.parse(reader.result); // 此处的msg就是后端返回的msg内容
                if (msg.indexOf('登录') !== -1) {
                  this.goToLogin();
                } else {
                  this.notice(msg);
                }
              };
            } else {
              let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
              let downloadElement = document.createElement('a');
              let href = window.URL.createObjectURL(blob);
              downloadElement.href = href;
              downloadElement.download = filename;
              document.body.appendChild(downloadElement);
              downloadElement.click();
              document.body.removeChild(downloadElement);
              window.URL.revokeObjectURL(href);
            }
          }).catch(err => {
            this.notice('导出失败！' + err);
          });
        },

        /**
         * 格式化时间戳
         * @param  {[type]} time   时间戳或new Date()
         * @param  {String} format 输出格式，默认为 yyyy-MM-dd HH:mm:ss，可按需求定制，如 MM-dd HH:mm
         */
        formatDate(time, format = 'yyyy-MM-dd HH:mm:ss') {
          if (!time) return '';
          let t = new Date(time);
          let tf = function(i) {
            return (i < 10 ? '0' : '') + i;
          };
          return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(item) {
            switch (item) {
              case 'yyyy':
                return tf(t.getFullYear());
              case 'MM':
                return tf(t.getMonth() + 1);
              case 'dd':
                return tf(t.getDate());
              case 'HH':
                return tf(t.getHours());
              case 'mm':
                return tf(t.getMinutes());
              case 'ss':
                return tf(t.getSeconds());
            }
          });
        },

        /**
         * 深拷贝对象
         */
        deepCopy(obj) {
          return JSON.parse(JSON.stringify(obj));
        },

        /**
         * 判断对象是否相等
         */
        deepEqual(a, b) {
          return JSON.stringify(a) === JSON.stringify(b);
        },

        /**
         * 复制到粘贴板
         */
        copyText(text) {
          let clipboard = new Clipboard('.copy-btn', {
            text: () => {
              return text;
            }
          });
          clipboard.on('success', () => {
            this.notice({
              type: 'success',
              desc: '复制成功'
            });
            clipboard.destroy();
          });
          clipboard.on('error', () => {
            this.notice('该浏览器不支持自动复制，请手动复制');
            clipboard.destroy();
          });
        },
      },
    });

    /**
     * 全局权限检测指令
     */
    Vue.directive('grant', {
      inserted(el, binding, vnode) {
        let code = binding.value;
        if (typeof code === 'string') {
          code = Grant[code];
        } else if (Array.isArray(code)) {
          code = code.map(item => {
            return Grant[item];
          });
        }
        if (!checkGrant(code)) {
          el.parentNode.removeChild(el);
        }
      }
    });
  },
};
