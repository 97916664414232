import spChart from './sp-chart';
import spCity from './sp-city';
import spEditor from './sp-editor';
import spFooter from './sp-footer';
import spLinkto from './sp-linkto';
import spMap from './sp-map';
import spMapT from './sp-map-t';
import spNumber from './sp-number';
import spRegion from './sp-region';
// import spResult from './sp-result';
import spRow from './sp-row';
import spTable from './sp-table';
import spUpload from './sp-upload';

const components = {
  spChart,
  spCity,
  spEditor,
  spFooter,
  spLinkto,
  spMap,
  spMapT,
  spNumber,
  spRegion,
  // spResult,
  spRow,
  spTable,
  spUpload,
};

// if (process.env.VUE_APP_LOAD_CHART === 'true') {
//   const spChart = () => import('./sp-chart');
//   components['spChart'] = spChart;
// }
// if (process.env.VUE_APP_LOAD_CITY === 'true') {
//   const spCity = () => import('./sp-city');
//   components['spCity'] = spCity;
// }

const install = function(Vue, opts = {}) {
  if (install.installed) return;
  Object.keys(components).forEach(key => {
    Vue.component(key, components[key]);
  });
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const API = {
  install,
  ...components,
};

export default API;