export default {
  common: {
    uploadImage: '/integration/common/uploadImage',
    uploadVideo: '/inner/integration/file/uploadVideo',
  },
  project: {
    queryBuildingList: '/osp/building/tBuildingInfo/getTBuildingInfoList',
    queryBuildingDetail: '/osp/building/tBuildingInfo/getTBuildingInfoDetail',
    addBuilding: '/osp/building/tBuildingInfo/addTBuildingInfo',
    updateBuilding: '/osp/building/tBuildingInfo/editTBuildingInfo',
    queryBuildingCategory: '/osp/building/tBuildingInfo/getBuildingCategoryEnum',
    queryBuildingSaleType: '/osp/building/tBuildingInfo/getBuildingSaleTypeEnum',
    queryBuildingStatus: '/osp/building/tBuildingInfo/getBuildingStatusEnum',
    changeBuildingStatus: '/osp/building/tBuildingInfo/updateBuildingInfoStatus',
    deleteBuilding: '/osp/building/tBuildingInfo/deleteBuildingInfo',
    queryPhotoList: '/osp/building/tBuildingInfo/getTBuildingPhotoAlbumList',
    queryCollectList: '/osp/member/tMemberMyCollect/getTMemberMyCollectPage',
    addPhoto: '/osp/building/tBuildingInfo/addTBuildingPhotoAlbum',
    queryHouseList: '/osp/building/tBuildingHouseInfo/getTBuildingHouseInfoList',
    queryHouseDetail: '/osp/building/tBuildingHouseInfo/getTBuildingHouseInfoDetail',
    addHouse: '/osp/building/tBuildingHouseInfo/addTBuildingHouseInfo',
    updateHouse: '/osp/building/tBuildingHouseInfo/editTBuildingHouseInfo',
    changeHouseSaleStatus: '/osp/building/tBuildingHouseInfo/updateHouseInfoSaleStatus',
    changeHouseStatus: '/osp/building/tBuildingHouseInfo/updateHouseInfoStatus',
    deleteHouse: '/osp/building/tBuildingHouseInfo/deleteTBuildingHouseInfo',
    getBuildingQrCode: '/osp/building/tBuildingInfo/getBuildingQrCode',
  },
  region: {
    queryProvinceRegionList: '/common/queryProvinceRegionList',
    queryCityRegionList: '/common/queryCityRegionList',
    queryCityRegionAllList: '/common/queryCityRegionAllList',
    getRegionList: '/common/getRegionList',
    addRegion: '/common/addRegion',
    deleteRegion: '/osp/building/common/deleteRegion',
  },
  activity: {
    queryActivityList: '/osp/activity/list',
    addActivity: '/osp/activity/add',
    updateActivity: '/osp/activity/update',
    deleteActivity: '/osp/activity/delete',
    queryBuildingList: '/osp/activityBuildingRel/list',
    queryUnselectedList: '/osp/activityBuildingRel/queryUnselectedBuilding',
    addBuilding: '/osp/activityBuildingRel/insertBatch',
    deleteBuilding: '/osp/activityBuildingRel/delete',
    queryCustomerList: '/osp/customer/queryByActivityId',
    queryCustomerDetail: '/osp/customer/info',
  },
  advert: {
    queryAdvertList: '/osp/advert/list',
    queryAdvertInfo: '/osp/advert/info',
    addAdvert: '/osp/advert/add',
    updateAdvert: '/osp/advert/update',
    deleteAdvert: '/osp/advert/delete',
  },
  marketing: {
    queryEventList: '/promotion/osp/pmtEvent/list',
    queryEventType: '/promotion/osp/pmtEvent/getPmtEventTypeEnum',
    queryEventStatus: '/promotion/osp/pmtEvent/getPmtEventStatusEnum',
    queryEventFrequency: '/promotion/osp/pmtEvent/getPmtEventFrequencyEnum',
    queryCommonLogList: '/inner/integration/commonLog/queryCommonLogList',
    queryLogList: '/promotion/osp/pmtEvent/doList',
    queryEventDetail: '/promotion/osp/pmtEvent/detail',
    queryEventMemberList: '/promotion/osp/pmtEvent/detail/memberList',
    addEvent: '/promotion/osp/pmtEvent/addOrUpdate',
    changeEventStatus: '/promotion/osp/pmtEvent/changeStatus',
  },
  news: {
    queryNewsList: '/osp/cms/news/list',
    queryNewsDetail: '/osp/cms/news/info',
    addNews: '/osp/cms/news/save',
    updateNews: '/osp/cms/news/update',
    changeNewsStatus: '/osp/cms/news/status/update',
    deleteNews: '/osp/cms/news/delete',
  },
  analysis: {
    queryBuildingInfoStatictics: '/osp/building/tBuildingInfo/getTBuildingInfoStaticticsVO',
    queryBuildingInfoStaticticsUser: '/osp/building/tBuildingInfo/getTBuildingInfoStaticticsUserVO',
    queryShareStatictics: '/osp/building/tBuildingInfo/getTShareStaticticsVO',
    queryShareStaticticsUser: '/osp/building/tBuildingInfo/getTShareStaticticsUserVO',
    exportBuildingInfoStaticticsUser: '/osp/building/tBuildingInfo/exportTBuildingInfoStaticticsUserVO',
    exportShareStatictics: '/osp/building/tBuildingInfo/exportTShareStaticticsVO',
    exportShareStaticticsUser: '/osp/building/tBuildingInfo/exportTShareStaticticsUserVO',
    exportBroker: '/osp/member/count/exportCountGroupByMember',
    exportRecordList: '/osp/exportLog/queryByPage',
    queryBroker: '/osp/member/count/countGroupByMember',
    exportTBuildingInfoStaticticsVO: '/osp/building/tBuildingInfo/exportTBuildingInfoStaticticsVO'
  },
  customer: {
    queryCustomerList: '/osp/customer/list',
    changeCustomerStatus: '/osp/customer/updateCustomerManagement',
  },
  message: {
    getModelList: '/message/osp/model/getModelList',
    getApprovedModelList: '/message/osp/model/getApprovedModelList',
    getModel: '/message/osp/model/getModel',
    updateStatus: '/message/osp/model/updateStatus',
    queryLogList: '/message/osp/model/queryLogList',
    saveModel: '/message/osp/model/saveModel',
    eventDetailMemberList: '/osp/event/detail/memberList',
    queryBusinessTypeList: '/message/dict/query',
    queryChannelList: '/message/dict/queryChannelList',
    getMessageList: '/message/osp/info/getMessageList',
    getMessageConfigList: '/message/osp/message/config/getMessageConfigList',
    getMessageConfig: '/message/osp/message/config/getMessageConfig',
    queryMessageConfigLogList: '/message/osp/message/config/queryMessageConfigLogList',
    saveMessageConfig: '/message/osp/message/config/saveMessageConfig',
    updateMessageConfigStatus: '/message/osp/message/config/updateMessageConfigStatus',
  },
  member: {
    queryMemberList: '/member-info/osp/pageQueryOspMemberInfo',
    queryMemberListPage: '/member-info/osp/queryMemberListPage',
    queryMemberDetail: '/member-info/osp/queryOspMemberInfo',
    changeMemberStatus: '/member-info/osp/batchChangeStatus',
    queryMemberBankCard: '/osp/customer/queryBankCardByMember',
    queryCommissionList: '/osp/customer/queryCommissionListByMember',
    queryCommissionDetail: '/osp/customer/getCommissionInfo',
    updateCommission: '/osp/customer/updateCommission',
    queryCustomerList: '/osp/customer/list',
    queryCustomerDetail: '/osp/customer/info',
    queryLevelList: '/member/osp/level/getTDicMemberLevelList',
    queryMemberVisitDetail: '/member/osp/count/queryMemberVisitDetail',
    queryMemberIncreaseDetail: '/member/osp/count/queryMemberIncreaseDetail',
    exportMemberListPage: '/member-info/osp/exportMemberListPage'
  },
  system: {
    login: '/osp/login/login',
    loginCaptcha: '/osp/login/loginCaptcha',
    logoutAuth: '/osp/login/logoutAuth',
    updatePasscode: '/osp/sysUser/updatePasscode',
    queryUsers: '/osp/sysUser/queryUsers',
    queryUserDetail: '/osp/sysUser/queryUserById',
    addUser: '/osp/sysUser/add',
    updateUser: '/osp/sysUser/update',
    disableAccount: '/osp/sysUser/disableAccount',
    deleteUser: '/osp/sysUser/delete',
    exportUser: '/osp/sysUser/exportUsers',
    importUser: '/osp/sysUser/importUsers',
    bindRole: '/osp/sysAuth/bindingUserRole',
    resetPasscode: '/osp/sysUser/ospResetPasscode',
    queryMenus: '/osp/sysMenu/querySysMenus',
    addMenu: '/osp/sysMenu/add',
    updateMenu: '/osp/sysMenu/update',
    deleteMenu: '/osp/sysMenu/delete',
    queryRoles: '/osp/sysRole/queryRoles',
    addRole: '/osp/sysRole/add',
    updateRole: '/osp/sysRole/update',
    deleteRole: '/osp/sysRole/delete',
    roleMenu: '/osp/sysAuth/roleId2MenuId',
    bindMenu: '/osp/sysAuth/bindingRoleMenu',
    queryOrgList: '/osp/org/list',
    addOrg: '/osp/org/add',
    updateOrg: '/osp/org/update',
    deleteOrg: '/osp/org/delete',
  },
  test: {
    queryTestList: '/test/queryList',
    queryTestDetail: '/test/queryDetail',
    deleteTest: '/test/delete',
    addTest: '/test/add',
    updateTest: '/test/update',
    uploadImage: '/test/upload',
  },
};
