/**
 * 设置页面标题
 */
export function setTitle(text) {
  let appName = process.env.VUE_APP_NAME;
  let title = (text && appName) ? [text, appName].join(' - ') : (text || appName);
  document.title = title;
  let isIos = navigator.userAgent.match(/iphone|ipad/i);
  let isWx = navigator.userAgent.match(/MicroMessenger/);
  if (isIos && isWx) {
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = '//activity.huaruntong.cn/web/activity/logo/hrt.png';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.onload = null;
        iframe.remove();
      }, 0);
    };
    document.body.appendChild(iframe);
  }
}

/**
 * 获取UUID
 */
export function getUUID() {
  let s = [];
  let hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}

/**
 * 加载指定的js
 */
export function loadScript(src) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.onload = () => {
      resolve();
    };
    script.onerror = err => {
      reject(err);
    };
    script.src = src;
    (document.head || document.body).appendChild(script);
  });
}

/**
 * 获取URL参数
 * @param {string} key
 * @param {string} str 如果指定的话从该字符串获取
 */
export function getUrlParam(key, str) {
  let r = new RegExp('\\?(?:.+&)?' + key + '=(.*?)(?:&.*)?$');
  let m = (str || window.location.toString()).match(r);
  return m ? m[1] : ''; // 如果需要处理中文，可以用返回decodeURLComponent(m[1])
}

/**
 * 数字加密，用于手机号脱敏等
 * @param  {String} value  手机号码
 * @param  {Number} prefix 前面正常显示位数，默认值 3
 * @param  {Number} suffix 后面正常显示位数，默认值 4
 * @return {String}        如: 135****1020
 */
export function cryNumber(value, prefix = 3, suffix = 4) {
  if (!value) return '';
  if (value.length <= prefix + suffix) return value;
  let r = new RegExp('(\\d{' + prefix + '})\\d*(\\d{' + suffix + '})');
  return value.replace(r, '$1****$2');
}
export function getArrayLabel(arr = [], k, value = 'value', label = 'label') {
  // eslint-disable-next-line eqeqeq
  let item = arr.find((item) => item[value] == k) || {};
  return item[label] || '-';
}