function loadDatas() {
  const datas = require.context('./data', true, /.*\.js$/i);

  let allData = {};
  datas.keys().forEach((key) => {
    let mockData = datas(key).default;
    allData = Object.assign(allData, mockData);
  });

  return allData;
}

let mockDatas = loadDatas();

export default mockDatas;