export default {
  '/osp/login/loginCaptcha': {
    'code': 'S0A00000',
    'msg': '操作成功',
    'data': {
      'img': 'data:image/png;BASE64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAoCAIAAACHGsgUAAAFPUlEQVR42u2Z228UVRzH++CL/4TxwfhmjFFjUQnRGGJiYqI2aAQTpPqg8tA+SGnUIoIGe1HAVrkIVrCA0OUisO2Clb24llr3Ukppu9vCtrZbtrszuzN76+zMTv2tZz2dzM5MZ2YHu5D55fvQ3XPJ9HN+53e+Z7Zq0QzVUWUiMGGZsExY9yysrvs6sSrnfxiPMBUES8gI68yDJ1eW0XZr9HNb7ISHbroYBf00SDEcv5KwJDEh+T/2KI+NHH3vDj30t674jt4YYiTUD1cT/zcsBUDQyqbZ4/cfQR/pALUsLyxDHjea5KwjKREjLr94PbwABNHHgVBWzVQzN7lDu1JlwZJjhGPicAB9aXvm4rKzC2EZgswRTH9qLTICQIAJB5vnD7gLvFr7CF7Fdjx1IPPao7H6msTrTxA1j8UMyCzJMbbVVtQaPDiuFVY51OzBNM6mDmdcss8UmUMdJqM5halO7su88khMqDerieZ62mDrQE/QiBRsRjbJqh9YPrgIzSEQkEGQR5J9IKdQH9uo0habCnIiWEiDdsZgnzXU5EW83G85tY6VhBW1NKoZe+5aEmdWiJBNHMzruEe2pHZsS2JAda/G3TZGiGznB3Q2zRsDC0wDgjX3a1h39RHCSvnOqRy1x04iEEBErjDhndjSRyhM9f5LceDSsD6RWyhMxP4LH/NqqqWMgQWMDLFaPMtEujYjXhwVUemtcHIBFMk+uNKDwGHITQVFHXEZ8SzNA8ggy6Dqo6aeE9lyYf3xtkul1VKOTNCNSJE9zZq8KOaFihc+FrGHwCcmdFaYCvHi8+LvIdcg46DpnRdIYaseWFDX1Vst5SB7WxCvTMClckjbb0S3j8a8sL0S6dJY0Y591iObXO+uJVEGlfKCGPWyqHWoP6cfFjgG9VZLITh6vli2ujbz7IL6gagqWfy0JCbQKR8dSxWPTrgSKUyFcAAXiSqRL7b+2JYu662DequlHCn/L4gX5TqsdSyYh7NDhfNxmmQBHGQcbDrIo0P9iWuzBfQ35hjE6ztXXG6SrxqKx6J0Vf2P1646Wicsaiyhz2pJXF8sjQgWEx41/Cp3frhoNU77Zd0m3mv4WJRsrX2e1J9Zvsa/dFstYQAjTVZLa+p9eZlAvJR77m4s5BccgnKVHgS3Ij2w+Dx/+gEDrFYhSd2dWq2W+nBOZBCpL2wxtDFlqydX3G7gG5Dhwh4C7o+oCY5OnZkVvjRrkNVa0Gq1NL0UxB6Cyy/Tub0pKXkBAh35Oo3+0AnL9YbdGKsVcOmwWqIAt/X7ZAaOSEQEnD1choR3o3YHCZdwNVOVkmr7MDkb4sqCxZDMClqt0pDzEEitfYTcxVsUY34W6hf4L9h06x4nPtpIOa2F/dt/mdEPa7xj1BirRc3ps1rqYXUOJFJMnsrmy3nO/TtS+mFB9K66YIjVSnrP6LZawphPclcC6aN/UuBCoVSB5/rGQYIRU3g/oWFRucWNa8iV/imM56PdW++c1TIqug8WXhZWPfSwF2nV08Nrnrsu1NoXb6zfEBBqU+3Elq0hkVpaZ/fsDQu1b/9ti4UQyeGkrg4khfL5UjMzzC3vyFD7FtDw9zvno7nKhDVoZ8BDLMGqWKlZRVBd/S3RKn6ybVrrKm56+Xbb9tjZn6mpEAML+fc0c6U33dwQL56GlQ+rcmTC0gJr6TSJ5iDxhJqczIqqDGRsaRpDbouyHapYaWmDeifaODXrxtG2Wl3tfvZJB6j6Kc/dAatiQ80qgs5fIEWreOxYtMxVRIKiedfAqpwwYZmwTFgmLBPWPRr/AATBcPab4v1SAAAAAElFTkSuQmCC',
      'captchaUuid': '633f1af0e56f48638be57b593dfbdc16'
    }
  },
  '/osp/login/login': {
    'code': 'S0A00000',
    'msg': '操作成功',
    'data': {
      'loginUserName': 'superUser',
      'password': null,
      'sessionId': 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJzdXBlclVzZXIiLCJleHAiOjE2Nzg3MDA0MTJ9.oSDSHE8LP-JmSi4ToeUdQoZynnT8NpfAP7Iab1CJnngFl9ouTf4LFV9v2vbd8HX-bQYjxAMyAJFlG2V1PgOKfONSNrTJUFUGlgqa03dzDjIS2N5LC3QBmdM77ahqY9Bi4YfSRMiWFeRRS_9-KrkKEPvjH5es4JqUnEvwh1Lv9sw',
      'userName': '超级管理员',
      'userMobile': '13800000099',
      'merchantCode': 'OSP',
      'storeCode': null,
      'menus': [
        {
          'menuId': 900,
          'parentMenuId': 0,
          'menuCode': 'OSP_MENU_SYS',
          'menuName': '系统管理',
          'parentMenuName': '',
          'menuUrl': '/osp/sysUser',
          'menuOrder': 900,
          'isButtonControl': 1
        },
        {
          'menuId': 901,
          'parentMenuId': 900,
          'menuCode': 'OSP_MENU_SYS_ROLE',
          'menuName': '角色管理',
          'parentMenuName': '系统管理',
          'menuUrl': '/osp/sysRole/queryRoles,/osp/sysMenu/querySysMenus,/osp/sysRole/update,/osp/sysRole/add,/osp/sysRole/delete,/osp/sysAuth/roleId2MenuId,/osp/sysAuth/bindingRoleMenu',
          'menuOrder': 901,
          'isButtonControl': 1
        },
        {
          'menuId': 902,
          'parentMenuId': 900,
          'menuCode': 'OSP_MENU_SYS_USER',
          'menuName': '用户管理',
          'parentMenuName': '系统管理',
          'menuUrl': '/osp/sysUser/add,/osp/sysUser/update,/osp/sysUser/queryUsers,/osp/sysRole/queryRoles,/osp/merchant/queryAll,/osp/store/queryStoreList,/osp/sysAuth/bindingUserRole,/osp/sysUser/ospResetPasscode',
          'menuOrder': 902,
          'isButtonControl': 1
        },
        {
          'menuId': 903,
          'parentMenuId': 900,
          'menuCode': 'OSP_MENU_SYS_MENU',
          'menuName': '菜单管理',
          'parentMenuName': '系统管理',
          'menuUrl': '/osp/sysMenu/querySysMenus,/osp/sysMenu/add,/osp/sysMenu/delete,/osp/sysMenu/update',
          'menuOrder': 903,
          'isButtonControl': 1
        }
      ]
    },
    'success': true
  }
};