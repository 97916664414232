import Grant from '@/util/grant';
import testMenu from './test.js';
const Main = () => import('../views/main/main.vue');
const routers = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      code: '*'
    },
    component: (resolve) => require(['../views/login/login.vue'], resolve)
  },
  {
    self: true,
    path: '',
    component: Main,
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
          code: '*',
          icon: 'md-desktop',
          show: true
        },
        component: () => import('../views/index/welcome.vue')
      },
      {
        path: '/changePwd',
        name: 'changePwd',
        meta: {
          title: '修改密码',
          code: '*'
        },
        component: () => import('../views/admin/change-pwd.vue')
      }
    ]
  },
  // 项目管理
  {
    path: '',
    name: 'project',
    meta: {
      title: '项目',
      subTitle: '项目管理',
      code: Grant.OSP_PROJECT,
      icon: 'md-cube',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/project/scope',
        name: 'projectScope',
        meta: {
          title: '项目范围',
          code: Grant.OSP_PROJECT_SCOPE,
          show: true
        },
        component: () => import('../views/project/scope/list.vue')
      },
      {
        path: '/project/mgm',
        name: 'projectMgm',
        meta: {
          title: '项目信息',
          code: Grant.OSP_PROJECT_MGM,
          show: true
        },
        component: () => import('../views/project/mgm/list.vue')
      },
      {
        path: '/project/mgm/type',
        name: 'projectMgmType',
        meta: {
          title: '户型维护',
          code: Grant.OSP_PROJECT_HOUSE,
          parent: 'projectMgm'
        },
        component: () => import('../views/project/house/list.vue')
      },
      {
        path: '/project/mgm/collect',
        name: 'projectMgmCollect',
        meta: {
          title: '收藏数',
          code: '*',
          parent: 'projectMgm'
        },
        component: () => import('../views/project/collect/list.vue')
      }
    ]
  },
  // 广告配置
  {
    path: '',
    name: 'advert',
    meta: {
      title: '广告',
      subTitle: '广告配置',
      code: Grant.OSP_ADVERT,
      icon: 'ios-color-palette',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/advert/mgm',
        name: 'advertMgm',
        meta: {
          title: '广告列表',
          code: Grant.OSP_ADVERT_MGM,
          show: true
        },
        component: () => import('../views/advert/mgm/list.vue')
      },
      {
        path: '/advert/mgm/info',
        name: 'advertMgmInfo',
        meta: {
          title: '广告配置（城市）',
          code: Grant.OSP_ADVERT_MGM_QUERY,
          parent: 'advertMgm'
        },
        component: () => import('../views/advert/mgm/info-list.vue')
      }
    ]
  },
  // 活动管理
  {
    path: '',
    name: 'activity',
    meta: {
      title: '活动',
      subTitle: '活动管理',
      code: Grant.OSP_ACTIVITY,
      icon: 'ios-aperture',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/activity/mgm',
        name: 'activityMgm',
        meta: {
          title: '活动信息',
          code: Grant.OSP_ACTIVITY_MGM,
          show: true
        },
        component: () => import('../views/activity/mgm/list.vue')
      },
      {
        path: '/activity/mgm/item',
        name: 'activityMgmItem',
        meta: {
          title: '关联项目',
          code: Grant.OSP_ACTIVITY_ITEM,
          parent: 'activityMgm'
        },
        component: () => import('../views/activity/item/list.vue')
      },
      {
        path: '/activity/mgm/info',
        name: 'activityMgmInfo',
        meta: {
          title: '参与信息',
          code: Grant.OSP_ACTIVITY_INFO,
          parent: 'activityMgm'
        },
        component: () => import('../views/activity/info/list.vue')
      }
    ]
  },
  // 经纪人管理
  {
    path: '',
    name: 'broker',
    meta: {
      title: '会员',
      subTitle: '会员管理',
      code: Grant.OSP_BROKER,
      icon: 'ios-contact',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/broker/mgm',
        name: 'brokerMgm',
        meta: {
          title: '经纪人信息',
          code: Grant.OSP_BROKER_MGM,
          show: true
        },
        component: () => import('../views/broker/mgm/list.vue')
      },
      {
        path: '/broker/mgm/customer',
        name: 'brokerMgmCustomer',
        meta: {
          title: '客户信息',
          code: Grant.OSP_BROKER_CUSTOMER,
          parent: 'brokerMgm'
        },
        component: () => import('../views/broker/customer/list.vue')
      },
      {
        path: '/broker/mgm/commission',
        name: 'brokerMgmCommission',
        meta: {
          title: '佣金管理',
          code: Grant.OSP_BROKER_COMMISSION,
          parent: 'brokerMgm'
        },
        component: () => import('../views/broker/commission/list.vue')
      },
      {
        path: '/broker/statistics',
        name: 'brokerStatistics',
        meta: {
          title: '会员信息',
          code: Grant.OSP_BROKER_STATISTICS,
          show: true
        },
        component: () => import('../views/broker/statistics/list.vue')
      },
      {
        path: '/analysis/statistics',
        name: 'analysisStatistics',
        meta: {
          title: '会员统计',
          code: Grant.OSP_MEMBER_STATISTICS,
          show: true,
        },
        component: () => import('../views/data-analysis/statistics/list.vue'),
      },
    ]
  },
  // 客户管理
  {
    path: '',
    name: 'customer',
    meta: {
      title: '客户',
      subTitle: '客户管理',
      code: Grant.OSP_CUSTOMER,
      icon: 'ios-contacts',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/customer/mgm',
        name: 'customerMgm',
        meta: {
          title: '客户信息',
          code: Grant.OSP_CUSTOMER_MGM,
          show: true
        },
        component: () => import('../views/customer/mgm/list.vue')
      }
    ]
  },
  // 佣金管理
  {
    path: '',
    name: 'commission',
    meta: {
      title: '佣金',
      subTitle: '佣金管理',
      code: Grant.OSP_COMMISSION,
      icon: 'logo-usd',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/commission/mgm',
        name: 'commissionMgm',
        meta: {
          title: '佣金信息',
          code: Grant.OSP_COMMISSION_MGM,
          show: true
        },
        component: () => import('../views/commission/mgm/list.vue')
      }
    ]
  },
  // 品牌馆
  {
    path: '',
    name: 'brand',
    meta: {
      title: '品牌',
      subTitle: '品牌馆',
      code: Grant.OSP_BRAND,
      icon: 'ios-star',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/brand/advert',
        name: 'brandAdvert',
        meta: {
          title: '广告管理',
          code: Grant.OSP_BRAND_ADVERT,
          show: true
        },
        component: () => import('../views/brand/advert/list.vue')
      },
      {
        path: '/brand/news',
        name: 'brandNews',
        meta: {
          title: '新闻资讯',
          code: Grant.OSP_BRAND_NEWS,
          show: true
        },
        component: () => import('../views/brand/news/list.vue')
      },
      {
        path: '/brand/story',
        name: 'brandStory',
        meta: {
          title: '品牌故事',
          code: Grant.OSP_BRAND_STORY,
          show: true
        },
        component: () => import('../views/brand/story/list.vue')
      }
    ]
  },
  // 积分营销管理
  {
    path: '',
    name: 'marketing',
    meta: {
      title: '营销',
      subTitle: '积分营销管理',
      code: Grant.OSP_MARKETING,
      icon: 'logo-google',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/marketing/event',
        name: 'marketingEvent',
        meta: {
          title: '营销事件管理',
          code: Grant.OSP_MARKETING_EVENT,
          show: true
        },
        component: () => import('../views/marketing/event/list.vue')
      },
      {
        path: '/marketing/log',
        name: 'marketingLog',
        meta: {
          title: '事件日志管理',
          code: Grant.OSP_MARKETING_LOG,
          show: true
        },
        component: () => import('../views/marketing/log/list.vue')
      }
    ]
  },
  // 消息中心管理
  {
    path: '',
    meta: {
      title: '消息中心',
      code: 'OSP_MENU_MESSAGE',
      icon: 'md-notifications',
      show: true,
    },
    component: Main,
    children: [
      {
        path: '/messageTemplate',
        name: 'messageTemplate',
        meta: {
          title: '消息模板',
          code: 'OSP_MENU_MESSAGE_TEMPLATE',
          show: true,
        },
        component: () => import('../views/message/template.vue'),
      },
      {
        path: '/messageTemplateAdd',
        name: 'messageTemplateAdd',
        meta: {
          title: '消息模板-新增',
          code: 'OSP_MENU_MESSAGE_TEMPLATE',
          parent: 'messageTemplate',
        },
        component: () => import('../views/message/add.vue'),
      },
      {
        path: '/messageTemplateEdit',
        name: 'messageTemplateEdit',
        meta: {
          title: '消息模板-编辑',
          code: 'OSP_MENU_MESSAGE_TEMPLATE',
          parent: 'messageTemplate',
        },
        component: () => import('../views/message/add.vue'),
      },
      {
        path: '/messageTemplateDetail',
        name: 'messageTemplateDetail',
        meta: {
          title: '消息模板-查看',
          code: 'OSP_MENU_MESSAGE_TEMPLATE',
          parent: 'messageTemplate',
        },
        component: () => import('../views/message/add.vue'),
      },
      {
        path: '/message/manage',
        name: 'messageCentermanage',
        meta: {
          title: '消息管理',
          code: 'OSP_MENU_MESSAGE_MANAGE',
          show: true,
        },
        component: () => import('../views/message/manage.vue'),
      },
      {
        path: '/messageAdd',
        name: 'messageCentermanageAdd',
        meta: {
          title: '消息管理-新增',
          code: 'OSP_MENU_MESSAGE_MANAGE',
          parent: 'messageCentermanage',
        },
        component: () => import('../views/message/manageAdd.vue'),
      },
      {
        path: '/messageEdit',
        name: 'messageCentermanageEdit',
        meta: {
          title: '消息管理-编辑',
          code: 'OSP_MENU_MESSAGE_MANAGE',
          parent: 'messageCentermanage',
        },
        component: () => import('../views/message/manageAdd.vue'),
      },
      {
        path: '/messageDetail',
        name: 'messageCentermanageDetail',
        meta: {
          title: '消息管理-查看',
          code: 'OSP_MENU_MESSAGE_MANAGE',
          parent: 'messageCentermanage',
        },
        component: () => import('../views/message/manageAdd.vue'),
      },
      {
        path: '/message/Log',
        name: 'messageCenterLog',
        meta: {
          title: '消息明细',
          code: 'OSP_MENU_MESSAGE_DETAIL',
          show: true,
        },
        component: () => import('../views/message/log.vue'),
      },
    ],
  },
  // 数据分析
  {
    path: '',
    name: 'analysis',
    meta: {
      title: '数据',
      subTitle: '数据分析',
      code: Grant.OSP_ANALYSIS,
      icon: 'ios-analytics',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/analysis/project',
        name: 'analysisProject',
        meta: {
          title: '楼盘信息统计',
          code: Grant.OSP_ANALYSIS_PROJECT,
          show: true
        },
        component: () => import('../views/data-analysis/project/list.vue')
      },
      {
        path: '/analysis/project/detail',
        name: 'analysisProjectDetail',
        meta: {
          title: '楼盘信息统计详情',
          code: Grant.OSP_ANALYSIS_PROJECT_VIEW,
          parent: 'analysisProject'
        },
        component: () => import('../views/data-analysis/project/detail-list.vue')
      },
      {
        path: '/analysis/broker',
        name: 'analysisBroker',
        meta: {
          title: '经纪人分享统计',
          code: Grant.OSP_ANALYSIS_BROKER,
          show: true,
        },
        component: () => import('../views/data-analysis/broker/list.vue'),
      },

    ]
  },
  // 系统管理
  {
    path: '',
    name: 'system',
    meta: {
      title: '系统',
      subTitle: '系统管理',
      code: Grant.OSP_SYS,
      icon: 'ios-settings',
      show: true
    },
    component: Main,
    children: [
      {
        path: '/system/menu',
        name: 'systemMenu',
        meta: {
          title: '菜单管理',
          code: Grant.OSP_SYS_MENU,
          show: true
        },
        component: () => import('../views/system/menu/list.vue')
      },
      {
        path: '/system/role',
        name: 'systemRole',
        meta: {
          title: '角色管理',
          code: Grant.OSP_SYS_ROLE,
          show: true
        },
        component: () => import('../views/system/role/list.vue')
      },
      {
        path: '/system/user',
        name: 'systemUser',
        meta: {
          title: '用户管理',
          code: Grant.OSP_SYS_USER,
          show: true
        },
        component: () => import('../views/system/user/list.vue')
      },
      {
        path: '/system/org',
        name: 'systemOrg',
        meta: {
          title: '组织管理',
          code: Grant.OSP_SYS_ORG,
          show: true
        },
        component: () => import('../views/system/org/list.vue')
      }
    ]
  }
];
// 页面示例
if (process.env.NODE_ENV === 'development') {
  routers.push(...testMenu);
}
export default routers;
