/**
 * 置底组件
 * luxinwen
 * 2020-02
 */
<style scoped lang="less">
  @import "./sp-footer.less";
</style>

<template>
  <div class="sp-footer">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'sp-footer'
  };
</script>