/**
 * 百度地图
 * luxinwen
 * Developed on 2021-03
 * Updated on 2023-02
 */
<template>
  <div>
    <div ref="sp-map" :style="mapStyle"></div>
  </div>
</template>

<script>
  export default {
    name: 'sp-map',
    props: {
      // 中心点地址
      center: {
        type: String
      },
      // 中心点坐标
      point: {
        type: Object
      },
      // 图层宽度
      width: {
        type: String,
        default: '100%'
      },
      // 图层高度
      height: {
        type: String,
        default: '400px'
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        address: this.center,
        lng: 116.604, // 经度
        lat: 39.915,  // 纬度
        map: null,
        geo: null,
        marker: null
      };
    },
    computed: {
      mapStyle() {
        return {
          width: this.width,
          height: this.height
        };
      }
    },
    mounted() {
      if (!window.BMap) {
        // 请求 https://api.map.baidu.com/api?v=3.0&ak=2v8aW2QE4dAtEei0C64GLvPxMspPNj3f 获得以下链接
        this.loadScript('https://api.map.baidu.com/getscript?v=3.0&ak=2v8aW2QE4dAtEei0C64GLvPxMspPNj3f&services=&t=20211206192911').then(res => {
          this.initMap();
        });
      } else {
        this.initMap();
      }
    },
    methods: {
      /**
       * 加载JS
       */
      loadScript(src) {
        return new Promise((resolve, reject) => {
          let script = document.createElement('script');
          script.onload = () => {
            resolve();
          };
          script.onerror = err => {
            reject(err);
          };
          script.src = src;
          (document.head || document.body).appendChild(script);
        });
      },
      /**
       * 初始化地图
       */
      initMap() {
        let BMap = window.BMap;
        if (!BMap) return;
        let map = new BMap.Map(this.$refs['sp-map']);
        map.enableScrollWheelZoom(true);  // 开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl()); // 添加平移缩放控件控件
        map.addControl(new BMap.MapTypeControl());  // 添加地图类型控件
        let point = new BMap.Point(this.lng, this.lat);  // 创建点坐标
        map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
        let geo = new BMap.Geocoder({ // 创建地址解析器实例
          extensions_town: true
        });
        let marker = new BMap.Marker(point);  // 创建标注
        map.addOverlay(marker); // 将标注添加到地图中
        this.geo = geo;
        if (!this.disabled) {
          marker.enableDragging();  // 开户标注拖拽
          marker.addEventListener('dragend', e => { // 监听标注事件
            let point = e.point;
            this.lng = point.lng;
            this.lat = point.lat;
            this.getLocation(point, result => {
              marker.setTitle(this.address);
            });
          });
          map.addEventListener('click', e => {  // 监听点击事件
            let point = e.point;
            this.lng = point.lng;
            this.lat = point.lat;
            this.getLocation(point, result => {
              marker.setTitle(this.address);
              marker.setPosition(point);
            });
          });
        }
        this.map = map;
        this.marker = marker;
        this.searchByAddress(this.address);
      },
      /**
       * 根据坐标得到地址描述
       */
      getLocation(point, callback) {
        if (!this.geo || !point) return;
        console.log('getLocation', point);
        this.geo.getLocation(point, result => {
          let address = '';
          if (result) {
            let pois = result.surroundingPois;
            if (pois && pois.length > 0) {
              address = pois[0].address;
            } else {
              address = result.address;
            }
          }
          this.address = address;
          if (callback && typeof callback === 'function') callback(result);
          this.setValue();
        });
      },
      /**
       * 根据地址获得坐标信息
       */
      getPoint(address, callback) {
        if (!this.geo || !address) return;
        console.log('getPoint', address);
        this.geo.getPoint(address, point => {
          console.log(point);
          let lng = '';
          let lat = '';
          if (point) {
            lng = point.lng;
            lat = point.lat;
          } else {
            console.log('您输入的地址没有解析到结果');
          }
          this.lng = lng;
          this.lat = lat;
          if (callback && typeof callback === 'function') callback(point);
          this.setValue();
        });
      },
      /**
       * 根据地址搜索地图
       */
      searchByAddress(address) {
        this.getPoint(address, point => {
          if (point) {
            this.map.centerAndZoom(point, 16);
            this.marker.setPosition(point);
            this.marker.setTitle(address);
          }
        });
      },
      /**
       * 根据坐标搜索地图
       */
      searchByPoint(point) {
        this.getLocation(point, result => {
          this.map.centerAndZoom(point, 16);
          this.marker.setPosition(point);
          this.marker.setTitle(this.address);
        });
      },
      /**
       * 设置传值
       */
      setValue() {
        this.$emit('on-change', {
          address: this.address,
          lng: this.lng,
          lat: this.lat
        });
      }
    },
    watch: {
      center: {
        immediate: true,
        handler(newVal) {
          console.log('map watch center', newVal);
          this.address = newVal;
          this.searchByAddress(newVal);
        }
      },
      point: {
        immediate: true,
        handler(newVal) {
          console.log('map watch point', newVal);
          if (newVal && newVal.lng) {
            this.lng = newVal.lng;
          }
          if (newVal && newVal.lat) {
            this.lat = newVal.lat;
          }
          this.searchByPoint(newVal);
        }
      }
    }
  };
</script>