export default {
  /**
   * 项目管理
   */
  OSP_PROJECT: 'OSP_PROJECT',                               // 项目管理
  OSP_PROJECT_MGM: 'OSP_PROJECT_MGM',                         // 项目信息
  OSP_PROJECT_MGM_QUERY: 'OSP_PROJECT_MGM_QUERY',               // 查询
  OSP_PROJECT_MGM_SAVE: 'OSP_PROJECT_MGM_SAVE',                 // 新增
  OSP_PROJECT_MGM_VIEW: 'OSP_PROJECT_MGM_VIEW',                 // 查看
  OSP_PROJECT_MGM_UPDATE: 'OSP_PROJECT_MGM_UPDATE',             // 编辑
  OSP_PROJECT_MGM_PHOTO: 'OSP_PROJECT_MGM_PHOTO',               // 项目相册
  OSP_PROJECT_MGM_CHANGE: 'OSP_PROJECT_MGM_CHANGE',             // 启用禁用
  OSP_PROJECT_MGM_DELETE: 'OSP_PROJECT_MGM_DELETE',             // 删除
  OSP_PROJECT_HOUSE: 'OSP_PROJECT_HOUSE',                     // 户型维护
  OSP_PROJECT_HOUSE_QUERY: 'OSP_PROJECT_HOUSE_QUERY',           // 查询
  OSP_PROJECT_HOUSE_SAVE: 'OSP_PROJECT_HOUSE_SAVE',             // 新增
  OSP_PROJECT_HOUSE_VIEW: 'OSP_PROJECT_HOUSE_VIEW',             // 查看
  OSP_PROJECT_HOUSE_UPDATE: 'OSP_PROJECT_HOUSE_UPDATE',         // 编辑
  OSP_PROJECT_HOUSE_SALESTATUS: 'OSP_PROJECT_HOUSE_SALESTATUS', // 在售停售
  OSP_PROJECT_HOUSE_CHANGE: 'OSP_PROJECT_HOUSE_CHANGE',         // 启用禁用
  OSP_PROJECT_HOUSE_DELETE: 'OSP_PROJECT_HOUSE_DELETE',         // 删除
  OSP_PROJECT_SCOPE: 'OSP_PROJECT_SCOPE',                     // 项目范围
  OSP_PROJECT_SCOPE_QUERY: 'OSP_PROJECT_SCOPE_QUERY',           // 查询
  OSP_PROJECT_SCOPE_SAVE: 'OSP_PROJECT_SCOPE_SAVE',             // 新增
  OSP_PROJECT_SCOPE_VIEW: 'OSP_PROJECT_SCOPE_VIEW',             // 查看
  OSP_PROJECT_SCOPE_DELETE: 'OSP_PROJECT_SCOPE_DELETE',         // 删除
  /**
   * 广告配置
   */
  OSP_ADVERT: 'OSP_ADVERT',                                 // 广告配置
  OSP_ADVERT_MGM: 'OSP_ADVERT_MGM',                           // 广告列表
  OSP_ADVERT_MGM_QUERY: 'OSP_ADVERT_MGM_QUERY',                 // 查询
  OSP_ADVERT_MGM_SAVE: 'OSP_ADVERT_MGM_SAVE',                   // 新增
  OSP_ADVERT_MGM_VIEW: 'OSP_ADVERT_MGM_VIEW',                   // 查看
  OSP_ADVERT_MGM_UPDATE: 'OSP_ADVERT_MGM_UPDATE',               // 编辑
  OSP_ADVERT_MGM_DELETE: 'OSP_ADVERT_MGM_DELETE',               // 删除
  /**
   * 活动管理
   */
  OSP_ACTIVITY: 'OSP_ACTIVITY',                             // 活动管理
  OSP_ACTIVITY_MGM: 'OSP_ACTIVITY_MGM',                       // 活动信息
  OSP_ACTIVITY_MGM_QUERY: 'OSP_ACTIVITY_MGM_QUERY',             // 查询
  OSP_ACTIVITY_MGM_SAVE: 'OSP_ACTIVITY_MGM_SAVE',               // 新增
  OSP_ACTIVITY_MGM_VIEW: 'OSP_ACTIVITY_MGM_VIEW',               // 查看
  OSP_ACTIVITY_MGM_UPDATE: 'OSP_ACTIVITY_MGM_UPDATE',           // 编辑
  OSP_ACTIVITY_MGM_CHANGE: 'OSP_ACTIVITY_MGM_CHANGE',           // 启用禁用
  OSP_ACTIVITY_MGM_DELETE: 'OSP_ACTIVITY_MGM_DELETE',           // 删除
  OSP_ACTIVITY_ITEM: 'OSP_ACTIVITY_ITEM',                     // 关联项目
  OSP_ACTIVITY_ITEM_SAVE: 'OSP_ACTIVITY_ITEM_SAVE',             // 新增
  OSP_ACTIVITY_ITEM_VIEW: 'OSP_ACTIVITY_ITEM_VIEW',             // 查看
  OSP_ACTIVITY_ITEM_DELETE: 'OSP_ACTIVITY_ITEM_DELETE',         // 删除
  OSP_ACTIVITY_INFO: 'OSP_ACTIVITY_INFO',                     // 参与信息
  OSP_ACTIVITY_INFO_QUERY: 'OSP_ACTIVITY_INFO_QUERY',           // 查询
  OSP_ACTIVITY_INFO_VIEW: 'OSP_ACTIVITY_INFO_VIEW',             // 查看
  /**
   * 经纪人管理
   */
  OSP_BROKER: 'OSP_BROKER',                                 // 经纪人管理
  OSP_BROKER_MGM: 'OSP_BROKER_MGM',                           // 经纪人列表
  OSP_BROKER_MGM_QUERY: 'OSP_BROKER_MGM_QUERY',                 // 查询
  OSP_BROKER_MGM_REALNAME: 'OSP_BROKER_MGM_REALNAME',           // 实名信息
  OSP_BROKER_MGM_CARD: 'OSP_BROKER_MGM_CARD',                   // 银行卡
  OSP_BROKER_MGM_CHANGE: 'OSP_BROKER_MGM_CHANGE',               // 冻结解冻
  OSP_BROKER_COMMISSION: 'OSP_BROKER_COMMISSION',               // 佣金信息
  OSP_BROKER_COMMISSION_QUERY: 'OSP_BROKER_COMMISSION_QUERY',     // 查询
  OSP_BROKER_COMMISSION_VIEW: 'OSP_BROKER_COMMISSION_VIEW',       // 查看
  // OSP_BROKER_COMMISSION_UPDATE: 'OSP_BROKER_COMMISSION_UPDATE',   // 佣金结算
  OSP_BROKER_CUSTOMER: 'OSP_BROKER_CUSTOMER',                   // 客户信息
  OSP_BROKER_CUSTOMER_VIEW: 'OSP_BROKER_CUSTOMER_VIEW',           // 查看
  OSP_BROKER_CUSTOMER_COMMISSION: 'OSP_BROKER_CUSTOMER_COMMISSION', // 佣金信息
  OSP_BROKER_STATISTICS: 'OSP_BROKER_STATISTICS',             // 经纪人统计
  OSP_BROKER_STATISTICS_QUERY: 'OSP_BROKER_STATISTICS_QUERY',   // 查询
  OSP_MEMBER_STATISTICS: 'OSP_MEMBER_STATISTICS', // 会员统计
  OSP_BROKER_STATISTICS_EXPORT: 'OSP_BROKER_STATISTICS_EXPORT',
  /**
   * 客户管理
   */
  OSP_CUSTOMER: 'OSP_CUSTOMER',                               // 客户管理
  OSP_CUSTOMER_MGM: 'OSP_CUSTOMER_MGM',                         // 客户信息
  OSP_CUSTOMER_MGM_QUERY: 'OSP_CUSTOMER_MGM_QUERY',               // 查询
  // OSP_CUSTOMER_MGM_VIEW: 'OSP_CUSTOMER_MGM_VIEW',                 // 查看
  OSP_CUSTOMER_MGM_VISIT: 'OSP_CUSTOMER_MGM_VISIT',               // 到访
  OSP_CUSTOMER_MGM_TOBUY: 'OSP_CUSTOMER_MGM_TOBUY',               // 认购
  OSP_CUSTOMER_MGM_DEAL: 'OSP_CUSTOMER_MGM_DEAL',                 // 成交
  OSP_CUSTOMER_MGM_FINAL: 'OSP_CUSTOMER_MGM_FINAL',               // 佣金结算
  /**
   * 佣金管理
   */
  OSP_COMMISSION: 'OSP_COMMISSION',                           // 佣金管理
  OSP_COMMISSION_MGM: 'OSP_COMMISSION_MGM',                     // 佣金信息
  OSP_COMMISSION_MGM_QUERY: 'OSP_COMMISSION_MGM_QUERY',           // 查询
  OSP_COMMISSION_MGM_VIEW: 'OSP_COMMISSION_MGM_VIEW',             // 查看
  OSP_COMMISSION_MGM_UPDATE: 'OSP_COMMISSION_MGM_UPDATE',         // 佣金结算
  OSP_COMMISSION_MGM_UNUSUAL: 'OSP_COMMISSION_MGM_UNUSUAL',       // 异常
  OSP_COMMISSION_MGM_RECOVER: 'OSP_COMMISSION_MGM_RECOVER',       // 恢复
  /**
   * 品牌馆
   */
  OSP_BRAND: 'OSP_BRAND',                                     // 品牌馆
  OSP_BRAND_ADVERT: 'OSP_BRAND_ADVERT',                         // 广告管理
  OSP_BRAND_NEWS: 'OSP_BRAND_NEWS',                             // 新闻资讯
  OSP_BRAND_NEWS_QUERY: 'OSP_BRAND_NEWS_QUERY',                   // 查询
  OSP_BRAND_NEWS_SAVE: 'OSP_BRAND_NEWS_SAVE',                     // 新增
  OSP_BRAND_NEWS_VIEW: 'OSP_BRAND_NEWS_VIEW',                     // 查看
  OSP_BRAND_NEWS_UPDATE: 'OSP_BRAND_NEWS_UPDATE',                 // 编辑
  OSP_BRAND_NEWS_DELETE: 'OSP_BRAND_NEWS_DELETE',                 // 删除
  OSP_BRAND_STORY: 'OSP_BRAND_STORY',                           // 品牌故事
  /**
   * 营销活动管理
   */
  OSP_MARKETING: 'OSP_MARKETING',                             // 营销活动管理
  OSP_MARKETING_EVENT: 'OSP_MARKETING_EVENT',                   // 营销事件管理
  OSP_MARKETING_EVENT_QUERY: 'OSP_MARKETING_EVENT_QUERY',         // 查询
  OSP_MARKETING_EVENT_SAVE: 'OSP_MARKETING_EVENT_SAVE',           // 新增
  OSP_MARKETING_EVENT_VIEW: 'OSP_MARKETING_EVENT_VIEW',           // 查看
  OSP_MARKETING_EVENT_UPDATE: 'OSP_MARKETING_EVENT_UPDATE',       // 编辑
  OSP_MARKETING_EVENT_ENABLE: 'OSP_MARKETING_EVENT_ENABLE',       // 启用
  OSP_MARKETING_EVENT_CANCEL: 'OSP_MARKETING_EVENT_CANCEL',       // 作废
  OSP_MARKETING_EVENT_ADUIT: 'OSP_MARKETING_EVENT_ADUIT',        // 审核
  OSP_MARKETING_EVENT_LOG: 'OSP_MARKETING_EVENT_LOG',             // 日志
  OSP_MARKETING_LOG: 'OSP_MARKETING_LOG',                       // 事件日志管理
  OSP_MARKETING_LOG_QUERY: 'OSP_MARKETING_LOG_QUERY',             // 查询
  OSP_MARKETING_LOG_VIEW: 'OSP_MARKETING_LOG_VIEW',               // 查看
  /**
   * 数据分析
   */
  OSP_ANALYSIS: 'OSP_ANALYSIS',                               // 数据分析
  OSP_ANALYSIS_PROJECT: 'OSP_ANALYSIS_PROJECT',                 // 楼盘信息统计
  OSP_ANALYSIS_BROKER: 'OSP_ANALYSIS_BROKER',
  OSP_ANALYSIS_PROJECT_QUERY: 'OSP_ANALYSIS_PROJECT_QUERY',       // 查询
  OSP_ANALYSIS_PROJECT_VIEW: 'OSP_ANALYSIS_PROJECT_VIEW',         // 查看
  OSP_ANALYSIS_PROJECT_EXPORT: 'OSP_ANALYSIS_PROJECT_EXPORT',     // 导出
  /**
   * 系统管理
   */
  OSP_SYS: 'OSP_SYS',                                          // 系统管理
  OSP_SYS_MENU: 'OSP_SYS_MENU',                                  // 菜单管理
  OSP_SYS_MENU_SAVE_ROOT: 'OSP_SYS_MENU_SAVE_ROOT',               // 添加一级菜单
  OSP_SYS_MENU_SAVE_SUB: 'OSP_SYS_MENU_SAVE_SUB',                 // 添加二级菜单
  OSP_SYS_MENU_SAVE_BUTTON: 'OSP_SYS_MENU_SAVE_BUTTON',           // 添加按钮权限
  OSP_SYS_MENU_VIEW: 'OSP_SYS_MENU_VIEW',                         // 查看详情
  OSP_SYS_MENU_UPDATE: 'OSP_SYS_MENU_UPDATE',                     // 编辑
  OSP_SYS_MENU_DELETE: 'OSP_SYS_MENU_DELETE',                     // 删除
  OSP_SYS_ROLE: 'OSP_SYS_ROLE',                                 // 角色管理
  OSP_SYS_ROLE_SAVE: 'OSP_SYS_ROLE_SAVE',                         // 新增
  OSP_SYS_ROLE_UPDATE: 'OSP_SYS_ROLE_UPDATE',                     // 编辑
  OSP_SYS_ROLE_VIEW: 'OSP_SYS_ROLE_VIEW',                         // 查看详情
  OSP_SYS_ROLE_BIND: 'OSP_SYS_ROLE_BIND',                         // 绑定菜单
  OSP_SYS_ROLE_DELETE: 'OSP_SYS_ROLE_DELETE',                     // 删除
  OSP_SYS_USER: 'OSP_SYS_USER',                                 // 用户管理
  OSP_SYS_USER_QUERY: 'OSP_SYS_USER_QUERY',                       // 查询
  OSP_SYS_USER_SAVE: 'OSP_SYS_USER_SAVE',                         // 新增
  OSP_SYS_USER_UPDATE: 'OSP_SYS_USER_UPDATE',                     // 编辑
  OSP_SYS_USER_SETROLE: 'OSP_SYS_USER_SETROLE',                   // 设置角色
  OSP_SYS_USER_RESET: 'OSP_SYS_USER_RESET',                       // 重置密码
  OSP_SYS_USER_CHANGE: 'OSP_SYS_USER_CHANGE',                     // 启用禁用
  OSP_SYS_USER_DELETE: 'OSP_SYS_USER_DELETE',                     // 删除
  OSP_SYS_ORG: 'OSP_SYS_ORG',                                   // 组织管理
  OSP_SYS_ORG_SAVE: 'OSP_SYS_ORG_SAVE',                           // 新增
  OSP_SYS_ORG_VIEW: 'OSP_SYS_ORG_VIEW',                           // 查看
  OSP_SYS_ORG_UPDATE: 'OSP_SYS_ORG_UPDATE',                       // 编辑
  OSP_SYS_ORG_DELETE: 'OSP_SYS_ORG_DELETE',                       // 删除
  /**
   * 页面示例
   */
  OSP_TEST: '*',
  OSP_TEST_LIST: '*',
  OSP_TEST_LIST_QUERY: '*',
  OSP_TEST_LIST_SAVE: '*',
  OSP_TEST_LIST_VIEW: '*',
  OSP_TEST_LIST_UPDATE: '*',
  OSP_TEST_LIST_DELETE: '*',
  OSP_TEST_LIST_BATCH: '*'
};